import React, { useState, useEffect } from "react";

const OTPTimer = ({ onResend, loading, seconds, setSeconds, setIsDisabled, isDisabled }) => {

  useEffect(() => {
    let timer;
    if (seconds > 0) {
      timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else {
      clearInterval(timer);
      setIsDisabled(false);
    }

    return () => {
      clearInterval(timer);
    };
  }, [seconds, setSeconds, setIsDisabled]);

  const handleResendClick = () => {
    if (!isDisabled) {
      onResend();
      // setIsDisabled(true); 
    }
  };

  return (
    <>
      {seconds > 0 ? (
        <p>
          Resend OTP in{" "}
          {Math.floor(seconds / 60) < 10
            ? `0${Math.floor(seconds / 60)}`
            : Math.floor(seconds / 60)}
          :
          {seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60}{" "}
          {seconds <= 60 ? "seconds" : "minutes"}
        </p>
      ) : (
        <p
        className={`resend-opt-text ${isDisabled ? 'resend-text-disabled' : ''}`}
        onClick={isDisabled ? undefined : handleResendClick}
      >
        Resend OTP
      </p>
      
      )}
    </>
  );
};

export default OTPTimer;
