import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {getAllUserTransactionList,} from "../../../../redux/slices/adminService";
import PageIndex from "../../../PageIndex";
import { debounce } from "lodash";
import dayjs from "dayjs";
import numeral from "numeral";

export default function TransactionsList() {
  const abortController = new AbortController();
  const { rolePermission } = PageIndex.useSelector((state) => state.admin);
  const [search, setSearch] = useState("");
  const params = PageIndex.useLocation();

  const today = params?.state;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [loader, setLoader] = useState(true);
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [transactionsList, setTransactionsList] = useState([]);
  const [singleTrasanction, setSingleTrasanction] = useState("");

  const columns = [
    "S.No",
    "Date",
    "Transaction Id",
    "Username",
    "Transaction Type",
    "Currency  Type",
    "Amount",
    "Status",
    "Action",
  ];

  const columnMap = {
    "S.No": "sNo",
    Date: "Date",
    "Transaction Id": "transactionId",
    "Username": "userId.userName",
    "Transaction Type": "type",
    "Currency  Type":"currencyType",
    Amount: "amount",
    Status: "paymentStatus",
    Action: "actions"
  };
  const sortableColumns = ["Date", "Transaction Type"];

  const typeList = [
    { id: 1, value: "today", key: "Today" },
    { id: 2, value: "overview", key: "Overview" },
  ];
const queryParams = new URLSearchParams(window.location.search);
const stateParam = queryParams.get('state');
const state = JSON.parse(decodeURIComponent(stateParam));

const navigate=PageIndex.useNavigate()
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(state?.today ? state?.today : 'overview')
  // const [type, setType] = useState(params?.state ? params?.state : 'overview')
  const [singleTransactionData, setSingleTransaction] = useState("");

  const handleOpen = (data,) => {
    setOpen(true);
      
    setSingleTransaction(data);
  };
  const handleClose = () => {
    setOpen(false)


  }
  const handleType = (e) => {
    setType(e.target.value);
    navigate({ state: '' })
    setCurrentPage(1)
  };

  const fetchUserTransactionList = (page) => {
    setLoader(true);
    let obj = {};
    if (selectedFromDate || selectedToDate) {
      obj = {
        startDate: selectedFromDate
          ? dayjs(selectedFromDate).format("DD/MM/YYYY")
          : "",
        endDate: selectedToDate
          ? dayjs(selectedToDate).format("DD/MM/YYYY")
          : "",
      };
    }
    let queryPage = page || currentPage || 1;
    let previousPage = queryPage;
    getAllUserTransactionList({
      obj,
      page: queryPage,
      search,
      type: "fiat",
      today:type,
    }).then((data) => {
      if (data?.status == 200) {
        if (data?.data.length === 0) {
          setCurrentPage(previousPage);
          setTransactionsList([]);
        } else {
          const formattedData = data?.data.map((item) => ({
            ...item,
            Date: Index.moment(item.createdAt).format("DD/MM/YYYY hh:mm A"),
            amount: numeral(item.amount).format('0,0.00a'),
          }));
          setTransactionsList(formattedData);
          if (data?.currentPage) {
            setCurrentPage(data?.currentPage);
          }
          // setCurrentPage(data?.currentPage || 1); // Set currentPage to 1 if data.currentPage is falsy
          setPageCount(data?.totalPages);
        }
        setLoader(false);
        // setLoading(false)
      } else {
        setTransactionsList([]);
        setLoader(false);
      }
    });
  };

  useEffect(() => {
    setLoader(true)
    const debouncedFetch = debounce(fetchUserTransactionList, 300);

    if (search || currentPage) {
      debouncedFetch();
    } else {
      fetchUserTransactionList()
    }

    return () => {
      debouncedFetch.cancel();
      abortController.abort();

    };
  }, [search, currentPage, type]);


  useEffect(() => {
    const debouncedFetch = debounce(fetchUserTransactionList, 300);

    if (selectedFromDate || selectedToDate) {
      debouncedFetch();
    } else if (selectedFromDate == null || selectedToDate == null) {
      fetchUserTransactionList();
    }return () => {
        debouncedFetch.cancel();
        abortController.abort();
      };
    }, [selectedFromDate, selectedToDate,type,currentPage]);

    const handleClearFromDate = () => {
      setSelectedFromDate(null);
      setCurrentPage(1)
    };
    const handleClearToDate = () => {
      setSelectedToDate(null);
      setCurrentPage(1)
    };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };


  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="barge-common-box">
         
         
          <Index.Box className="campaigns-section-main">
              <Index.Box className="user-list-flex">
                <Index.Box className="admin-page-title-main">
                  <Index.Typography
                    className="admin-page-title"
                    component="h2"
                    variant="h2"
                  >
                    Transactions List
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="contest-search-list user-list-flex">
                {/* <Index.Box className="userlist-btn-flex"> */}
                <Index.Box className="contest-search-list-1">
             {type!='today'&&  
                  <>
                  <Index.Box className="admin-text-field-main from-input mb-0">
                    <Index.Box className="input-box input-box-admin input-box-admin2">
                      <Index.Box className="form-group transaction-fieldset">
                        <Index.LocalizationProvider
                          dateAdapter={Index.AdapterDayjs}
                        >
                          <Index.InputLabel id="demo-simple-label">
                            From
                          </Index.InputLabel>
                          <Index.Stack className="date-picker-mui cus-datepicker">
                            {/* From Date */}
                            <Index.MobileDatePicker
                              className="form-control datepicker"
                              format="DD-MM-YYYY"
                              value={selectedFromDate}
                              disableFuture
                              maxDate={selectedToDate}
                              onChange={(newValue) => {
                                setSelectedFromDate(newValue);
                                setCurrentPage(1)
                              }}
                            />
                            {selectedFromDate ? (
                              <Index.IconButton
                                className="clear-icon"
                                onClick={handleClearFromDate}
                              >
                                <Index.ClearIcon className="icon-clear" />
                              </Index.IconButton>
                            ) : (
                              ""
                            )}
                          </Index.Stack>
                        </Index.LocalizationProvider>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-text-field-main from-input mb-0">
                    <Index.Box className="input-box input-box-admin input-box-admin2">
                      <Index.Box className="form-group transaction-fieldset">
                        <Index.LocalizationProvider
                          dateAdapter={Index.AdapterDayjs}
                        >
                          <Index.InputLabel id="demo-simple-label">
                            To
                          </Index.InputLabel>
                          <Index.Stack className="sale-field date-picker-mui cus-datepicker">
                            {/* To Date */}
                            <Index.MobileDatePicker
                              className=" form-control datepicker"
                              format="DD-MM-YYYY"
                              value={selectedToDate}
                              // disableFuture
                              minDate={selectedFromDate}
                              onChange={(newValue) =>
                               { setSelectedToDate(newValue)
                                setCurrentPage(1)}
                              }
                            />
                            {selectedToDate ? (
                              <Index.IconButton
                                className="clear-icon"
                                onClick={handleClearToDate}
                              >
                                <Index.ClearIcon className="icon-clear" />
                              </Index.IconButton>
                            ) : (
                              ""
                            )}
                          </Index.Stack>
                        </Index.LocalizationProvider>
                      </Index.Box>
                    </Index.Box>
           
                </Index.Box>
                </>
                }
                </Index.Box>
                <Index.Box className="contest-search-list-2">
                <Index.Box className="input-box add-user-input request-input-box cus-request-drop-list reel-type-dropdown">
                <Index.Box className="form-group">
                  <Index.FormControl className="request-dropdown">
                    <Index.Select
                      className="form-control select-drop-list request-drop-list"
                      name="type"
                      value={type}

                      onChange={handleType}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                    >
                      {typeList &&
                        typeList?.map((val) => (
                          <Index.MenuItem
                            value={val?.value}
                            key={val?.id}
                            className="menuitem"
                          >
                            {val?.key}
                          </Index.MenuItem>
                        ))}
                    </Index.Select>
                  </Index.FormControl>
                </Index.Box>
              </Index.Box>

                  <Index.Box className="user-search-main">
                    <Index.Box className="user-search-box">
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          placeholder="Search"
                          name="search"
                          value={search}
                          onChange={(e) => {
                            const newValue = e.target.value
                              .replace(/^\s+/, "")
                              .replace(/\s\s+/g, " ");
                              setCurrentPage(1)
                            setSearch(newValue);
                          }}
                        />
                        <span className="search-icon-box">
                          <img src={Index.Svg.search} className="search-icon" />
                        </span>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                {/* </Index.Box> */}
              </Index.Box>
            </Index.Box>
            <PageIndex.CommonTable
              columnMap={columnMap}
              columns={columns}
              data={transactionsList}
              loader={loader}
              setLoader={setLoader}
              pageCount={pageCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setPageCount={setPageCount}
              sortableColumns={sortableColumns}
              handleOpen={handleOpen}
              title="Transactions List"
              className={"transaction-table-main"}
            />
              <PageIndex.ViewTransactionDetailsModel open={open} handleClose={handleClose} singleTransactionData={singleTransactionData} />
        </Index.Box>
      </Index.Box>

      {/* Delete Modal */}
     
     
    </>
  );
}
