import React, { useState } from "react";
import Index from "../../../Index";
import { addUserMaster } from "../../../../redux/slices/adminService";
import PageIndex from "../../../PageIndex";

export default function AddUser() {
  const navigate = PageIndex.useNavigate();

  const params = PageIndex.useLocation();
  const tabValue = params?.state?.btnValue;


  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleClickShowPassword = (e) => {
    e.preventDefault();
    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const initialValues = {
    name: "",
    email: "",
    phoneNumber: "",
    userName: "",
    password: "",
    isEdit: false,
  };

  const handleSubmit = async (values) => {
    setLoader(true);
    addUserMaster(values).then((data) => {
      if (data?.status === 201) {
        setLoader(false);
        navigate("/dashboard/user-list", {
          state: {
            btnValue: tabValue
          }
        });
      } else {
        setTimeout(() => {
          setLoader(false);
        }, 3000);
      }
    });
  };

  return (
    <>
      <Index.Box className="dashboard-content ">
        <PageIndex.BackButton onClick={() => navigate("/dashboard/user-list", {
          state: {
            btnValue: tabValue
          }
        })} />
        <Index.Box className="barge-common-box">
          <Index.Typography
            className="admin-page-title cus-heading"
            component="h2"
            variant="h2"
          >
            Add User
          </Index.Typography>
          <PageIndex.Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={PageIndex.addUserSchema}
          >
            {({
              values,
              handleBlur,
              handleChange,
              errors,
              setFieldValue,
              touched,
            }) => (
              <PageIndex.Form>
                <Index.Box className="add-user-data-main">
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      className="display-row-add-user"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Name   <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Name"
                              name="name"
                              value={values.name}
                              onChange={(e) => {
                                const newValue = e.target.value
                                  .replace(/^\s+/, "")
                                  .replace(/\s\s+/g, " ");
                                setFieldValue("name", newValue);
                              }}
                              onBlur={handleBlur}
                              error={Boolean(
                                errors?.name ? touched?.name : undefined
                              )}
                              helperText={
                                touched?.name ? errors?.name : undefined
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Email   <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control email-input"
                              placeholder="Email"
                              name="email"
                              value={values.email}
                              onChange={(e) => {
                                const newValue = e.target.value.replace(
                                  /\s/g,
                                  ""
                                );
                                setFieldValue("email", newValue);
                              }}
                              onBlur={handleBlur}
                              error={Boolean(
                                errors?.email ? touched?.email : undefined
                              )}
                              helperText={
                                touched?.email ? errors?.email : undefined
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Phone Number   <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              type="text"
                              className="form-control"
                              placeholder="Phone Number"
                              name="phoneNumber"
                              value={values.phoneNumber}
                              onChange={(e) => {
                                const newValue = e.target.value.replace(
                                  /\D+/g,
                                  ""
                                );
                                if (newValue.length <= 10) {
                                  setFieldValue("phoneNumber", newValue);
                                }
                              }}
                              error={Boolean(
                                errors?.phoneNumber
                                  ? touched?.phoneNumber
                                  : undefined
                              )}
                              helperText={
                                touched?.phoneNumber
                                  ? errors?.phoneNumber
                                  : undefined
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Password  <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              size="small"
                              placeholder="Password"
                              name="password"
                              className="form-control input-with-radius password-form-control"
                              type={showPassword ? "text" : "password"}
                              variant="outlined"
                              autoComplete="off"
                              inputProps={{
                                className: "input_props",
                              }}
                              InputLabelProps={{
                                className: "add-formlabel",
                              }}
                              FormHelperTextProps={{
                                className: "input_label_props",
                              }}
                              value={values.password}
                              onChange={(e) => {
                                const newValue = e.target.value.replace(
                                  /\s/g,
                                  ""
                                );
                                setFieldValue("password", newValue);
                              }}
                              InputProps={{
                                // <-- This is where the toggle button is added.
                                endAdornment: (
                                  <Index.InputAdornment position="end">
                                    <Index.IconButton
                                      className="passwrd-eye"
                                      aria-label="toggle password visibility"
                                      onClick={(e) =>
                                        handleClickShowPassword(e)
                                      }
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <Index.Visibility />
                                      ) : (
                                        <Index.VisibilityOff />
                                      )}
                                    </Index.IconButton>
                                  </Index.InputAdornment>
                                ),
                              }}
                              error={Boolean(
                                errors?.password ? touched?.password : undefined
                              )}
                              helperText={
                                touched?.password ? errors?.password : undefined
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Username  <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              type="text"
                              className="form-control"
                              placeholder="Username"
                              name="userName"
                              value={values.userName}
                              onChange={(e) => {
                                const newValue = e.target.value.replace(
                                  /\s/g,
                                  ""
                                )

                                setFieldValue("userName", newValue);
                              }}
                              error={Boolean(
                                errors?.userName ? touched?.userName : undefined
                              )}
                              helperText={
                                touched?.userName ? errors?.userName : undefined
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      ></Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="user-btn-flex">
                    <Index.Box className="save-btn-main border-btn-main">
                      <Index.Button
                        className="save-user-btn border-btn"
                        type="submit"
                        disabled={loader}
                      >
                        {loader ? (
                          <PageIndex.ButtonLoader
                            color="white"
                            size={14}
                            loading={loader}
                          />
                        ) : (
                          <>
                            <img
                              src={Index.Svg.save}
                              className="user-save-icon"
                              alt="Save Icon"
                            />
                            Save
                          </>
                        )}
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </PageIndex.Form>
            )}
          </PageIndex.Formik>
        </Index.Box>
      </Index.Box>
      {/* </Index.Box> */}
    </>
  );
}
