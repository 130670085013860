import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Index from '../Index';
import { PuffLoader } from 'react-spinners';
const Loading = () => {
  return (
   <>
   {/* <Index.Box className="flex-loader">
    <CircularProgress  className='loader'/>
   </Index.Box>  */}
   <Index.Box className="flex-loader">
                    <PuffLoader
                        color='#d75ccd'
                        className='loader'

                    />
                </Index.Box>
   </>
  )
}

export default Loading