import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { getAllUsersFeedback } from "../../../../redux/slices/adminService";
import moment from "moment";
import { debounce } from "lodash";

const UsersFeedbackList = () => {
  const navigate = PageIndex.useNavigate();
  const location = PageIndex.useLocation()
  const [usersFeedbackList, setUsersFeedbackList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const { rolePermission } = PageIndex.useSelector((state) => state.admin);

  //api of users feedback list

  //api of users feedback list
  const listOfUsersFeedback = (page) => {
    setLoader(true);

    getAllUsersFeedback(currentPage, search).then((res) => {
      if (res?.status === 200) {
        setLoader(false);
        setUsersFeedbackList(res?.data);
        if (res?.currentPage) {
          setCurrentPage(res?.currentPage);
        }

        setPageCount(res?.totalPages);
      } else {
        setUsersFeedbackList([]);
      }
    });
  };

  useEffect(() => {
    const debouncedFetch = debounce(listOfUsersFeedback, 300);

    if (search || currentPage) {
      debouncedFetch();
    } else {
      listOfUsersFeedback();
    }

    return () => {
      debouncedFetch.cancel();
    };
  }, [search, currentPage]);

  // useEffect(() => {
  //   listOfUsersFeedback();
  // }, [ search]);

  const debouncedSetSearch = debounce((value) => {
    setSearch(value);
  }, 300);
  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="barge-common-box">
          <Index.Box className="common-box">
            <Index.Box className="user-list-flex">
              <Index.Box className="admin-page-title-main">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  Users Feedback List
                </Index.Typography>
              </Index.Box>
              <Index.Box className="userlist-btn-flex">
                <Index.Box className="user-search-main">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Search"
                        // name="search"
                        autoComplete="search"
                        value={search}
                        onChange={(e) => {
                          const newValue = e.target.value
                            .replace(/^\s+/, "")
                            .replace(/\s\s+/g, " ");
                          setCurrentPage(1);
                          setSearch(newValue);
                          // debouncedSetSearch(newValue);
                        }}
                      />
                      <span className="search-icon-box">
                        <img src={Index.Svg.search} className="search-icon" />
                      </span>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row-userlist"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box">
                      <Index.Box className="user-feedback-table-main page-table-main">
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                        >
                          <Index.Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                            className="table"
                          >
                            <Index.TableHead className="table-head cus-table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  S.No
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Name
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Username
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Recent Feedback
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Date
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Time
                                </Index.TableCell>
                                {(rolePermission &&
                                  rolePermission?.roleType?.rolePermission?.includes(
                                    "UserFeedbackList_view"
                                  )) ||
                                  // rolePermission?.roleType?.rolePermission?.includes(
                                  //   "UserFeedbackList_edit"
                                  // ) ||
                                  // rolePermission?.roleType?.rolePermission?.includes(
                                  //   "UserFeedbackList_delete"
                                  // ) ||
                                  (rolePermission &&
                                    rolePermission?.isAdmin === true) ? (
                                  <>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Action
                                    </Index.TableCell>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {!loader ? (
                                usersFeedbackList?.length > 0 ? (
                                  usersFeedbackList?.map((row, index) => (
                                    <Index.TableRow
                                      key={row._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                      >
                                        {(currentPage - 1) * 10 + index + 1}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                      >
                                        {row?.userId?.name
                                          ? row?.userId?.name
                                          : "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td "
                                        align="center"
                                       >
                                       <span className=" cus-user-name-navigate"
                                        onClick={() => {
                                          navigate('/dashboard/user-view',
                                            {
                                              state: {
                                                data: row?.userId?._id,
                                                pathName: location.pathname
                                              },
                                            }
                                          )
                                        }}
                                       >{row?.userId?.userName
                                          ? row?.userId?.userName
                                          : "-"}</span> 
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                      >
                                        
                                        {row?.feedBackData?.length > 0 ? (
                                          row?.feedBackData[0].message.length >
                                            50 ? (
                                            <PageIndex.LightTooltip
                                              placement="top-start"
                                              title={
                                                row
                                                  ?.feedBackData[// row?.feedBackData.length - 
                                                  0].message
                                              }
                                            >
                                              <span className="wrap-text">
                                                {row?.feedBackData[0].message.slice(
                                                  0,
                                                  50
                                                )}
                                              </span>
                                            </PageIndex.LightTooltip>
                                          ) : (
                                            <span>
                                              {row?.feedBackData[0].message}
                                            </span>
                                          )
                                        ) : (
                                          "-"
                                        )}

                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                      >
                                        {row?.feedBackData &&
                                          row?.feedBackData?.length > 0
                                          ? moment(
                                            row?.feedBackData[
                                              0
                                            ]?.dateTime
                                          ).format("DD/MM/YYYY")
                                          : "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                      >
                                        {row?.feedBackData &&
                                          row?.feedBackData?.length > 0
                                          ? moment(
                                            row?.feedBackData[
                                              0
                                            ]?.dateTime
                                          ).format("hh:mm A")
                                          : "-"}
                                      </Index.TableCell>
                                      {(rolePermission &&
                                        rolePermission?.roleType?.rolePermission?.includes(
                                          "UserFeedbackList_view"
                                        )) ||
                                        (rolePermission &&
                                          rolePermission?.isAdmin === true) ? (
                                        <>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            className="table-td"
                                            align="center"
                                          >
                                            <Index.Box className="userdata-btn-flex">
                                              <PageIndex.LightTooltip title="View">
                                                <Index.IconButton
                                                  color="primary"
                                                  aria-label="upload picture"
                                                  component="label"
                                                  onClick={() =>
                                                    navigate(
                                                      `/dashboard/single-user-feedback-list`,
                                                      {
                                                        state: {
                                                          data: row?.userId?._id
                                                        }
                                                      }
                                                    )
                                                  }
                                                >
                                                  <Index.RemoveRedEyeIcon />
                                                </Index.IconButton>
                                              </PageIndex.LightTooltip>
                                            </Index.Box>
                                          </Index.TableCell>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </Index.TableRow>
                                  ))
                                ) : (
                                  <PageIndex.RecordNotFound colSpan={7} />
                                )
                              ) : (
                                <PageIndex.TableLoader colSpan={7} />
                              )}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            {usersFeedbackList?.length ? (
              <Index.Box className="pagination-main">
                <Index.Pagination
                  count={pageCount}
                  page={currentPage}
                  onChange={(event, value) => {
                    setCurrentPage(value);
                  }}
                  variant="outlined"
                  shape="rounded"
                  className="pagination"
                />
              </Index.Box>
            ) : (
              ""
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default UsersFeedbackList;
