import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#d75ccd",
    color: "#000000",
    fontSize: "11px",
    fontFamily: "Poppins-Regular",
    padding: "5px 9px",
    lineHeight: "19px",
    maxWidth:'200px',
    borderRadius: "5px",
  
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#d75ccd", // Customize arrow color
  },
}));