import { useState } from "react";
import { AdminForgotPassword } from "../../../redux/slices/adminService";
import Index from "../../../component/Index";
import PageIndex from "../../PageIndex";
import { isForgot } from "../../../redux/slices/AdminSlice";

const ForgotPassword = () => {
  const dispatch = PageIndex.useDispatch();
  const navigate = PageIndex.useNavigate();
  const [loading, setLoading] = useState(false)

  const SendEmail = (values) => {
    setLoading(true);
    AdminForgotPassword(values).then((val) => {
      if (val?.status == 200) {
        const id = val?.data?._id;
        navigate("/otp", { state: { id: id } });
        dispatch(isForgot(false))
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 3000)
      }
    });
  };
  return (

    <Index.Box className="admin-login-main-flex">
      <Index.Box className="admin-login-left-main">
        <Index.Box className="login-left-bg">
          <Index.Box className="auth-logo-box">
            <img
              alt="logo_img"
              src={Index.Jpg.authlogo}
              className="auth-logo"

            />
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="admin-login-right-main">
        <Index.Box className="admin-login-box">
          <Index.Box className="admin-login-main">
            <PageIndex.Formik
              validationSchema={PageIndex.adminForgotPasswordSchema}
              initialValues={{
                email: "",
              }}
              onSubmit={SendEmail}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                touched,
                handleSubmit,
                setFieldValue
              }) => (
                <PageIndex.Form>
                  <Index.Box className="admin-login-inner">
                    <Index.Typography
                      component="h2"
                      variant="h2"
                      className="admin-wel-text"
                    >
                      Forgot Password
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="admin-sign-para common-para"
                    >
                      Enter your Email and we'll send you an OTP on your Email!
                    </Index.Typography>
                    <Index.Box className="input-box">
                      <Index.FormHelperText className="form-lable">
                        Email
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          onBlur={handleBlur}
                          value={values.email}
                          placeholder="Email"
                          onChange={(e) => {
                            const newValue = e.target.value.replace(
                              /\s/g,
                              ""
                            );
                            setFieldValue("email", newValue)
                          }}
                          fullWidth
                          name="email"
                          id="fullWidth"
                          className="form-control email-input"
                          type="text"
                          autocomplete="off"
                          error={Boolean(
                            errors?.email ? touched?.email : undefined
                          )}
                          helperText={
                            touched?.email ? errors?.email : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="btn-main-primary login-btn-main ">
                      <Index.Button
                        className="btn-primary login-btn forgot-button"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? (
                          <PageIndex.ButtonLoader color="white" loading={loading} />
                        ) : (
                          "Submit"
                        )}
                      </Index.Button>
                      <Index.Typography
                        component="span"
                        variant="span"
                        className="admin-sign-para common-para back-text"
                        onClick={() => {
                          navigate("/");
                        }}
                      >
                        Go to Login page?
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className='back-text-box'>
                    </Index.Box>
                  </Index.Box>
                </PageIndex.Form>
              )}
            </PageIndex.Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>

  );
};
export default ForgotPassword;
