import React, { useEffect, useState } from "react";
import { logout } from "../../../redux/slices/AdminSlice";
import Index from "../../Index";
import PageIndex from "../../../container/PageIndex";
import { imageUrl } from "../../../config/dataService";

export default function Header(props) {
  // for header profile menu
  const navigate = PageIndex.useNavigate();
  const dispatch = PageIndex.useDispatch();
  const { adminProfile } = PageIndex.useSelector((state) => state.admin);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [screen, setScreen] = useState("");

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlelogout = () => {
    dispatch(logout());
    navigate("/");
  };
  useEffect(() => {
    console.log(window.screen.width, 226);
    if (window.screen.width < 768) {
      setScreen("Mobile");
    } else {
      setScreen("Desktop");
    }
  }, [window.screen.availHeight]);
  return (
    <>
      <Index.Box
        className={`admin-header-main ${props.open ? "active" : "admin-header-deactive"
          }`}
      >
        <Index.Box className="admin-header-left">
          <Index.Box className="admin-header-logo-main">
            <img src={Index.Png.socialMediaLogo} className="admin-sidebar-logo" alt='logo' />
            {/* <Index.Button className='admin-bergur-button' onClick={() => {
              props.setOpen(!props.open)
              document.body.classList[props.open ? "add" : "remove"](
                "body-overflow"
              );
            }}>
              <img src={Index.Svg.bergurmenu} className="bergurmenu-icon" alt='dashboard logo'></img>
            </Index.Button> */}

            <div class="nav">
              <input type="checkbox" checked={props.open} onChange={() => {
                props.setOpen(!props.open)
                document.body.classList[props.open ? "add" : "remove"](
                  "body-overflow"
                );
              }} />

              <svg style={{ height: '30px' }}>
                <use href="#menu" />
                <use href="#menu" />
              </svg>

            </div>

            <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
              <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 56" id="menu">
                <path d="M48.33,45.6H18a14.17,14.17,0,0,1,0-28.34H78.86a17.37,17.37,0,0,1,0,34.74H42.33l-21-21.26L47.75,4" />
              </symbol>
            </svg>

          </Index.Box>
          <Index.Box className="admin-header-right">
            <Index.Button className="admin-header-icon-box">
              <img
                src={Index.Svg.notification}
                className="admin-header-icon"
                alt="dashboard logo"
              ></img>
            </Index.Button>
            <Index.Button className="admin-header-icon-box">
              <img
                src={Index.Svg.settings}
                className="admin-header-icon"
                alt="dashboard bell icon"
              ></img>
            </Index.Button>
            <Index.Box className="admin-header-drop-main">
              <Index.Button
                className="drop-header-btn"
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <Index.Box className="flex-drop-main">
                  <img
                    src={
                      adminProfile?.profile
                        ? `${imageUrl}${adminProfile?.profile}`
                        : PageIndex?.Jpg?.dummyImage
                    }
                    className="admin-header-profile-icon"
                    alt="dashboard bell icon"
                  ></img>
                  <Index.Box className="title-admin-drop">
                    <Index.Typography
                      variant="h5"
                      component="h5"
                      className="admin-header-drop"
                    >
                      {adminProfile.name}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Button>
            </Index.Box>
            <Index.Menu
              className="drop-header-menu"
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Index.MenuItem
                onClick={handleClose}
                className="drop-header-menuitem"
              >
                <Index.Link
                  to="/dashboard/edit-profile"
                  className="text-decoration-none drop-header-menuitemlink pink-color"
                >
                  My Profile
                </Index.Link>
              </Index.MenuItem>
              <Index.MenuItem
                onClick={handlelogout}
                className="drop-header-menuitem"
              >
                <Index.Box className="text-decoration-none drop-header-menuitemlink pink-color">
                  Sign Out
                </Index.Box>
              </Index.MenuItem>
            </Index.Menu>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
