import React, { useState, useEffect } from "react";
import PageIndex from "../../container/PageIndex";
import Index from "../../container/Index";
import { orderBy } from "lodash"; // Import lodash orderBy function

const CommonTable = ({
  data,
  currentPage,
  columns,
  setCurrentPage,
  columnMap,
  loader,
  sortableColumns,
  className,
  pageCount,
  handleOpen,
  setPageCount,
  title,
}) => {
  const { rolePermission } = PageIndex.useSelector((state) => state.admin);
  const [sortedData, setSortedData] = useState([]);
  const [sortOrder, setSortOrder] = useState({});
  const [currentRows, setCurrentRows] = useState([]);
  const [rowsPerPage] = useState(10);
  const navigate = PageIndex.useNavigate();
  const location = PageIndex.useLocation();
  useEffect(() => {
    if (data.length > 0) {
      const initialData = data.map((row, index) => ({
        ...row,
        sNo: (currentPage - 1) * rowsPerPage + 1 + index,
      }));
      setSortedData(initialData);
    } else {
      setSortedData([]);
      setCurrentPage(1);
      setPageCount(1);
    }
  }, [data, currentPage]);

  console.log(sortedData, 16);
  const handleSort = (column) => {
    if (!sortableColumns.includes(column)) {
      return;
    }
    let newSortOrder = {};
    if (sortOrder[column]) {
      newSortOrder[column] = sortOrder[column] === "asc" ? "desc" : "asc";
    } else {
      newSortOrder[column] = "asc";
    }
    setSortOrder(newSortOrder);
    const sorted = orderBy(data, [columnMap[column]], [newSortOrder[column]]);

    const updatedSortedData = sorted.map((row, index) => ({
      ...row,
      sNo: (currentPage - 1) * rowsPerPage + 1 + index,
    }));

    setSortedData(updatedSortedData);
  };

  const resetSortOrder = () => {
    setSortOrder({});
  };
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  useEffect(() => {
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentData = sortedData;
    // const currentData = sortedData.slice(indexOfFirstRow, indexOfLastRow);
    setCurrentRows(currentData);
  }, [currentPage, sortedData]);

  return (
    <>
      <Index.Box className="admin-dash-box">
        <Index.Box className={`page-table-main ${className}`}>
          <Index.TableContainer
            component={Index.Paper}
            className="table-container"
          >
            <Index.Table
              stickyHeader
              aria-label="simple table"
              className="table"
            >
              <Index.TableHead className="table-head cus-table-head">
                <Index.TableRow className="table-row">
                  {columns.map((column) => (
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      align="center"
                      key={column}
                      onClick={() => {
                        resetSortOrder();
                        handleSort(column);
                      }}
                      style={{
                        cursor: sortableColumns.includes(column)
                          ? "pointer"
                          : "default",
                      }}
                    >
                      <Index.Box className="drop-downup-arrow-main">
                        {column}

                        {sortOrder[column] === "asc" ? (
                          <img
                            className="drop-down-up-arrow"
                            src={PageIndex?.Png?.upArrow}
                            alt="Ascending"
                          />
                        ) : sortOrder[column] === "desc" ? (
                          <img
                            className="drop-down-up-arrow"
                            src={PageIndex?.Png?.downArrow}
                            alt="Descending"
                          />
                        ) : null}
                      </Index.Box>
                    </Index.TableCell>
                  ))}
                </Index.TableRow>
              </Index.TableHead>
              {/* 
              <Index.TableBody className="table-body">
                {!loader ? (
                  currentRows?.length > 0 ? (
                    currentRows.map((row, rowIndex) => (
                      <Index.TableRow key={rowIndex}>
                        {columns.map((column, colIndex) => (
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                            align="center"
                            key={`${rowIndex}-${colIndex}`}

                          >
{console.log(column,columnMap[column],columnMap,'nirmal')}
                            {
                              columnMap[column] === "actions" ? (
                                <Index.Box className="userdata-btn-flex">
                                  <PageIndex.LightTooltip
                                    title="View"
                                    className="tooltip-main"
                                  >
                                    <Index.IconButton
                                      color="primary"
                                      aria-label="upload picture"
                                      component="label"
                                      onClick={() => handleOpen(row)}
                                    >
                                      <Index.RemoveRedEyeIcon className="view-eye-Icon" />
                                    </Index.IconButton>
                                  </PageIndex.LightTooltip>
                                </Index.Box>
                              ) : columnMap[column] === "transactionId" ? (
                                row[columnMap[column]]?.length > 20 ? (
                                  <PageIndex.LightTooltip
                                    title={row[columnMap[column]]}
                                    arrow
                                  >
                                    <span>
                                      {row[columnMap[column]].substring(0, 20)}...
                                    </span>
                                  </PageIndex.LightTooltip>
                                ) : (
                                  row[columnMap[column]] || "-"
                                )
                              ) : row[columnMap[column]] == 0 ? (
                                0
                              ) :
                                columnMap[column] === "userId.userName" ? 
                                  <span
                                  className="cus-user-name-navigate"
                                  onClick={() => {
                                    if (columnMap[column] === "userId.userName") {
                                      navigate('/dashboard/user-view',
                                        {
                                          state: {
                                            data: row?.userId?._id,
                                            pathName: location.pathname
                                          },
                                        }
                                      )
                                    }

                                  }}

                                >{row.userId?.userName}</span> :
                                  (
                                    row[columnMap[column]] || "-"
                                  )}


                          </Index.TableCell>
                        ))}
                      </Index.TableRow>
                    ))
                  ) : (
                    <PageIndex.RecordNotFound colSpan={columns.length} />
                  )
                ) : (
                  <PageIndex.TableLoader colSpan={columns.length} />
                )}
              </Index.TableBody> */}
              <Index.TableBody className="table-body">
                {!loader ? (
                  currentRows?.length > 0 ? (
                    currentRows.map((row, rowIndex) => (
                      <Index.TableRow key={rowIndex}>
                        {columns.map((column, colIndex) => (
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                            align="center"
                            key={`${rowIndex}-${colIndex}`}
                          >
                            {console.log(column, columnMap[column], row)}
                            {columnMap[column] === "actions" ? (
                              <Index.Box className="userdata-btn-flex">
                                <PageIndex.LightTooltip
                                  title="View"
                                  className="tooltip-main"
                                >
                                  <Index.IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="label"
                                    onClick={() => handleOpen(row)}
                                  >
                                    <Index.RemoveRedEyeIcon className="view-eye-Icon" />
                                  </Index.IconButton>
                                </PageIndex.LightTooltip>
                              </Index.Box>
                            ) : columnMap[column] ===
                              "cryptoCoinType.tokenName" ? (
                              row.cryptoCoinType?.tokenName || "-"
                            ) : columnMap[column] === "transactionId" ? (
                              row[columnMap[column]]?.length > 20 ? (
                                <PageIndex.LightTooltip
                                  title={row[columnMap[column]]}
                                  arrow
                                >
                                  <span>
                                    {row[columnMap[column]].substring(0, 20)}...
                                  </span>
                                </PageIndex.LightTooltip>
                              ) : (
                                row[columnMap[column]] || "-"
                              )
                            ) : row[columnMap[column]] == 0 ? (
                              0
                            ) : columnMap[column] === "userId.userName" ? (
                              <span
                                className="cus-user-name-navigate"
                                onClick={() => {
                                  if (columnMap[column] === "userId.userName") {
                                    navigate("/dashboard/user-view", {
                                      state: {
                                        data: row?.userId?._id,
                                        pathName: location.pathname,
                                      },
                                    });
                                  }
                                }}
                              >
                                {row.userId?.userName}
                              </span>
                            ) : (
                              row[columnMap[column]] || "-"
                            )}
                          </Index.TableCell>
                        ))}
                      </Index.TableRow>
                    ))
                  ) : (
                    <PageIndex.RecordNotFound colSpan={columns.length} />
                  )
                ) : (
                  <PageIndex.TableLoader colSpan={columns.length} />
                )}
              </Index.TableBody>
            </Index.Table>
          </Index.TableContainer>
        </Index.Box>
      </Index.Box>

      {pageCount ? (
        <Index.Box className="pagination-main">
          <Index.Pagination
            page={currentPage}
            count={pageCount}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
            className="pagination"
          />
        </Index.Box>
      ) : null}
    </>
  );
};

export default CommonTable;
