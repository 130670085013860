import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import AdminReducer from './slices/AdminSlice';

const persistConfig = {
  key: 'root',
  storage,
}
const persistedReducer = persistReducer(persistConfig, AdminReducer)

export const store = configureStore({
  reducer: {
    admin: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
  },
})
export const persistor = persistStore(store)





//   key: "root",
//   storage: localStorage,
// };
// const persistedReducer = persistReducer(persistConfig, rootReducer); //persist reducer combine both local and root reducer
// export default () => {
//   const composeEnhancers =
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//   let store = createStore(persistedReducer, {}, composeEnhancers(applyMiddleware(thunk))); //apply middleware to run rootreducer
//   let persistor = persistStore(store);
//   return { store, persistor };
// };