import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import dayjs from "dayjs";
import {
  cancelContestAction,
  deleteContestAction,
  getAllSubContest,
  searchSubContenstparticipant,
} from "../../../../redux/slices/adminService";
import moment from "moment";
import { debounce } from "lodash";
const ViewSubContest = () => {
  const typeList = [
    { id: 1, value: "today", key: "Today" },
    { id: 2, value: "overview", key: "Overview" },
  ];

  const navigate = PageIndex.useNavigate();
  const contestData = PageIndex.useLocation();
  console.log(contestData, "contestData");

  const [contestList, setContestList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [editData, setEditData] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [openCancelModel, setOpenCancelModel] = useState(false);
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false);
  const { rolePermission } = PageIndex.useSelector((state) => state.admin);
  const ITEM_HEIGHT = 48;

  const open = Boolean(anchorEl);
  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setEditData(row);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function convertTo12HourFormat(time24) {
    // Split the time string into hours, minutes, and seconds
    const [hour, minute, second] = time24.split(":");

    // Convert hour to an integer and determine AM or PM
    let hour12 = parseInt(hour, 10);
    const ampm = hour12 >= 12 ? "PM" : "AM";

    // Adjust hours for 12-hour format
    hour12 = hour12 % 12 || 12; // Converts '0' or '12' to '12'

    // Return formatted string in 12-hour format
    return `${hour12}:${minute}:${second} ${ampm}`;
  }

  function formatDate(originalDateStr) {
    const originalDate = new Date(originalDateStr);
    const day = originalDate.getDate().toString().padStart(2, "0");
    const month = (originalDate.getMonth() + 1).toString().padStart(2, "0");
    const year = originalDate.getFullYear().toString();
    const formattedDateStr = `${day}/${month}/${year}`;
    return formattedDateStr;
  }

  const handlePageChange = (event, value) => {
    // setLoader(true);
    setCurrentPage(value);
  };
  // Sorting functions for different columns
  const sortFunctions = {
    contestName: (a, b) => {
      return sortOrder === "asc"
        ? a.contestName.localeCompare(b.contestName)
        : b.contestName.localeCompare(a.contestName);
    },
    category: (a, b) => {
      return sortOrder === "asc"
        ? a.category.localeCompare(b.category)
        : b.category.localeCompare(a.category);
    },
    startDate: (a, b) => {
      return sortOrder === "asc"
        ? moment(a.startDate).diff(moment(b.startDate))
        : moment(b.startDate).diff(moment(a.startDate));
    },
    endDate: (a, b) => {
      return sortOrder === "asc"
        ? moment(a.endDate).diff(moment(b.endDate))
        : moment(b.endDate).diff(moment(a.endDate));
    },
    startTime: (a, b) => {
      const timeA = moment(a.startTime, "hh:mm A");
      const timeB = moment(b.startTime, "hh:mm A");
      return sortOrder === "asc" ? timeA.diff(timeB) : timeB.diff(timeA);
    },
    endTime: (a, b) => {
      const timeA = moment(a.endTime, "hh:mm A");
      const timeB = moment(b.endTime, "hh:mm A");
      return sortOrder === "asc" ? timeA.diff(timeB) : timeB.diff(timeA);
    },
  };

  //api of users contest list
  const listOfContest = () => {
    setLoader(true);
    const contestId = contestData.state._id;
    getAllSubContest(currentPage, contestId).then((res) => {
      if (res?.status === 200) {
        let sortedContestList = res?.data;
        if (sortBy && sortFunctions[sortBy]) {
          sortedContestList = sortedContestList.sort(sortFunctions[sortBy]);
        }
        setContestList(sortedContestList);
        setLoader(false);
        if (res?.currentPage) {
          setCurrentPage(res?.currentPage);
        }

        setPageCount(res?.totalPages);
      } else {
        setContestList([]);
      }
    });
  };

  const contestSearch = () => {
    const obj = {
      startDate: selectedFromDate
        ? dayjs(selectedFromDate).format("DD/MM/YYYY")
        : "",
      endDate: selectedToDate ? dayjs(selectedToDate).format("DD/MM/YYYY") : "",
      search: search,
      page: currentPage,
    };
    console.log(obj, 110);
    searchSubContenstparticipant(obj).then((res) => {
      if (res?.status == 200) {
        console.log(res);
        setLoader(false);
        if (res?.currentPage) {
          setCurrentPage(res?.currentPage);
        }
        setPageCount(res?.totalPages);
        setContestList(res?.data);
      } else {
        setContestList([]);
      }
    });
  };
  useEffect(() => {
    const debouncedFetch = debounce(() => {
      if (search || selectedFromDate || selectedToDate) {
        contestSearch();
      } else {
        listOfContest();
      }
    }, 300);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [
    search,
    selectedFromDate,
    selectedToDate,
    currentPage,
    sortBy,
    sortOrder,
  ]);

  const handleCloseDelete = () => setOpenDelete(false);
  //delete contest
  const deleteContest = () => {
    setIsDeleteButtonDisabled(true);
    deleteContestAction({ id: editData._id }).then((data) => {
      setOpenDelete(false);
      if (data?.status == 200) {
        setLoader(true);
        setIsDeleteButtonDisabled(false);
        listOfContest();
      }
    });
  };

  const handleCloseCancelModel = () => setOpenCancelModel(false);

  //cancel contest
  const handleCancel = () => {
    setIsDeleteButtonDisabled(true);
    cancelContestAction({ id: editData._id }).then((data) => {
      setOpenCancelModel(false);
      if (data?.status == 200) {
        setLoader(true);
        setIsDeleteButtonDisabled(false);
        listOfContest();
      }
    });
  };
  return (
    <>
      <Index.Box className="dashboard-content">
        <PageIndex.BackButton
          onClick={() => navigate("/dashboard/contest-list")}
        />
        <Index.Box className="barge-common-box">
          <Index.Box className="common-box">
            <Index.Box className="user-list-flex">
              <Index.Box className="admin-page-title-main">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  Sub Contest List
                </Index.Typography>
              </Index.Box>

              <Index.Box className="userlist-btn-flex">
                <Index.Box className="add-role-permission">
                  <Index.Box className="adduser-btn-main btn-main-primary">
                    {(rolePermission &&
                      rolePermission?.roleType?.rolePermission?.includes(
                        "ContestList_add"
                      )) ||
                    (rolePermission && rolePermission?.isAdmin === true) ? (
                      <>
                        <Index.Button
                          className="adduser-btn btn-primary"
                          onClick={() => navigate("/dashboard/add-contest")}
                        >
                          <img
                            src={Index.Svg.plus}
                            className="plus-icon"
                            alt="plus icon"
                          />
                          Add
                        </Index.Button>
                      </>
                    ) : (
                      ""
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row-userlist"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box">
                      <Index.Box className="contest-table-main page-table-main">
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                        >
                          <Index.Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                            className="table"
                          >
                            <Index.TableHead className="table-head cus-table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  S.No
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  <Index.Box className="drop-downup-arrow-main">
                                    Contest Name{" "}
                                  </Index.Box>
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  <Index.Box className="drop-downup-arrow-main">
                                    Category{" "}
                                  </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  <Index.Box className="drop-downup-arrow-main">
                                    Contest Type{" "}
                                  </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  <Index.Box className="drop-downup-arrow-main">
                                    Contest Start{" "}
                                  </Index.Box>
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Contest End{" "}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Contest Result{" "}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Status
                                </Index.TableCell>
                                {(rolePermission &&
                                  rolePermission?.roleType?.rolePermission?.includes(
                                    "ContestList_view"
                                  )) ||
                                rolePermission?.roleType?.rolePermission?.includes(
                                  "ContestList_edit"
                                ) ||
                                rolePermission?.roleType?.rolePermission?.includes(
                                  "ContestList_delete"
                                ) ||
                                (rolePermission &&
                                  rolePermission?.isAdmin === true) ? (
                                  <>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Action
                                    </Index.TableCell>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {!loader ? (
                                contestList?.length > 0 ? (
                                  contestList?.map((row, index) => (
                                    <>
                                      <Index.TableRow
                                        key={row._id}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                        >
                                          {(currentPage - 1) * 10 + index + 1}
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                        >
                                          {row?.contestName
                                            ? row?.contestName
                                            : "-"}
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                          // title= {row?.feedBackData && row?.feedBackData?.length>0 ? row?.feedBackData[row?.feedBackData.length-1].message:"-"}
                                        >
                                          {row?.category ? row?.category : "-"}
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                        >
                                          {row?.contestType
                                            ? row?.contestType == "quickPlay"
                                              ? "Quick play"
                                              : "Mega play"
                                            : "-"}
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                        >
                                          {row?.startDate
                                            ? formatDate(row?.startDate) + "  "
                                            : ""}
                                          {convertTo12HourFormat(
                                            row?.startEntryTime
                                              ? row?.startEntryTime
                                              : ""
                                          )}
                                        </Index.TableCell>
                                        <Index.TableCell
                                          compon
                                          ent="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                        >
                                          {row?.endDate
                                            ? formatDate(row?.endDate) + " "
                                            : " "}
                                          {convertTo12HourFormat(
                                            row?.endEntryTime
                                              ? row?.endEntryTime
                                              : ""
                                          )}
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                        >
                                          {row?.resultDate
                                            ? formatDate(row?.resultDate) + " "
                                            : ""}
                                          {convertTo12HourFormat(
                                            row?.resultTime
                                              ? row?.resultTime
                                              : ""
                                          )}
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                        >
                                          {!row?.isClosed && row?.isCancel
                                            ? "Cancelled"
                                            : row?.isAnnounceWinner
                                            ? "completed"
                                            : "Started"}
                                        </Index.TableCell>

                                        {(rolePermission &&
                                          rolePermission?.roleType?.rolePermission?.includes(
                                            "ContestList_view"
                                          )) ||
                                        (rolePermission &&
                                          rolePermission?.roleType?.rolePermission?.includes(
                                            "ContestList_edit"
                                          )) ||
                                        rolePermission?.roleType?.rolePermission?.includes(
                                          "ContestList_delete"
                                        ) ||
                                        (rolePermission &&
                                          rolePermission?.isAdmin === true) ? (
                                          <>
                                            <Index.TableCell
                                              component="td"
                                              variant="td"
                                              scope="row"
                                              className="table-td"
                                              align="center"
                                            >
                                              <Index.IconButton
                                                className="show-chat-button show-analysis-button"
                                                onClick={(e) => {
                                                  navigate(
                                                    `/dashboard/view-contest-analysis`,
                                                    {
                                                      state: {
                                                        editData: row,
                                                        contestId: contestData,
                                                      },
                                                    }
                                                  );
                                                }}
                                              >
                                                <img
                                                  src={
                                                    PageIndex.Png.dataAnalysis
                                                  }
                                                />
                                              </Index.IconButton>
                                            </Index.TableCell>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </Index.TableRow>
                                    </>
                                  ))
                                ) : (
                                  <PageIndex.RecordNotFound colSpan={9} />
                                )
                              ) : (
                                <PageIndex.TableLoader colSpan={9} />
                              )}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="pagination-main">
              <Index.Pagination
                count={pageCount}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
                className="pagination"
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <PageIndex.CancelModel
        openDelete={openCancelModel}
        handleCloseDelete={handleCloseCancelModel}
        handleDeleteRecord={handleCancel}
        isDeleteButtonDisabled={isDeleteButtonDisabled}
        //  text={}
      />
      <PageIndex.DeleteModal
        openDelete={openDelete}
        handleCloseDelete={handleCloseDelete}
        handleDeleteRecord={deleteContest}
        isDeleteButtonDisabled={isDeleteButtonDisabled}
      />
    </>
  );
};

export default ViewSubContest;
