import React, { useState } from "react";
import PropTypes from "prop-types";
import Index from "../../../Index";
import {
  autoLogout,
  changePasswordProfile,
  updateProfile,
} from "../../../../redux/slices/adminService";
import { imageUrl } from "../../../../config/dataService";
import PageIndex from "../../../PageIndex";
import { logout, updateAdmin, updateRolePermission } from "../../../../redux/slices/AdminSlice";
// for tabs design

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EditProfile() {
 
  const navigate = PageIndex.useNavigate();
  const dispatch=PageIndex.useDispatch()
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { adminProfile,token } = PageIndex.useSelector((state) => state.admin);
  const [value, setValue] = useState(0);
  const [image, setImage] = useState("");
  const [loader, setLoader] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    autoLogout(token).then((res) => {
      if (res?.data?.status == 200) {
        dispatch(updateRolePermission(res?.data?.data));
      }
      else if (res?.response?.data?.status == 401) {
        dispatch(logout());
        navigate("/");
      }
    })
  };

  const handleClickShowPassword = (field) => {
    switch (field) {
      case "oldPassword":
        setShowOldPassword((show) => !show);
        break;
      case "newPassword":
        setShowNewPassword((show) => !show);
        break;
      case "confirmPassword":
        setShowConfirmPassword((show) => !show);
        break;
      default:
        break;
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //edit profile call
  const handleEditProfile = (values) => {
    const formData = new FormData();
    formData.append("name", values?.name);
    formData.append("email", values?.email);
    formData.append("phoneNumber", values?.phoneNumber);
    {
      image && formData.append("profile", image);
    }
    setLoader(true);
    dispatch(updateProfile(formData)).then((data) => {
      if (data?.payload?.status == 200) {
        console.log(data.payload, 102);
        dispatch(updateAdmin(data.payload.data));
        navigate("/dashboard");
        setLoader(false);
      } else {
        setTimeout(() => {
          setLoader(false);
        }, 2000);
      }
    });
  };

  //Change Password
  const handleChangePasswordSubmit = (values, { resetForm }) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("oldPassword", values?.oldPassword);
    urlencoded.append("newPassword", values?.newPassword);
    setLoader(true);
    changePasswordProfile(urlencoded).then((data) => {
      if (data == 200) {
        setLoader(false);
        resetForm({
          ...values,
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      } else {
        setTimeout(() => {
          setLoader(false);
        }, 2000);
      }
    });
  };
  return (
    <>
      <Index.Box className="dashboard-content edit-profile-containt">
        <Index.Typography
          className="admin-page-title"
          component="h2"
          variant="h2"
        >
          Account Settings
        </Index.Typography>

        <Index.Box className="tabs-main-box">
          <Index.Box sx={{ width: "100%" }}>
            <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Index.Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="admin-tabs-main"
              >
                <Index.Tab
                  label="Edit Profile"
                  {...a11yProps(0)}
                  className="admin-tab"
                />
                <Index.Tab
                  label="Change Password"
                  {...a11yProps(1)}
                  className="admin-tab"
                />
              </Index.Tabs>
            </Index.Box>
            <PageIndex.Formik
              initialValues={{
                name: adminProfile?.name ? adminProfile?.name : "",
                email: adminProfile?.email ? adminProfile?.email : "",
                phoneNumber: adminProfile?.phoneNumber
                  ? adminProfile?.phoneNumber
                  : "",
                profile: adminProfile?.profile ? adminProfile?.profile : "",
              }}
              onSubmit={handleEditProfile}
              validationSchema={PageIndex.EditProfileSchema}
            >
              {({ values, handleBlur, setFieldValue, errors, touched }) => (
                <PageIndex.Form>
                  <TabPanel value={value} index={0} className="admin-tabpanel">
                    <Index.Box className="tabpanel-main">
                      <Index.Box className="page-border">
                        <Index.Box className="admin-page-title-main cus-change-password-box">
                          <Index.Typography
                            className="admin-page-title"
                            component="h2"
                            variant="h2"
                          >
                            Edit Profile
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="edit-profile-flex">
                          <Index.Box className="file-upload-btn-main">
                            <img
                              src={
                                image
                                  ? URL.createObjectURL(image)
                                  : values?.profile
                                  ? `${imageUrl}${adminProfile?.profile}`
                                  : PageIndex?.Jpg?.dummyImage
                              }
                              // onChange={(e) => e.target.files[0]}
                              className="upload-profile-img"
                              alt="upload img"
                            ></img>
                            <Index.Button
                              variant="contained"
                              component="label"
                              className="file-upload-btn"
                            >
                              <img
                                src={Index.Svg.edit}
                                className="upload-icon-img"
                                alt="upload img"
                              ></img>
                              <input
                                hidden
                                accept="image/*"
                                name="profile"
                                type="file"
                                onChange={(e) => {
                                  setImage(e.target.files[0]);
                                  setFieldValue("profile", e.target.files[0]);
                                }}
                              />
                            </Index.Button>
                          </Index.Box>
                        </Index.Box>
                        {errors?.profile && touched?.profile && (
                          <p className="image-error">{errors?.profile}</p>
                        )}
                        <Index.Box className="add-user-data-main">
                          <Index.Box sx={{ width: 1 }} className="grid-main">
                            <Index.Box
                              display="grid"
                              className="display-row-edit-profile"
                              gridTemplateColumns="repeat(12, 1fr)"
                              gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                            >
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 6",
                                  md: "span 6",
                                  lg: "span 6",
                                }}
                                className="grid-column"
                              >
                                <Index.Box className="input-box add-user-input">
                                  <Index.FormHelperText className="form-lable">
                                    Name{" "}
                                    <span className="mandatory-asterisk">
                                      *
                                    </span>
                                  </Index.FormHelperText>
                                  <Index.Box className="form-group">
                                    <Index.TextField
                                      fullWidth
                                      name="name"
                                      placeholder="Name"
                                      value={values.name}
                                      onChange={(e) => {
                                        const newValue = e.target.value
                                          .replace(/^\s+/, "")
                                          .replace(/\s\s+/g, " ");
                                        setFieldValue("name", newValue);
                                      }}
                                      id="fullWidth"
                                      className="form-control"
                                      onBlur={handleBlur}
                                      error={Boolean(
                                        errors?.name ? touched?.name : undefined
                                      )}
                                      helperText={
                                        touched?.name ? errors?.name : undefined
                                      }
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 6",
                                  md: "span 6",
                                  lg: "span 6",
                                }}
                                className="grid-column"
                              >
                                <Index.Box className="input-box add-user-input">
                                  <Index.FormHelperText className="form-lable">
                                    Email{" "}
                                    <span className="mandatory-asterisk">
                                      *
                                    </span>
                                  </Index.FormHelperText>
                                  <Index.Box className="form-group">
                                    <Index.TextField
                                      name="email"
                                      value={values.email}
                                      onChange={(e) => {
                                        const newValue = e.target.value.replace(
                                          /\s/g,
                                          ""
                                        );
                                        setFieldValue("email", newValue);
                                      }}
                                      onBlur={handleBlur}
                                      fullWidth
                                      id="fullWidth"
                                      className="form-control email-input "
                                      placeholder="Email"
                                      error={Boolean(
                                        errors?.email
                                          ? touched?.email
                                          : undefined
                                      )}
                                      helperText={
                                        touched?.email
                                          ? errors?.email
                                          : undefined
                                      }
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 6",
                                  md: "span 6",
                                  lg: "span 6",
                                }}
                                className="grid-column"
                              >
                                <Index.Box className="input-box add-user-input">
                                  <Index.FormHelperText className="form-lable">
                                    Mobile number{" "}
                                    <span className="mandatory-asterisk">
                                      *
                                    </span>
                                  </Index.FormHelperText>
                                  <Index.Box className="form-group">
                                    <Index.TextField
                                      fullWidth
                                      id="fullWidth"
                                      className="form-control"
                                      type="text"
                                      placeholder="Mobile Number"
                                      name="phoneNumber"
                                      value={values.phoneNumber}
                                      onChange={(e) => {
                                        const newValue = e.target.value.replace(
                                          /\D+/g,
                                          ""
                                        );
                                        if (newValue.length <= 10) {
                                          setFieldValue(
                                            "phoneNumber",
                                            newValue
                                          );
                                        }
                                      }}
                                      onBlur={handleBlur}
                                      error={Boolean(
                                        errors?.phoneNumber
                                          ? touched?.phoneNumber
                                          : undefined
                                      )}
                                      helperText={
                                        touched?.phoneNumber
                                          ? errors?.phoneNumber
                                          : undefined
                                      }
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="user-btn-flex change-pass-flex">
                              <Index.Box className="save-btn-main border-btn-main">
                                <Index.Button
                                  className="save-user-btn border-btn"
                                  type="submit"
                                  disabled={loader}
                                >
                                  {loader ? (
                                    <PageIndex.ButtonLoader
                                      color="white"
                                      size={14}
                                      loading={loader}
                                    />
                                  ) : (
                                    <>
                                      <img
                                        src={Index.Svg.save}
                                        className="user-save-icon"
                                        alt="Save Icon"
                                      />
                                      {adminProfile?._id ? "Update" : "Save"}
                                    </>
                                  )}
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </TabPanel>
                </PageIndex.Form>
              )}
            </PageIndex.Formik>
            <TabPanel value={value} index={1} className="admin-tabpanel">
              <Index.Box className="tabpanel-main">
                <Index.Box className="change-pass-main">
                  <Index.Box className="admin-page-title-main cus-change-password-box">
                    <Index.Typography
                      className="admin-page-title"
                      component="h2"
                      variant="h2"
                    >
                      Change Password
                    </Index.Typography>
                  </Index.Box>
                  <PageIndex.Formik
                    validationSchema={PageIndex.ChangePasswordSchemaProfile}
                    initialValues={{
                      oldPassword: "",
                      newPassword: "",
                      confirmPassword: "",
                    }}
                    onSubmit={handleChangePasswordSubmit}
                  >
                    {({
                      values,
                      errors,
                      handleBlur,
                      touched,
                      setFieldValue,
                    }) => (
                      <PageIndex.Form>
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                           Old Password
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              size="small"
                              name="oldPassword"
                              className="form-control input-with-radius password-form-control"
                              type={showOldPassword ? "text" : "password"}
                              // label="Password"
                              variant="outlined"
                              placeholder="Old Password"
                              autoComplete="off"
                              inputProps={{
                                className: "input_props",
                              }}
                              InputLabelProps={{
                                className: "add-formlabel",
                              }}
                              FormHelperTextProps={{
                                className: "input_label_props",
                              }}
                              value={values.oldPassword}
                              onChange={(e) => {
                                const newValue = e.target.value.replace(
                                  /\s/g,
                                  ""
                                );
                                setFieldValue("oldPassword", newValue);
                              }}
                              onBlur={handleBlur}
                              error={Boolean(
                                errors?.oldPassword
                                  ? touched?.oldPassword
                                  : undefined
                              )}
                              helperText={
                                touched?.oldPassword
                                  ? errors?.oldPassword
                                  : undefined
                              }
                              sx={{ mb: 3 }}
                              InputProps={{
                                // <-- This is where the toggle button is added.
                                endAdornment: (
                                  <Index.InputAdornment position="end">
                                    <Index.IconButton
                                      className="passwrd-eye"
                                      aria-label="toggle password visibility"
                                      onClick={() =>
                                        handleClickShowPassword("oldPassword")
                                      }
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showOldPassword ? (
                                         <Index.Visibility />
                                      ) : (
                                        <Index.VisibilityOff />
                                      )}
                                    </Index.IconButton>
                                  </Index.InputAdornment>
                                ),
                              }}
                            />
                          </Index.Box>
                        </Index.Box>

                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            New Password
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              size="small"
                              name="newPassword"
                              className="form-control input-with-radius password-form-control"
                              type={showNewPassword ? "text" : "password"}
                              // label="Password"
                              variant="outlined"
                              placeholder="New Password"
                              autoComplete="off"
                              inputProps={{
                                className: "input_props",
                              }}
                              InputLabelProps={{
                                className: "add-formlabel",
                              }}
                              FormHelperTextProps={{
                                className: "input_label_props",
                              }}
                              onBlur={handleBlur}
                              value={values.newPassword}
                              onChange={(e) => {
                                const newValue = e.target.value.replace(
                                  /\s/g,
                                  ""
                                );
                                setFieldValue("newPassword", newValue);
                              }}
                              error={Boolean(
                                errors?.newPassword
                                  ? touched?.newPassword
                                  : undefined
                              )}
                              helperText={
                                touched?.newPassword
                                  ? errors?.newPassword
                                  : undefined
                              }
                              sx={{ mb: 3 }}
                              InputProps={{
                                // <-- This is where the toggle button is added.
                                endAdornment: (
                                  <Index.InputAdornment position="end">
                                    <Index.IconButton
                                      className="passwrd-eye"
                                      aria-label="toggle password visibility"
                                      onClick={() =>
                                        handleClickShowPassword("newPassword")
                                      }
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showNewPassword ? (
                                         <Index.Visibility />
                                      ) : (
                                        <Index.VisibilityOff />
                                      )}
                                    </Index.IconButton>
                                  </Index.InputAdornment>
                                ),
                              }}
                            />
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Confirm Password
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              size="small"
                              name="confirmPassword"
                              placeholder="Confirm Password"
                              className="form-control input-with-radius password-form-control"
                              type={showConfirmPassword ? "text" : "password"}
                              // label="Password"
                              variant="outlined"
                              autoComplete="off"
                              inputProps={{
                                className: "input_props",
                              }}
                              InputLabelProps={{
                                className: "add-formlabel",
                              }}
                              FormHelperTextProps={{
                                className: "input_label_props",
                              }}
                              onBlur={handleBlur}
                              value={values.confirmPassword}
                              onChange={(e) => {
                                const newValue = e.target.value.replace(
                                  /\s/g,
                                  ""
                                );
                                setFieldValue("confirmPassword", newValue);
                              }}
                              error={Boolean(
                                errors?.confirmPassword
                                  ? touched?.confirmPassword
                                  : undefined
                              )}
                              helperText={
                                touched?.confirmPassword
                                  ? errors?.confirmPassword
                                  : undefined
                              }
                              sx={{ mb: 3 }}
                              InputProps={{
                                // <-- This is where the toggle button is added.
                                endAdornment: (
                                  <Index.InputAdornment position="end">
                                    <Index.IconButton
                                      className="passwrd-eye"
                                      aria-label="toggle password visibility"
                                      onClick={() =>
                                        handleClickShowPassword(
                                          "confirmPassword"
                                        )
                                      }
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showConfirmPassword ? (
                                         <Index.Visibility />
                                      ) : (
                                        <Index.VisibilityOff />
                                      )}
                                    </Index.IconButton>
                                  </Index.InputAdornment>
                                ),
                              }}
                            />
                          </Index.Box>
                        </Index.Box>

                        <Index.Box className="user-btn-flex change-pass-flex">
                          <Index.Box className="save-btn-main border-btn-main">
                            <Index.Button
                              className="save-user-btn border-btn"
                              type="submit"
                              disabled={loader}
                            >
                              {loader ? (
                                <PageIndex.ButtonLoader
                                  color="white"
                                  size={14}
                                  loading={loader}
                                />
                              ) : (
                                <>
                                  <img
                                    src={Index.Svg.save}
                                    className="user-save-icon"
                                    alt="Save Icon"
                                  />
                                  Save
                                </>
                              )}
                            </Index.Button>
                          </Index.Box>
                        </Index.Box>
                      </PageIndex.Form>
                    )}
                  </PageIndex.Formik>
                </Index.Box>
              </Index.Box>
            </TabPanel>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
