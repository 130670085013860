import React from "react";
import PulseLoader from "react-spinners/PulseLoader";
const ButtonLoader = ({ color, loading, ariaLabel, dataTestId,size }) => {
  return (
    <>
      <PulseLoader
        color={color}
        loading={loading}
        size={size||15}
        aria-label={ariaLabel}
        data-testid={dataTestId}

      />
    </>
  );
};

export default ButtonLoader;