import React, { useState } from "react";
import moment from "moment";
import Index from "../../../../Index";
import PageIndex from "../../../../PageIndex";
import { imageUrl } from "../../../../../config/dataService";

const TrendingPostComment = (props) => {
  const [expandedComments, setExpandedComments] = useState({});

  const navigate = PageIndex.useNavigate();
  const location = PageIndex.useLocation();

  const handleOpenImage = (image) => {
    if(image){
      props.setImageOpen(true)
      props.setImagePath(image)
    }
  }
  
  const handleToggle = (id) => {
    setExpandedComments((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <Index.Box className="common-box flex-common-box comment-overflow">
      {props?.comments?.length > 0 ? (
        props.comments.map((cmt) => {
          const cleanComment = cmt?.comment.replace(/<\/?[^>]+(>|$)/g, "");
          const isExpanded = expandedComments[cmt._id] || false;

          return (
            <Index.Box key={cmt._id} className="comment-box-wrap">
              <Index.Box className="user-image-box"          
               onClick={()=>handleOpenImage(cmt?.createdBy?.profile)}>
                <img
                  src={
                    cmt?.createdBy?.profile
                      ? cmt?.createdBy?.profile instanceof Blob
                        ? URL.createObjectURL(cmt?.createdBy?.profile)
                        : `${imageUrl}${cmt?.createdBy?.profile}`
                      : PageIndex?.Jpg?.dummyImage
                  }
                  className="user-post-user-img"
                  alt="User"
        
                />
              </Index.Box>
              <Index.Box className="comment-box">
                <Index.Box className="user-name-box">
                  <Index.Typography className="user-name">
                    <span
                      className="cus-user-name-navigate"
                      onClick={() => {
                        navigate('/dashboard/user-view', {
                          state: {
                            data: cmt?.createdBy?._id,
                            postId: props?.singledataId,
                            pathName: location.pathname,
                          },
                        });
                      }}
                    >
                      {cmt?.createdBy?.name}
                    </span>
                  </Index.Typography>
                  <Index.Typography className="comment-date-text">
                    {moment(cmt.createdAt).format("DD/MM/YYYY hh:mm A")}
                  </Index.Typography>
                </Index.Box>

                <Index.Typography className="comment-text">
                  {cleanComment.length > 100 ? (
                    <>
                      {isExpanded ? (
                        <>
                          {cleanComment}
                          <button onClick={() => handleToggle(cmt._id)}>
                            Show Less
                          </button>
                        </>
                      ) : (
                        <>
                          {cleanComment.slice(0, 100) + "..."}
                          <button onClick={() => handleToggle(cmt._id)}>
                            Show More
                          </button>
                        </>
                      )}
                    </>
                  ) : (
                    cleanComment
                  )}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          );
        })
      ) : (
        <Index.Box className="no-comment-box">
          <Index.Box className="table-data-not-found">Record not found</Index.Box>
        </Index.Box>
      )}
    </Index.Box>
  );
};

export default TrendingPostComment;
