import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { imageUrl } from "../../../../config/dataService";
import { getAllProfileReportsList } from "../../../../redux/slices/adminService";
import { debounce } from "lodash";

const ConversionRequestList = ({
  open,
  isDeleteButtonDisabled,
  openDeclineModel,
  typeList,
  loader,
  setLoader,
  type,
  isViewData,
  setCurrentPage,
  handleType,
  handleClose,
  handleCloseDeclineModel,
  handleOpen,
  handleOpenDeclineModel,
  handleReason,
  singleWithdrawalData,
  withdrawalList,
  fetchWithrawalCrytoList,
  filterData,
  search,
  reason,
  setSearch,
  pageCount,
  currentPage,
  rolePermission,
  dayTypeList,
  dayType,
  handleDayType,
  actualTransactionId,
  setwithdrawalList,
  setActualTransactionId,
  actualTransactionIdError,
  setActualTransactionIdError,
}) => {

  const location = PageIndex.useLocation()
  const navigate = PageIndex.useNavigate()

  return (
    <Index.Box className="barge-common-box">
      <Index.Box className="common-box">
        <Index.Box className="user-list-flex">
          <Index.Box className="admin-page-title-main">
            <Index.Typography
              className="admin-page-title tittle-like-costam"
              component="h2"
              variant="h2"
            >
              List of Conversion Requests
            </Index.Typography>
          </Index.Box>
          <Index.Box className="userlist-btn-flex">

            <Index.Box className="input-box add-user-input request-input-box">
              <Index.Box className="form-group">
                <Index.FormControl className="request-dropdown">
                  <Index.Select
                    className="form-control select-drop-list request-drop-list"
                    name="type"
                    value={dayType}

                    onChange={handleDayType}
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                  >
                    {dayTypeList &&
                      dayTypeList?.map((val) => (
                        <Index.MenuItem
                          value={val?.value}
                          key={val?.id}
                          className="menuitem"
                        >
                          {val?.key}
                        </Index.MenuItem>
                      ))}
                  </Index.Select>
                </Index.FormControl>
              </Index.Box>
            </Index.Box>
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search"
                    onChange={(e) => {
                      const newValue = e.target.value
                        .replace(/^\s+/, "")
                        .replace(/\s\s+/g, " ");
                      setCurrentPage(1)
                      setSearch(newValue);
                      setwithdrawalList()
                      setLoader(true)
                    }}
                  />
                  <span className="search-icon-box">
                    <img src={Index.Svg.search} className="search-icon" />
                  </span>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="input-box add-user-input request-input-box">
              <Index.Box className="form-group">
                <Index.FormControl className="request-dropdown">
                  <Index.Select
                    className="form-control select-drop-list request-drop-list"
                    name="type"
                    value={type}
                    // defaultValue={type ? type : "ALL"}
                    onChange={handleType}
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                  >
                    {typeList &&
                      typeList?.map((val) => (
                        <Index.MenuItem
                          value={val?.value}
                          key={val?.id}
                          className="menuitem"
                        >
                          {val?.value}
                        </Index.MenuItem>
                      ))}
                  </Index.Select>
                </Index.FormControl>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dash-box">
          <Index.Box className="page-table-main action-column withdrawal-request-table-main">
            {/* <Index.Box className="page-table-main action-column conversion-table-main"> */}
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                stickyHeader
                aria-label="simple table"
                className="table"
              >
                <Index.TableHead className="table-head cus-table-head">
                  <Index.TableRow className="table-row">
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      align="center"
                    >
                      S.No.
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      align="center"
                    >
                      Username
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      align="center"
                    >
                      Email ID
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      align="center"
                    >
                      From
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      align="center"
                    >
                      To
                    </Index.TableCell>

                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      align="center"
                    >
                      Status
                    </Index.TableCell>
                    {(rolePermission &&
                      rolePermission?.roleType?.rolePermission?.includes(
                        "ConversionList_view"
                      )) ||
                      rolePermission?.roleType?.rolePermission?.includes(
                        "ConversionList_edit"
                      ) ||
                      (rolePermission && rolePermission?.isAdmin === true) ? (
                      <>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        >
                          Actions
                        </Index.TableCell>
                      </>
                    ) : (
                      ""
                    )}
                  </Index.TableRow>
                </Index.TableHead>

                <Index.TableBody className="table-body">
                  {!loader ? (
                    withdrawalList?.length > 0 ? (
                      withdrawalList?.map((row, index) => (
                        <Index.TableRow key={row?._id}>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                            align="center"
                          >
                            {(currentPage - 1) * 10 + index + 1}
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                            align="center"
                          >
                            <span className="cus-user-name-navigate" onClick={() => {
                              navigate('/dashboard/user-view',
                                {
                                  state: {
                                    data: row?.userId?._id,
                                    pathName: location.pathname
                                  },
                                }
                              )
                            }}>{row?.userId?.userName ? row?.userId?.userName : "-"}{" "}</span>
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                            align="center"
                          >
                            {row?.userId?.email ? row?.userId?.email : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                            align="center"
                          >
                            {row?.cryptoRequest === true
                              ? row?.currency?.coins
                                ? row?.currency?.coins
                                : "-"
                              : row?.cryptoCoinType?.tokenName
                                ? row?.cryptoCoinType?.tokenName
                                : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                            align="center"
                          >
                            {!row?.cryptoRequest === true
                              ? row?.currency?.coins
                                ? row?.currency?.coins
                                : "-"
                              : row?.cryptoCoinType?.tokenName
                                ? row?.cryptoCoinType?.tokenName
                                : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                            align="center"
                          >
                            {row?.requestStatus ? row?.requestStatus : "-"}
                          </Index.TableCell>
                          {(rolePermission &&
                            rolePermission?.roleType?.rolePermission?.includes(
                              "ConversionList_view"
                            )) ||
                            rolePermission?.roleType?.rolePermission?.includes(
                              "ConversionList_edit"
                            ) ||
                            (rolePermission &&
                              rolePermission?.isAdmin === true) ? (
                            <>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                                align="center"
                              >
                                <Index.Box className="userdata-btn-flex">
                                  {(rolePermission &&
                                    rolePermission?.roleType?.rolePermission?.includes(
                                      "ConversionList_view"
                                    )) ||
                                    (rolePermission &&
                                      rolePermission?.isAdmin === true) ? (
                                    <>
                                      <PageIndex.LightTooltip title="View">
                                        <Index.IconButton
                                          color="primary"
                                          aria-label="upload picture"
                                          component="label"
                                          onClick={() => handleOpen(row, true)}
                                        >
                                          <Index.RemoveRedEyeIcon className="view-eye-Icon" />
                                        </Index.IconButton>
                                      </PageIndex.LightTooltip>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {
                                    (row?.requestStatus === "Approved" || row?.requestStatus === "Pending") && (
                                      rolePermission &&
                                      (rolePermission?.roleType?.rolePermission?.includes("WithdrawalList_edit") ||
                                        rolePermission?.isAdmin === true)
                                    ) ? (
                                      <PageIndex.LightTooltip title={row?.requestStatus !== "Approved" ? "Approve" : "Approved"}>
                                        <div style={{ display: 'inline-block' }}>
                                          <Index.IconButton
                                            color="primary"
                                            className="request-btn"
                                            aria-label="approve"
                                            disabled={
                                              row?.requestStatus === "Rejected" ||
                                              row?.requestStatus === "Approved"
                                            }
                                            component="label"
                                            onClick={() => handleOpen(row)}
                                          >
                                            <Index.CheckIcon className="edit-pen-Icon" />
                                          </Index.IconButton>
                                        </div>
                                      </PageIndex.LightTooltip>
                                    ) : null
                                  }

                                  {

                                    (row?.requestStatus === "Rejected" || row?.requestStatus === "Pending") && (
                                      (rolePermission &&
                                        (rolePermission?.roleType?.rolePermission?.includes("WithdrawalList_edit") ||
                                          rolePermission?.isAdmin === true))
                                    ) ? (
                                      <PageIndex.LightTooltip title={row?.requestStatus == "Rejected" ? "Rejected" : "Reject"}>
                                        <div style={{ display: 'inline-block' }}>
                                          <Index.IconButton
                                            color="primary"
                                            disabled={
                                              row?.requestStatus === "Rejected" ||
                                              row?.requestStatus === "Approved"
                                            }
                                            aria-label="reject request"
                                            component="label"
                                            onClick={() => handleOpenDeclineModel(row)}
                                          >
                                            <Index.CloseIcon className="delete-bin-Icon request-btn" />
                                          </Index.IconButton>
                                        </div>
                                      </PageIndex.LightTooltip>
                                    ) : null
                                  }

                                </Index.Box>
                              </Index.TableCell>
                            </>
                          ) : (
                            ""
                          )}
                        </Index.TableRow>
                      ))
                    ) : (
                      <PageIndex.RecordNotFound colSpan={7} />
                    )
                  ) : (
                    <PageIndex.TableLoader colSpan={7} />
                  )}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
        </Index.Box>
        {withdrawalList?.length ? (
          <Index.Box className="pagination-main">
            <Index.Pagination
              count={pageCount}
              page={currentPage}
              onChange={(event, value) => {
                setCurrentPage(value);
              }}
              variant="outlined"
              shape="rounded"
              className="pagination"
            />
          </Index.Box>
        ) : (
          ""
        )}
      </Index.Box>
      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete modal"
      >
        <Index.Box
          sx={Index.style}
          className="withdrawal-modal-inner-main modal-inner"
        >
          <Index.Box className="modal-circle-main cus-approve-modal">
            <Index.IconButton onClick={handleClose}>
              <img src={Index.Png.close} className="user-circle-img" />
            </Index.IconButton>
          </Index.Box>
          <Index.Typography
            className="withdrawal-modal-heading"
            component="h2"
            variant="h2"
          >
            {isViewData ? "View Details" : "Approve"}
          </Index.Typography>
          <Index.Divider />
          <Index.Typography
            className="withdrawal-modal-title"
            component="h2"
            variant="h2"
          >
            {isViewData ? "" : " Are you sure you want to approve ?"}
          </Index.Typography>
          <Index.Box className="conversion-model-main">
            <Index.Box className="withdrawal-model">
              <Index.Typography className="conversion-title">
                {singleWithdrawalData?.cryptoRequest == true
                  ? "Fiat to Crypto Conversion"
                  : "Crypto to Fiat Conversation"}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="withdrawal-model">
              <Index.Typography className="conversion-content">
                {singleWithdrawalData?.cryptoRequest == true
                  ? `From ${singleWithdrawalData?.currency?.coins ? singleWithdrawalData?.currency?.coins : "_"} ${singleWithdrawalData?.amount} to ${singleWithdrawalData?.noOfCoins} ${singleWithdrawalData?.cryptoCoinType?.tokenName ? singleWithdrawalData?.cryptoCoinType?.tokenName : "_"} coins`
                  : `From ${singleWithdrawalData?.noOfCoins ? singleWithdrawalData?.noOfCoins : "_"} ${singleWithdrawalData?.cryptoCoinType?.tokenName ? singleWithdrawalData?.cryptoCoinType?.tokenName : "_"} coins to ${singleWithdrawalData?.currency?.coins ? singleWithdrawalData?.currency?.coins : "_"} ${singleWithdrawalData?.amount ? singleWithdrawalData?.amount : "_"} `}
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="withdrawal-model-main">
            <Index.Box className="withdrawal-model">
              <Index.Typography className="withdrawal-title">
                Username
              </Index.Typography>
              <Index.Typography className="withdrawal-content">
                {singleWithdrawalData?.userId?.userName
                  ? singleWithdrawalData?.userId?.userName
                  : ""}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="withdrawal-model">
              <Index.Typography className="withdrawal-title">
                Email
              </Index.Typography>
              <Index.Typography className="withdrawal-content">
                {singleWithdrawalData?.userId?.email
                  ? singleWithdrawalData?.userId?.email
                  : "-"}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="withdrawal-model">
              <Index.Typography className="withdrawal-title">
                Amount
              </Index.Typography>
              <Index.Typography className="withdrawal-content">
                {singleWithdrawalData?.amount
                  ? singleWithdrawalData?.amount
                  : "-"}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="withdrawal-model">
              <Index.Typography className="withdrawal-title">
                Platform Fee
              </Index.Typography>
              <Index.Typography className="withdrawal-content">
                {singleWithdrawalData?.platformFee
                  ? singleWithdrawalData?.platformFee
                  : "-"}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="withdrawal-model">
              <Index.Typography className="withdrawal-title">
                Platform Fee (%)
              </Index.Typography>
              <Index.Typography className="withdrawal-content">
                {singleWithdrawalData?.platformFeePercent
                  ? singleWithdrawalData?.platformFeePercent
                  : "-"}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="withdrawal-model">
              <Index.Typography className="withdrawal-title">
                Crypto Name
              </Index.Typography>
              <Index.Typography className="withdrawal-content">
                {singleWithdrawalData?.cryptoCoinType?.tokenName
                  ? singleWithdrawalData?.cryptoCoinType?.tokenName
                  : "-"}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="withdrawal-model">
              <Index.Typography className="withdrawal-title">
                Currency
              </Index.Typography>
              <Index.Typography className="withdrawal-content">
                {singleWithdrawalData?.currency?.coins
                  ? singleWithdrawalData?.currency?.coins
                  : "-"}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="withdrawal-model">
              <Index.Typography className="withdrawal-title">
                Currency Type
              </Index.Typography>
              <Index.Typography className="withdrawal-content">
                {singleWithdrawalData?.currencyType
                  ? singleWithdrawalData?.currencyType
                  : "-"}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="withdrawal-model">
              <Index.Typography className="withdrawal-title">
                Request Date & Time
              </Index.Typography>
              <Index.Typography className="withdrawal-content">
                {singleWithdrawalData?.createdAt
                  ? Index.moment(singleWithdrawalData?.createdAt).format(
                    "DD/MM/YYYY hh:mm A"
                  )
                  : "-"}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="withdrawal-model">
              <Index.Typography className="withdrawal-title">
                Status
              </Index.Typography>
              <Index.Typography className="withdrawal-content">
                {singleWithdrawalData?.requestStatus
                  ? singleWithdrawalData?.requestStatus
                  : "-"}
              </Index.Typography>
            </Index.Box>
            {singleWithdrawalData?.requestRejectReason ? (<Index.Box className="withdrawal-model">
              <Index.Typography className="withdrawal-title">
                Rejection Reason
              </Index.Typography>
              <Index.Typography className="withdrawal-content break-text">
                {singleWithdrawalData?.requestRejectReason
                  ? singleWithdrawalData?.requestRejectReason
                  : "-"}
              </Index.Typography>
            </Index.Box>) : ""}
            <Index.Box className="withdrawal-model">
              <Index.Typography className="withdrawal-title">
                {!isViewData ? '' : 'Actual Transaction Id'}
              </Index.Typography>
              <Index.Typography className="withdrawal-content">
                {!isViewData ? '' :
                  singleWithdrawalData?.actualTransactionId || "-"
                }

              </Index.Typography>
            </Index.Box>

          </Index.Box>
          {!isViewData ? (
            <Index.Box className="delete-modal-btn-flex">
              <Index.Button
                className="modal-cancel-btn modal-btn"
                onClick={handleClose}
              >
                Cancel
              </Index.Button>

              <Index.Button
                className="modal-delete-btn modal-btn"
                onClick={() => handleReason(singleWithdrawalData, "Approved", true)}
                disabled={isDeleteButtonDisabled}
              >
                {isDeleteButtonDisabled ? (
                  <PageIndex.ButtonLoader
                    color="white"
                    size={14}
                    loading={isDeleteButtonDisabled}
                  />
                ) : (
                  "Approve"
                )}
              </Index.Button>
            </Index.Box>
          ) : (
            ""
          )}
        </Index.Box>
      </Index.Modal>
      <PageIndex.DeclineModel
        openDeclineModel={openDeclineModel}
        handleCloseDeclineModel={handleCloseDeclineModel}
        reason={reason}
        handleSubmit={handleReason}
        isDeleteButtonDisabled={isDeleteButtonDisabled}
      />
    </Index.Box>
  );
};
export default ConversionRequestList;
