import React, { useEffect, useState } from 'react'
import Index from '../../../Index';
import PageIndex from '../../../PageIndex';
import { imageUrl } from '../../../../config/dataService';
import moment from 'moment';
import { autoLogout, suffleContestParticipantAction } from '../../../../redux/slices/adminService';
import PropTypes from "prop-types";
import ParticipantList from './ParticipantList';
import WinnerList from './WinnerList';
import { logout, updateRolePermission } from '../../../../redux/slices/AdminSlice';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Index.Box sx={{ p: 3 }}>
                    <Index.Typography>{children}</Index.Typography>
                </Index.Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
const Contestview = () => {

    const ordinalNumbers = ["First", "Second", "Third", "Fourth", "Fifth", "Sixth", "Seventh", "Eighth", "Ninth", "Tenth"];
    const { state } = PageIndex.useLocation()
    const navigate = PageIndex.useNavigate()
    const dispatch=PageIndex.useDispatch()
    const { token } = PageIndex.useSelector((state) => state.admin);
    const [contestParticipantList, setContestParticipantList] = useState(state?.row?.spots)
    const [loader, setLoader] = useState(false)
    const [pageData, setPageData] = useState([]);
    const [search, setSearch] = useState("");
    const [filterData, setFilterData] = useState([]);
    const [value, setValue] = useState(0);


    const handleChange = (event, newValue) => {
        setValue(newValue);
        autoLogout(token).then((res) => {
          if (res?.data?.status == 200) {
            dispatch(updateRolePermission(res?.data?.data));
          }
          else if (res?.response?.data?.status == 401) {
            dispatch(logout());
            navigate("/");
          }
        })
      };
    useEffect(() => {
        const filterData = contestParticipantList?.filter((item) => {
            return search
                ? item.caption.toLowerCase().includes(search.toLowerCase())
                : item;
        });
        setFilterData(filterData);
    }, [search, contestParticipantList]);


    const timeFormate = (timeString) => {
        const [hours, minutes, seconds] = timeString.split(":");
        return { hours, minutes };
      };

    return (
        <>
            <Index.Box className='user-details-back-btn'>
                <PageIndex.BackButton onClick={() => navigate("/dashboard/contest-Participants-list")} />
            </Index.Box>

            <Index.Box className="user-detail-section">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                        display="grid"
                        className="display-row-add-user"
                        gridTemplateColumns="repeat(12, 1fr)"
                    >
                        <Index.Box
                            gridColumn={{
                                xs: "span 12",
                                sm: "span 12",
                                md: "span 12",
                                lg: "span 3",
                            }}
                            className="grid-column"
                        >
                            <Index.Box className="left-wrape">
                                <Index.Box className="detail">
                                    <label>Contest ID</label>
                                    <p>{state?.row?.contestID}</p>
                                    <label>Contest name</label>
                                    <p>{state?.row?.contestName}</p>
                                    <label>Category</label>
                                    <p>{state?.row?.category}</p>
                                    <label>Total number of participants</label>
                                    <p>{state?.row?.spots?.length}</p>
                                    <label>Spots which were available</label>
                                    <p>{state?.row?.spotNumber - state?.row?.spots?.length}</p>
                                    <label>Start date</label>
                                    <p>{state?.row?.startDate ? moment(state?.row?.startDate).format("DD/MM/YYYY") : "-"}</p>
                                    <label>Start Time</label>
                                    <p> {state?.row?.startTime
                                        ? timeFormate(state?.row?.startTime)?.hours +
                                        ":" +
                                        timeFormate(state?.row?.startTime)?.minutes
                                        : "-"}</p>
                                    <label>End Date</label>
                                    <p>{state?.row?.endDate ? moment(state?.row?.endDate).format("DD/MM/YYYY") : "-"}</p>
                                    <label>End Time</label>
                                    <p> {state?.row?.endTime
                                        ? timeFormate(state?.row?.endTime)?.hours +
                                        ":" +
                                        timeFormate(state?.row?.endTime)?.minutes
                                        : "-"}</p>
                                    {
                                        state?.row?.winningPrice?.map((row, index) => {

                                            return (
                                                <>
                                                    <label>{row?.Rank} rank price</label>
                                                    <p>{row?.prize} %</p>
                                                </>
                                            )
                                        }
                                        )
                                    }
                                    {/* <label>Time duration</label>
                                <p>{state?.data?.commentId?.createdBy?.links}</p> */}
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                        <Index.Box
                            gridColumn={{
                                xs: "span 12",
                                sm: "span 12",
                                md: "span 12",
                                lg: "span 9",
                            }}
                            className="grid-column"
                        >
                            <Index.Box className="right-wrape">

                                <Index.Box sx={{ marginLeft: 3 }}>
                                    <Index.Tabs
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="basic tabs example"
                                        className="admin-tabs-main"
                                    >
                                        <Index.Tab
                                            label="Participant List"
                                            {...a11yProps(0)}
                                            className="admin-tab"
                                        />
                                        <Index.Tab
                                            label="Winner List"
                                            {...a11yProps(1)}
                                            className="admin-tab"
                                        />
                                    </Index.Tabs>
                                </Index.Box>

                                {
                                    value == 0 ?
                                        <ParticipantList row={state?.row} winnerPage={state?.winner} />

                                        :
                                        <WinnerList editData={state?.row} winnerPage={state?.winner} />
                                }
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Box>
        </>
    )
}

export default Contestview