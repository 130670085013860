
import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { getAllStickersList, deleteSticker, addEditStickers } from "../../../../redux/slices/adminService";
import PageIndex from "../../../PageIndex";
import { imageUrl } from "../../../../config/dataService";
import { debounce } from "lodash";

export default function StickersList() {
    const { rolePermission } = PageIndex.useSelector((state) => state.admin);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [openDelete, setOpenDelete] = useState(false);
    const [openImage, setOpenImage] = useState(false);
    const [loader, setLoader] = useState(true);
    const [deleteId, setDeleteId] = useState();
    const [stickersList, setStickersList] = useState([]);
    const [singleStickerData, setSingleStickerData] = useState("");
    const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false);
    const [isView, setIsView] = useState(false);
    const [image, setImage] = useState(false);
    const [open, setOpen] = useState(false);
    const initialValues = {
        title: singleStickerData?.title ? singleStickerData?.title : "",
        description: singleStickerData?.description ? singleStickerData?.description : "",
        image: singleStickerData?.image ? singleStickerData?.image : "",
    };
    const handleOpen = (data, isViewCome = false) => {
        setSingleStickerData(data);
        setIsView(isViewCome)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false)
        setIsView(false)

    }
    // add user modal

    //Delete Modal
    const handleOpenDelete = (id) => {
        setOpenDelete(true);
        setDeleteId(id);
    };
    const handleCloseDelete = () => setOpenDelete(false);

    const handleOpenImage = (image) => {
        setOpenImage(true);
        setImage(image);
    };
    const handleCloseImage = () => {
        setImage('')
        setOpenImage(false);
    }

    const deleteCryptoNam = (id) => {
        setIsDeleteButtonDisabled(true);
        deleteSticker({ id: deleteId }).then((data) => {
            handleCloseDelete();
            setIsDeleteButtonDisabled(false);
            setLoader(true);
            fetchStickersList();
        });
    };

    const handleStickerDetails = async (values) => {
        console.log(values, 61);
        setIsDeleteButtonDisabled(true);

        setLoader(true);
        const urlencoded = new FormData();

        if (singleStickerData?._id) {
            urlencoded.append("id", singleStickerData?._id);
        }

        urlencoded.append("title", values?.title);
        urlencoded.append("description", values?.description);
        urlencoded.append("image", values?.image);

        addEditStickers(urlencoded).then((data) => {
            if (data?.status == 200) {
                handleClose();
                setIsDeleteButtonDisabled(false);
                fetchStickersList();
                setLoader(false);
            } else {
                handleClose();
                // setCryptoList([]);
                setIsDeleteButtonDisabled(false);

                fetchStickersList();
            }
        });
    };

    const fetchStickersList = () => {
        setLoader(true);
        getAllStickersList({ currentPage, search }).then((data) => {
            if (data?.status == 200) {
                setStickersList(data?.data);
                if (data?.currentPage) {
                    setCurrentPage(data?.currentPage);
                }
                setPageCount(data?.totalPages);
                setLoader(false);
                setIsDeleteButtonDisabled(false);
            } else {
                setStickersList([]);
                setTimeout(() => {
                    setLoader(false);
                }, 1000);
            }
        });
    };


    useEffect(() => {
        const debouncedFetch = debounce(fetchStickersList, 300);

        if (search || currentPage) {
            debouncedFetch();
        } else {
            fetchStickersList();
        }
        return () => {
            debouncedFetch.cancel();
        };
    }, [search, currentPage]);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };


    return (
        <>
            <Index.Box className="dashboard-content">
                <Index.Box className="barge-common-box">
                    <Index.Box className="user-list-flex">
                        <Index.Box className="admin-page-title-main">
                            <Index.Typography
                                className="admin-page-title"
                                component="h2"
                                variant="h2"
                            >
                                Stickers List
                            </Index.Typography>
                        </Index.Box>
                        <Index.Box className="userlist-btn-flex">
                            <Index.Box className="user-search-main">
                                <Index.Box className="user-search-box">
                                    <Index.Box className="form-group">
                                        <Index.TextField
                                            fullWidth
                                            id="fullWidth_123"
                                            type="text"
                                            value={search}
                                            autoComplete="search"
                                            className="form-control"
                                            placeholder="Search"
                                            onChange={(e) => {
                                                const newValue = e.target.value
                                                    .replace(/^\s+/, "")
                                                    .replace(/\s\s+/g, " ");
                                                setSearch(newValue);
                                                setCurrentPage(1);
                                                // getCryptoList(newValue);
                                            }}
                                        />
                                        <span className="search-icon-box">
                                            <img src={Index.Svg.search} className="search-icon" />
                                        </span>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>

                            <Index.Box className="flex-all user-list-inner-flex">
                                <Index.Box className="adduser-btn-main btn-main-primary">
                                    {(rolePermission &&
                                        rolePermission?.roleType?.rolePermission?.includes(
                                            "CryptoList_add"
                                        )) ||
                                        (rolePermission && rolePermission?.isAdmin === true) ? (
                                        <>
                                            <Index.Button
                                                className="adduser-btn btn-primary"
                                                onClick={handleOpen}
                                            >
                                                <img
                                                    src={Index.Svg.plus}
                                                    className="plus-icon"
                                                    alt="plus icon"
                                                />
                                                Add Sticker
                                            </Index.Button>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="admin-dashboard-list-row">
                        <Index.Box sx={{ width: 1 }} className="grid-main">
                            <Index.Box
                                display="grid"
                                className="display-row-userlist"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                            >
                                <Index.Box
                                    gridColumn={{
                                        xs: "span 12",
                                        sm: "span 12",
                                        md: "span 12",
                                        lg: "span 12",
                                    }}
                                    className="grid-column"
                                >
                                    <Index.Box className="admin-dash-box">
                                        <Index.Box className="stickersList-table-main action-column page-table-main">
                                            {/* <Paper sx={{ width: '100%', overflow: 'hidden' }}> */}

                                            <Index.TableContainer
                                                component={Index.Paper}
                                                className="table-container"
                                            // sx={{ maxHeight:400 }}
                                            >
                                                <Index.Table
                                                    stickyHeader
                                                    aria-label="sticky table"
                                                    sx={{ minWidth: 650 }}
                                                    className="table"
                                                >
                                                    <Index.TableHead className="table-head cus-table-head">
                                                        <Index.TableRow className="table-row">
                                                            <Index.TableCell
                                                                component="th"
                                                                variant="th"
                                                                className="table-th"
                                                                align="center"
                                                            >
                                                                S.No
                                                            </Index.TableCell>
                                                            <Index.TableCell
                                                                component="th"
                                                                variant="th"
                                                                className="table-th"
                                                                align="center"
                                                            >
                                                                Media
                                                            </Index.TableCell>
                                                            <Index.TableCell
                                                                component="th"
                                                                variant="th"
                                                                className="table-th"
                                                                align="center"
                                                            >
                                                                Title
                                                            </Index.TableCell>
                                                            <Index.TableCell
                                                                component="th"
                                                                variant="th"
                                                                className="table-th"
                                                                align="center"
                                                            >
                                                                Description
                                                            </Index.TableCell>

                                                            {rolePermission?.roleType?.rolePermission?.includes(
                                                                "StickersList_view"
                                                            ) ||
                                                                rolePermission?.roleType?.rolePermission?.includes(
                                                                    "StickersList_edit"
                                                                ) ||
                                                                rolePermission?.roleType?.rolePermission?.includes(
                                                                    "StickersList_delete"
                                                                ) ||
                                                                (rolePermission &&
                                                                    rolePermission?.isAdmin === true) ? (
                                                                <Index.TableCell
                                                                    component="th"
                                                                    variant="th"
                                                                    className="table-th"
                                                                    align="center"
                                                                >
                                                                    Actions
                                                                </Index.TableCell>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Index.TableRow>
                                                    </Index.TableHead>
                                                    <Index.TableBody className="table-body">
                                                        {!loader ? (
                                                            stickersList?.length > 0 ? (
                                                                stickersList?.map((row, index) => (
                                                                    <Index.TableRow
                                                                        key={row._id}
                                                                        sx={{
                                                                            "&:last-child td, &:last-child th": {
                                                                                border: 0,
                                                                            },
                                                                        }}
                                                                    >
                                                                        <Index.TableCell
                                                                            component="td"
                                                                            variant="td"
                                                                            scope="row"
                                                                            className="table-td"
                                                                            align="center"
                                                                        >
                                                                            {(currentPage - 1) * 10 + index + 1}
                                                                        </Index.TableCell>
                                                                        <Index.TableCell
                                                                            component="td"
                                                                            variant="td"
                                                                            scope="row"
                                                                            className="table-td"
                                                                            align="center"
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() => handleOpenImage(row?.image)}
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    row?.image
                                                                                        ? row?.image instanceof Blob
                                                                                            ? URL.createObjectURL(
                                                                                                row?.image
                                                                                            )
                                                                                            : `${imageUrl}${row?.image}`
                                                                                        : PageIndex?.Jpg?.dummyImage
                                                                                }
                                                                                className="user-stickers-user-img"
                                                                            />
                                                                        </Index.TableCell>
                                                                        <Index.TableCell
                                                                            component="td"
                                                                            variant="td"
                                                                            scope="row"
                                                                            className="table-td"
                                                                            align="center"
                                                                        >
                                                                            {row?.title ? row?.title : "-"}
                                                                        </Index.TableCell>
                                                                        <Index.TableCell
                                                                            component="td"
                                                                            variant="td"
                                                                            scope="row"
                                                                            className="table-td"
                                                                            align="center"
                                                                        >
                                                                            {row?.description && row?.description?.length > 30 ?
                                                                                <PageIndex.LightTooltip
                                                                                    title={row?.description}
                                                                                    arrow
                                                                                >
                                                                                    <span>
                                                                                        {row?.description.substring(0, 25)}...
                                                                                    </span>
                                                                                </PageIndex.LightTooltip> : row?.description || "-"}

                                                                        </Index.TableCell>

                                                                        {rolePermission?.roleType?.rolePermission?.includes(
                                                                            "StickersList_view"
                                                                        ) ||
                                                                            rolePermission?.roleType?.rolePermission?.includes(
                                                                                "StickersList_edit"
                                                                            ) ||
                                                                            rolePermission?.roleType?.rolePermission?.includes(
                                                                                "StickersList_delete"
                                                                            ) ||
                                                                            (rolePermission &&
                                                                                rolePermission?.isAdmin === true) ? (
                                                                            <Index.TableCell
                                                                                component="td"
                                                                                variant="td"
                                                                                className="table-td"
                                                                                align="center"

                                                                            >
                                                                                <Index.Box className="userdata-btn-flex">

                                                                                    {(rolePermission &&
                                                                                        rolePermission?.roleType?.rolePermission?.includes(
                                                                                            "StickersList_view"
                                                                                        )) ||
                                                                                        (rolePermission &&
                                                                                            rolePermission?.isAdmin ===
                                                                                            true) ? (
                                                                                        <>
                                                                                            <PageIndex.LightTooltip title="View">
                                                                                                <Index.IconButton
                                                                                                    color="primary"
                                                                                                    aria-label="upload picture"
                                                                                                    component="label"
                                                                                                    onClick={() => {
                                                                                                        handleOpen(row, true);
                                                                                                    }}
                                                                                                >
                                                                                                    <Index.RemoveRedEyeIcon className="view-eye-Icon" />
                                                                                                </Index.IconButton>
                                                                                            </PageIndex.LightTooltip>
                                                                                        </>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}

                                                                                    {(rolePermission &&
                                                                                        rolePermission?.roleType?.rolePermission?.includes(
                                                                                            "StickersList_edit"
                                                                                        )) ||
                                                                                        (rolePermission &&
                                                                                            rolePermission?.isAdmin ===
                                                                                            true) ? (
                                                                                        <>
                                                                                            <PageIndex.LightTooltip title="Edit">
                                                                                                <Index.IconButton
                                                                                                    color="primary"
                                                                                                    aria-label="upload picture"
                                                                                                    component="label"
                                                                                                    onClick={() => {
                                                                                                        handleOpen(row);
                                                                                                    }}
                                                                                                >
                                                                                                    <Index.EditIcon />
                                                                                                </Index.IconButton>
                                                                                            </PageIndex.LightTooltip>
                                                                                        </>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                    {(rolePermission &&
                                                                                        rolePermission?.roleType?.rolePermission?.includes(
                                                                                            "StickersList_delete"
                                                                                        )) ||
                                                                                        (rolePermission &&
                                                                                            rolePermission?.isAdmin ===
                                                                                            true) ? (
                                                                                        <>
                                                                                            <PageIndex.LightTooltip title="Delete">
                                                                                                <Index.IconButton
                                                                                                    color="primary"
                                                                                                    aria-label="upload picture"
                                                                                                    component="label"
                                                                                                    onClick={() => {
                                                                                                        handleOpenDelete(row._id);
                                                                                                    }}
                                                                                                >
                                                                                                    <Index.DeleteIcon />
                                                                                                </Index.IconButton>
                                                                                            </PageIndex.LightTooltip>
                                                                                        </>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </Index.Box>
                                                                            </Index.TableCell>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </Index.TableRow>
                                                                ))
                                                            ) : (
                                                                <PageIndex.RecordNotFound colSpan={7} />
                                                            )
                                                        ) : (
                                                            <PageIndex.TableLoader colSpan={7} />
                                                        )}
                                                    </Index.TableBody>
                                                </Index.Table>
                                            </Index.TableContainer>
                                            {/* </Paper> */}
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>

                    {stickersList.length ? (
                        <Index.Box className="pagination-main">
                            <Index.Pagination
                                count={pageCount}
                                page={currentPage}
                                onChange={handlePageChange}
                                variant="outlined"
                                shape="rounded"
                                className="pagination"
                            />
                        </Index.Box>
                    ) : (
                        ""
                    )}
                </Index.Box>
            </Index.Box>

            {/* Delete Modal */}
            <PageIndex.DeleteModal
                openDelete={openDelete}
                handleCloseDelete={handleCloseDelete}
                handleDeleteRecord={deleteCryptoNam}
                isDeleteButtonDisabled={isDeleteButtonDisabled}
            />
            <PageIndex.AddStickerModel
                title={singleStickerData?.title ? "Edit" : "Add"}

                open={open}
                initialValues={initialValues}
                schema={PageIndex.addEditStickersSchema}
                singleStickerData={singleStickerData}
                handleSubmit={handleStickerDetails}
                handleClose={handleClose}
                isView={isView}
                isDeleteButtonDisabled={isDeleteButtonDisabled}
            />


            <PageIndex.ImageViewModel
                open={openImage}
                handleClose={handleCloseImage}
                image={image}
            />
        </>
    );
}
