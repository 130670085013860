import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { imageUrl } from "../../../../config/dataService";
import {
  getAllProfileReportsList,
  getFiatWallet,
  updateFiatWalletStatus,
} from "../../../../redux/slices/adminService";
import { debounce } from "lodash";
import numeral from "numeral";

const UserFiatWalletList = () => {
  let navigate = PageIndex.useNavigate();
  const params = PageIndex.useLocation();
  const { rolePermission } = PageIndex.useSelector((state) => state.admin);

  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fiatWalletList, setFiatWalletist] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const abortController = new AbortController();
  //Fetch user fiat List

  const fetchFiatList = (page) => {
    setLoader(true);
    getFiatWallet({ currentPage, search }, { signal: abortController.signal }).then((data) => {
      if (data?.status == 200) {
        setFiatWalletist(data?.data);
        if (data?.currentPage) {
          setCurrentPage(data?.currentPage);
          setPageCount(data?.totalPages);
        }
        setLoader(false);
      }
    });
  };

  const handleFiatWalletStatus = (id) => {
    setLoader(true);
    const urlencoded = new URLSearchParams();

    urlencoded.append("userId", id);

    updateFiatWalletStatus(urlencoded).then((data) => {
      if (data?.status == 200) {
        fetchFiatList();
      } else {
        setLoader(false);
        fetchFiatList();
      }
    });
  };
  useEffect(() => {
    setLoader(true)
    const debouncedFetch = debounce(fetchFiatList, 300);

    if (search || currentPage) {
      debouncedFetch();
    } else {

      fetchFiatList();
    }

    return () => {
      debouncedFetch.cancel();
      abortController.abort();
    };
  }, [search, currentPage]);


  return (
    <div style={{ position: "relative" }}>
      {loading && (
        <div>
          <Index.Box className="loader-main">
            <img src={PageIndex.Png.spinnerLoader} className="loader-img" />
          </Index.Box>
        </div>
      )}
      <div className={loading ? "loader-overlay" : ""}>
        <Index.Box className="barge-common-box">
          <Index.Box className="common-box">
            <Index.Box className="user-list-flex">
              <Index.Box className="admin-page-title-main">
                <Index.Typography
                  className="admin-page-title tittle-like-costam"
                  component="h2"
                  variant="h2"
                >
                  List of Users Fiat Wallet
                </Index.Typography>
              </Index.Box>
              <Index.Box className="userlist-btn-flex">
                <Index.Box className="user-search-main">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        name="search"
                        autoComplete="search"
                        value={search}
                        className="form-control"
                        placeholder="Search"
                        onChange={(e) => {
                          const newValue = e.target.value
                            .replace(/^\s+/, "")
                            .replace(/\s\s+/g, " ");
                          setFiatWalletist()
                          setSearch(newValue);
                          setCurrentPage(1);
                          setLoader(true)
                        }}
                      />
                      <span className="search-icon-box">
                        <img src={Index.Svg.search} className="search-icon" />
                      </span>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="admin-dash-box">
              <Index.Box className="page-table-main action-column fiat-wallet-table-main">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  <Index.Table
                    stickyHeader
                    aria-label="simple table"
                    className="table"
                  >
                    <Index.TableHead className="table-head cus-table-head">
                      <Index.TableRow className="table-row">
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        >
                          S.No.
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        >
                          Username
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        >
                          Email
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        >
                          Currency
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        >
                          Balance
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        >
                          Status
                        </Index.TableCell>
                        {(rolePermission &&
                          rolePermission?.roleType?.rolePermission?.includes(
                            "FiatWalletManagement_view"
                          )) ||
                          rolePermission?.roleType?.rolePermission?.includes(
                            "FiatWalletManagement_edit"
                          ) ||
                          (rolePermission && rolePermission?.isAdmin === true) ? (
                          <>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              Action
                            </Index.TableCell>
                          </>
                        ) : (
                          ""
                        )}
                      </Index.TableRow>
                    </Index.TableHead>

                    <Index.TableBody className="table-body">
                      {!loader ? (
                        fiatWalletList?.length > 0 ? (
                          fiatWalletList?.map((row, index) => (
                            <Index.TableRow key={row?._id}>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                align="center"
                              >
                                {console.log("profileReportistRow", row)}
                                {fiatWalletList?.length ? (currentPage - 1) * 10 + index + 1 : ""}
                                {/* {(1 - 1) * 10 + index + 1} */}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td "
                                align="center"

                              >
                                <span className="cus-user-name-navigate"
                                  onClick={() => {
                                    navigate('/dashboard/user-view',
                                      {
                                        state: {
                                          data: row?.userId?._id,
                                          pathName: params.pathname
                                        },
                                      }
                                    )
                                  }}>{row?.userId?.userName
                                    ? row?.userId?.userName
                                    : "-"}</span>
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                align="center"
                              >
                                {row?.userId?.email ? row?.userId?.email : "-"}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                align="center"
                              >
                                {row?.currency?.coins
                                  ? row?.currency?.coins
                                  : "-"}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                align="center"
                              >


                                {row?.fiatBalance
                                  ? Number.isInteger(row.fiatBalance)
                                    ? numeral(row.fiatBalance).format('0')
                                    : numeral(row.fiatBalance).format('0.000a')
                                  : 0}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                align="center"
                              >
                                {row?.isActive ? "Active" : "Deactive"}
                              </Index.TableCell>
                              {(rolePermission &&
                                rolePermission?.roleType?.rolePermission?.includes(
                                  "FiatWalletManagement_view"
                                )) ||
                                rolePermission?.roleType?.rolePermission?.includes(
                                  "FiatWalletManagement_edit"
                                ) ||
                                (rolePermission &&
                                  rolePermission?.isAdmin === true) ? (
                                <>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                    align="center"
                                  >
                                    <Index.Box className="userdata-btn-flex wallet-btn">
                                      {(rolePermission &&
                                        rolePermission?.roleType?.rolePermission?.includes(
                                          "FiatWalletManagement_view"
                                        )) ||
                                        (rolePermission &&
                                          rolePermission?.isAdmin === true) ? (
                                        <>
                                          <PageIndex.LightTooltip title="View">
                                            <Index.IconButton
                                              color="primary"
                                              aria-label="upload picture"
                                              component="label"
                                              onClick={() =>
                                                navigate(
                                                  "/dashboard/view-user-fiat-wallet",
                                                  {
                                                    state: {
                                                      data: row?.userId?._id,
                                                    }
                                                  },
                                                )
                                              }
                                            >
                                              <Index.RemoveRedEyeIcon className="view-eye-Icon" />
                                            </Index.IconButton>
                                          </PageIndex.LightTooltip>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {/* {(rolePermission &&
                                        rolePermission?.roleType?.rolePermission?.includes(
                                          "FiatWalletManagement_edit"
                                        )) ||
                                        (rolePermission &&
                                          rolePermission?.isAdmin === true) ? (
                                        <>
                                          <Index.Box className="table-view-toggle-btn">
                                           
                                            <Index.FormControlLabel
                                              control={
                                                <Index.IOSSwitch
                                                  checked={
                                                    row?.isActive === true
                                                  }
                                                  sx={{ m: 1 }}
                                                  className="MuiSwitch-track-active"
                                                  onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleFiatWalletStatus(
                                                      row?.userId?._id
                                                    );
                                                  }}
                                                />
                                              }
                                              className="switch-lable"
                                            />
                                     
                                          </Index.Box>
                                        </>
                                      ) : (
                                        ""
                                      )} */}
                                    </Index.Box>
                                  </Index.TableCell>
                                </>
                              ) : (
                                ""
                              )}
                            </Index.TableRow>
                          ))
                        ) : (
                          <PageIndex.RecordNotFound colSpan={7} />
                        )
                      ) : (
                        <PageIndex.TableLoader colSpan={7} />
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
            </Index.Box>
            {fiatWalletList?.length ? (
              <Index.Box className="pagination-main">
                <Index.Pagination
                  count={pageCount}
                  page={currentPage}
                  onChange={(event, value) => {
                    setCurrentPage(value);
                  }}
                  variant="outlined"
                  shape="rounded"
                  className="pagination"
                />
              </Index.Box>
            ) : (
              ""
            )}
          </Index.Box>
        </Index.Box>
      </div>
    </div>
  );
};
export default UserFiatWalletList;
