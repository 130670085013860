import React, { useEffect, useState } from "react";
import PageIndex from "../../../PageIndex";
import Index from "../../../Index";
import UserFiatWalletList from "./UserFiatList";
import UserCryptoWalletList from "./UserCryptoList";
import { logout, updateRolePermission } from "../../../../redux/slices/AdminSlice";
import { autoLogout } from "../../../../redux/slices/adminService";

const WalletManagement = () => {
  const { state } = PageIndex.useLocation();
  const navigate=PageIndex.useNavigate()
  const dispatch=PageIndex.useDispatch()
  const { rolePermission,token } = PageIndex.useSelector((state) => state.admin);
  const [value, setValue] = useState(state?.postKey ? state?.postKey : 0);

  //three dot functions
  const handleChange = (event, newValue) => {
    setValue(newValue);
    autoLogout(token).then((res) => {
      if (res?.data?.status == 200) {
        dispatch(updateRolePermission(res?.data?.data));
      }
      else if (res?.response?.data?.status == 401) {
        dispatch(logout());
        navigate("/");
      }
    })
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }


  useEffect(() => {
    if (
      rolePermission &&
      rolePermission?.roleType?.rolePermission?.includes(
        "FiatWalletManagement_view"
      ) &&
      rolePermission?.roleType?.rolePermission?.includes("CryptoWalletManagement_view")
    ) {
      setValue(0);
    } else if (
      rolePermission &&
      rolePermission?.roleType?.rolePermission?.includes("FiatWalletManagement_view")
    ) {
      setValue(0);
    } else if (
      rolePermission &&
      rolePermission?.roleType?.rolePermission?.includes("CryptoWalletManagement_view")
    ) {
      setValue(1);
    }
  }, []);
  console.log(value,'value');
  // rolePermission, value
  return (
    <>
      <Index.Box className="table-wrape">
        <Index.Box className="external-tab-box">
          <Index.Box className="admin-page-title-main">
            <Index.Typography
              className="admin-page-title tittle-like-costam"
              component="h2"
              variant="h2"
            >
              Wallet Management
            </Index.Typography>
          </Index.Box>
          <Index.Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="admin-tabs-main"
          >
            {(rolePermission &&
              rolePermission?.roleType?.rolePermission?.includes(
                "FiatWalletManagement_view"
              )) ||
              (rolePermission && rolePermission?.isAdmin === true) ? (
              <Index.Tab
                label="Fiat Wallet"
                {...a11yProps(0)}
                className="admin-tab"
              />
            ) : (
              ""
            )}
            {(rolePermission &&
              rolePermission?.roleType?.rolePermission?.includes(
                "CryptoWalletManagement_view"
              )) ||
              (rolePermission && rolePermission?.isAdmin === true) ? (
              <Index.Tab
                label="Crypto Wallet"
                {...a11yProps(1)}
                className="admin-tab"
              />
            ) : (
              ""
            )}
          </Index.Tabs>
        </Index.Box>

        {value === 0 ? <UserFiatWalletList btnValue={value} setBtnValue={setValue} /> : <UserCryptoWalletList btnValue={value} setBtnValue={setValue} />}
      </Index.Box>
    </>
  );
};

export default WalletManagement;
