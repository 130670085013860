import React, { useEffect, useRef, useState } from "react";
import Index from "../../../../Index";
import PageIndex from "../../../../PageIndex";
import { imageUrl } from "../../../../../config/dataService";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation, Pagination } from "swiper/modules";
import moment from "moment";
import ReactPlayer from "react-player";
import numeral from "numeral";
const UserPostData = ({ postdata, loader,userName,setImageOpen,setImagePath }) => {
  const divRef = useRef(null);
  //all state
  const [currentVideoIndex, setCurrentVideoIndex] = useState(null);
  const [currentVideoId,setCurrentVideoId]=useState(null)
  const [pageData, setPageData] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState([]);
  
 
  const handleOpenImage = (image) => {
    if(image){
      setImageOpen(true)
      setImagePath(image)
    }
  }

  useEffect(() => {
    const filterData = postdata?.filter((item) => {

      return search
        ? item?.caption?.toLowerCase()?.includes(search.toLowerCase()) ||
        new Date(item?.createdAt)
          ?.toLocaleDateString("en-GB")
          ?.toString()
          .includes(search?.toLowerCase())
        : item;
    });
    setFilterData(filterData);
  }, [search, postdata]);

  const handleVideoClick = (index, id) => {
    if (index === currentVideoIndex && id === currentVideoId) {
      setCurrentVideoIndex(null);
      setCurrentVideoId(null);
    } else {
      setCurrentVideoIndex(index);
      setCurrentVideoId(id);
    }
  }
  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="user-list-flex">
          <Index.Box className="admin-page-title-main">
            <Index.Typography
              className="admin-page-title"
              component="h2"
              variant="h2"
            >
              Post
            </Index.Typography>
          </Index.Box>
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search"
                    name="search"
                    value={search}
                    onChange={(e) => {
                      const newValue = e.target.value
                        .replace(/^\s+/, "")
                        .replace(/\s\s+/g, " ");
                      setSearch(newValue);
                    }}
                  />
                  <span className="search-icon-box">
                    <img src={Index.Svg.search} className="search-icon" />
                  </span>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row-userlist"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="usermaster-post-table page-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head cus-table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              S.No.
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              Media
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              Caption
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              Like
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              Comments Count
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              Created Date
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>

                        <Index.TableBody className="table-body">
                          {!loader ? (
                            pageData?.length > 0 ? (
                              pageData?.map((row, rowIndex) => (
                                <Index.TableRow
                                  key={row._id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row?.sNo}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td user-view-post-data"
                                    align="center"
                                  >
                                    <Index.Box className="cus-slider table-swiper">
                                      <Swiper
                                        navigation={true}
                                        modules={[Pagination, Navigation]}
                                        className="mySwiper"
                                        onSlideChange={(swiper) => handleVideoClick(swiper.activeIndex, row._id)}
                                      >
                                        {row &&
                                          row?.profile?.map((profile, profileIndex) => (
                                            <SwiperSlide key={`${row._id}_${profileIndex}`}>
                                              {profile?.endsWith(".mp4")||profile?.endsWith(".mov") ? (
                                                <Index.Box className='video-swiper' >
                                                  <ReactPlayer
                                                    component="video"
                                                    controls
                                                    width={150}
                                                    height={66}
                                                    className="react-player"
                                                    url={`${imageUrl}${profile}`}
                                                    playing={profileIndex === currentVideoIndex && row._id === currentVideoId}
                                                    onPlay={() => handleVideoClick(profileIndex, row._id)}
                                                    
                                            
                                                  />
                                                </Index.Box>
                                              ) : (
                                                <Index.Box className='image-swiper' onClick={()=>handleOpenImage(profile)}>
                                                  <img src={`${imageUrl}${profile}`} />
                                                </Index.Box>
                                              )}
                                            </SwiperSlide>
                                          ))}
                                      </Swiper>
                                    </Index.Box>
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                    align="center"
                                  >
                                    {console.log(row?.caption?.split(userName))}
                                    {row?.caption &&
                                      row?.caption.length > 35 ? (
                                      <PageIndex.LightTooltip
                                        title={
                                          <Index.Box
                                          ref={divRef} 
                                            dangerouslySetInnerHTML={{
                                              __html: row?.caption
                                                ? row?.caption?.split(userName)[1]
                                                : "-",
                                            }}
                                          ></Index.Box>
                                        }
                                        arrowPlacement="top"
                                      >
                                        <Index.Box
                                              ref={divRef} 
                                          dangerouslySetInnerHTML={{
                                            __html: row?.caption
                                              ? row?.caption?.split(userName)[1]
                                                ?.slice(0, 35) + "..."
                                              : "-",
                                          }}
                                        ></Index.Box>
                                      </PageIndex.LightTooltip>
                                    ) : (
                                      <Index.Box
                                      ref={divRef} 
                                        dangerouslySetInnerHTML={{
                                          __html: row?.caption
                                            ? row?.caption?.split(userName)[1]

                                            : "-",
                                        }}
                                      ></Index.Box>
                                    )}
                                    {/* {row?.caption ?row?.caption:"-"} */}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                    align="center"
                                  >
                                    {/* {row?.likes ? row?.likes?.length : "-"} */}
                                    {row?.likes
                                    ? Number.isInteger(row?.likes?.length)
                                      ? numeral(row?.likes?.length).format('0')
                                      : numeral(row?.likes?.length).format('0.000a')
                                    : 0}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                    align="center"
                                  >
                                  {/* {row?.commentCount
                                      ? row?.commentCount
                                      : "0"} */}
                                          {row?.commentCount
                                    ? Number.isInteger(row?.commentCount)
                                      ? numeral(row?.commentCount).format('0')
                                      : numeral(row?.commentCount).format('0.000a')
                                    : 0}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                    align="center"
                                  >
                                    {moment(row.createdAt).format("DD-MM-YYYY")}
                                  </Index.TableCell>
                                </Index.TableRow>
                              ))
                            ) : (
                              <PageIndex.RecordNotFound colSpan={6} />
                            )
                          ) : (
                            <PageIndex.TableLoader colSpan={6} />
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {/* Pagination */}
      <PageIndex.Pagination
        fetchData={filterData}
        setPageData={setPageData}
        pageData={pageData}
      />
    </>
  );
};

export default UserPostData;
