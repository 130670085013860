import React from "react";
import PageIndex from "../../container/PageIndex";
import { addRejectionReason } from "../../validation/Validation";
import Index from "../../container/Index";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const DeclineModel = ({
  openDeclineModel,
  handleCloseDeclineModel,
  //   initialValues,
  singleWithdrawalData,
  reason,
  isDeleteButtonDisabled,

  handleSubmit,
  
}) => {
  let initialValues;
  if (singleWithdrawalData?.reason) {
    console.log("enter", 14);
    initialValues = {
      reason: singleWithdrawalData?.reason,
      actualTransactionId: singleWithdrawalData?.actualTransactionId,
      //   id: reason,
      isEdit: false,
    };
  } else {
    console.log("notenter", 14);
    initialValues = {
      reason: "",
      actualTransactionId: "",
      isEdit: false,
    };
  }
  return (
    <>
      <Index.Modal
        open={openDeclineModel}
        // onClose={handleCloseDeclineModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete modal"
      >
        <Index.Box
          sx={style}
          className="withdrawal-modal-inner-main modal-inner"
        >
          <Index.Box className="modal-circle-main cus-approve-modal">
            <Index.IconButton onClick={handleCloseDeclineModel}>
            <img src={Index.Png.close} className="user-circle-img" />
            </Index.IconButton>
          </Index.Box>
          <Index.Typography
            className="withdrawal-modal-heading"
            component="h2"
            variant="h2"
          >
            Decline
          </Index.Typography>
          <Index.Divider />
          <Index.Typography
            className="withdrawal-modal-title"
            component="h2"
            variant="h2"
          >
            Are you sure, you want to decline ?
          </Index.Typography>

          <PageIndex.Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={addRejectionReason}
          >
            {({
              values,
              handleBlur,
              handleChange,
              errors,
              touched,
              setFieldValue,
            }) => (
              <PageIndex.Form>

                <Index.Box className="input-box add-user-input">
                  <Index.FormHelperText className="form-lable">
                    Reason   <span className="mandatory-asterisk">*</span>
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="reason"
                      name="reason"
                      className="form-control"
                      value={values?.reason}
                      onChange={(e) => {
                        const newValue = e.target.value
                          .replace(/^\s+/, "")
                          .replace(/[^A-Za-z]/g, " ")
                          .replace(/\s\s+/g, " ");
                        setFieldValue("reason", newValue);
                      }}
                      onBlur={handleBlur}
                      placeholder="Reason"
                      error={Boolean(errors.reason && touched.reason)}
                      helperText={touched.reason && errors.reason}
                    />
                  </Index.Box>
                </Index.Box>
                {/* <Index.Box className="input-box add-user-input">
                  <Index.FormHelperText className="form-lable">
                    Actual Transaction Id   <span className="mandatory-asterisk">*</span>
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="actualTransactionId"
                      name="actualTransactionId"
                      className="form-control"
                      value={
                        values?.actualTransactionId
                      }
                      onBlur={handleBlur}
                      placeholder="Actual Transaction Id "
                      error={Boolean(errors.actualTransactionId && touched.actualTransactionId)}
                      helperText={touched.actualTransactionId && errors.actualTransactionId}
                      onChange={(e) => {
                        const newValue = e.target.value
                          .replace(/\s/, "")
                          .replace(/[^A-Za-z0-9 ]/g, "");


                        if (newValue.length <= 20) {
                          // newValue = newValue.slice(0, 10);
                          setFieldValue("actualTransactionId", newValue);
                        }

                        // setActualTransactionIdError("");

                      }}

                    />

                  </Index.Box>
                </Index.Box> */}
                <Index.Box className="delete-modal-btn-flex">
                  <Index.Button
                    className="modal-cancel-btn modal-btn"
                    onClick={handleCloseDeclineModel}
                  >
                    Cancel
                  </Index.Button>

                  <Index.Button
                    type="submit"
                    className="modal-delete-btn modal-btn"
                    disabled={isDeleteButtonDisabled}
                  >
                    {isDeleteButtonDisabled ? (
                      <PageIndex.ButtonLoader
                        color="white"
                        size={14}
                        loading={isDeleteButtonDisabled}
                      />
                    ) :
                      "Submit"}

                  </Index.Button>
                </Index.Box>
              </PageIndex.Form>
            )}
          </PageIndex.Formik>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default DeclineModel;
