import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { imageUrl } from "../../../../config/dataService";
import {
    deleteReelReport,
    getSingleReelReportsList,
    sendMailReelReports,
    suspendUser,
} from "../../../../redux/slices/adminService";
import moment from "moment/moment";
import numeral from "numeral";


const ViewReelReport = () => {
    const divRef = useRef(null);
    const params = PageIndex.useLocation();
    let navigate = PageIndex.useNavigate();
    const { rolePermission } = PageIndex.useSelector((state) => state.admin);

    const [loader, setLoader] = useState(true);
    const [currentVideoId, setCurrentVideoId] = useState(null);
    const [filterData, setFilterData] = useState([]);
    const [pageData, setPageData] = useState([]);
    const [reelReportist, setReelReportist] = useState([]);
    const [search, setSearch] = useState("");
    const [deleteId, setDeleteId] = useState();
    const [openDelete, setOpenDelete] = useState(false);
    const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false);
    const [isMail, setIsMail] = useState(false);
    const [suspendUserId, setSuspendUserId] = useState();
    const [openSuspend, setOpenSuspend] = useState(false);
    const [imagePath, setImagePath] = useState();
    const [openImage, setOpenImage] = useState(false);
    //Fetch Post Report List

    const postId = params?.state?.postId;
    const sendMailToUser = reelReportist[0]?.reelId?.createdBy?._id

    const fetchSinglePostReport = () => {
        getSingleReelReportsList(postId).then((data) => {
            if (data?.status == 200) {
                setLoader(false);
                setReelReportist(data?.data);
                setFilterData(data?.data);
            } else {
                setReelReportist([]);
                setLoader(false);
            }
        });
    };
    useEffect(() => {
        fetchSinglePostReport();
    }, []);

    const handleOpenImage = (image) => {
        if (image) {
            setOpenImage(true);
            setImagePath(image);
        }
    };

    const handleImageClose = () => {
        setImagePath('')
        setOpenImage(false);
    }

    setTimeout(() => {
        setLoader(false);
    }, 4000);
    useEffect(() => {
        const filterData = reelReportist?.filter((item) => {
            return search
                ? item?.userId?.userName?.toLowerCase().includes(search?.toLowerCase()) ||
                item?.report?.toLowerCase().includes(search?.toLowerCase()) ||
                new Date(item?.createdAt)
                    ?.toLocaleDateString("en-GB")
                    ?.toString()
                    .includes(search?.toLowerCase())
                : item;
        });
        setFilterData(filterData);
    }, [search, reelReportist]);

    const handleOpenDelete = (type) => {
        setOpenDelete(true);
        setDeleteId(postId);
    };
    const handleCloseDelete = () => setOpenDelete(false);

    const handleOpenSuspend = (type) => {
        setOpenSuspend(true);
        setSuspendUserId(postId);
    };
    const handleCloseSuspend = () => setOpenSuspend(false);

    const deletePost = (id) => {
        setIsDeleteButtonDisabled(true);
        deleteReelReport({ id: deleteId }).then((res) => {
            handleCloseDelete();
            setIsDeleteButtonDisabled(false);
            fetchSinglePostReport();
            if (res?.status === 200) {
                navigate("/dashboard/user-report", {
                    state: {
                        postKey: 2,
                    },
                });
            } else {
                setTimeout(() => {
                    setIsDeleteButtonDisabled(false);
                }, 3000);
            }
        });
    };

    const handleSendMail = () => {
        setIsMail(true);
        sendMailReelReports(sendMailToUser).then((data) => {
            if (data?.status == 200) {
                setIsMail(false);
            } else {
                setTimeout(() => {
                    setIsMail(false);
                }, 3000);
            }
        });
    };
    const suspendUserByAdmin = () => {
        setIsDeleteButtonDisabled(true);
        suspendUser(sendMailToUser).then((res) => {
            setIsDeleteButtonDisabled(false);
            fetchSinglePostReport();
            if (res?.status === 200) {
                handleCloseSuspend();
            } else {
                setTimeout(() => {
                    setIsDeleteButtonDisabled(false);
                }, 3000);
            }
        });
    };


    const handleVideoClick = (row) => {
        if (row === currentVideoId) {
            setCurrentVideoId(null);
        } else {
            if (currentVideoId !== null) {
                setCurrentVideoId(null);
            }

            setCurrentVideoId(row);
        }
    };
    ////////////////////////////////////////////////

    return (
        <>
            <Index.Box className='user-details-back-btn'>
                <PageIndex.BackButton onClick={() => navigate("/dashboard/user-report", {
                    state: {
                        postKey: 0,
                    },
                })} />
            </Index.Box>
            <Index.Box className="user-detail-section">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                        display="grid"
                        className="display-row-add-user"
                        gridTemplateColumns="repeat(12, 1fr)"
                    // gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    >
                        <Index.Box
                            gridColumn={{
                                xs: "span 12",
                                sm: "span 12",
                                md: "span 12",
                                lg: "span 3",
                            }}
                            className="grid-column"
                        >
                            {/* {console.log("postId", reelReportist)} */}
                            <Index.Box className="left-wrape">
                                <Index.Box className="profile-wrape">
                                    <Index.Box className="image-wrape"
                                        onClick={() => handleOpenImage(reelReportist[0]?.reelId?.createdBy?.profile)}>
                                        <img
                                            src={
                                                reelReportist[0]?.reelId?.createdBy?.profile
                                                    ?
                                                    reelReportist[0]?.reelId?.createdBy?.profile instanceof
                                                        Blob
                                                        ? URL.createObjectURL(
                                                            reelReportist[0]?.reelId?.createdBy?.profile
                                                        )
                                                        : `${imageUrl}${reelReportist[0]?.reelId?.createdBy?.profile}`
                                                    : PageIndex?.Jpg?.dummyImage
                                            }
                                            className="user-post-post-img"
                                        />
                                    </Index.Box>
                                </Index.Box>
                                <Index.Box className="detail">

                                    <label>Full Name</label>
                                    <p> {reelReportist[0]?.reelId?.createdBy?.name ? reelReportist[0]?.reelId?.createdBy?.name : "-"}</p>
                                    <label> Email</label>
                                    <p> {reelReportist[0]?.reelId?.createdBy?.email ? reelReportist[0]?.reelId?.createdBy?.email : "-"}</p>
                                    <label>Username</label>
                                    <p>
                                        <span
                                            onClick={() => {
                                                navigate('/dashboard/user-view',
                                                    {
                                                        state: {
                                                            data: reelReportist[0]?.reelId?.createdBy?._id,
                                                            postId: postId,
                                                            pathName: params.pathname
                                                        },
                                                    }
                                                )
                                            }}
                                            className="cus-user-name-navigate"
                                        >{reelReportist[0]?.reelId?.createdBy?.userName ? reelReportist[0]?.reelId?.createdBy?.userName : '-'}</span>
                                    </p>
                                    <label>Phone Number</label>
                                    <p>
                                        {reelReportist[0]?.reelId?.createdBy?.phoneNumber ? `${reelReportist[0]?.reelId?.createdBy?.countryCode} ${reelReportist[0]?.postId?.createdBy?.phoneNumber}` : '-'}
                                    </p>
                                    <label>Engagement Ratio</label>
                                    <p> {reelReportist[0]?.allLikeComment ? `${reelReportist[0]?.allLikeComment?.toFixed(2)}%` : '-'}</p>
                                    <label>Country</label>
                                    <p>  {reelReportist[0]?.reelId?.createdBy?.country ? reelReportist[0]?.reelId?.createdBy?.country : "-"}</p>

                                    <label>Bio</label>
                                    <p>
                                        {reelReportist[0]?.reelId?.createdBy?.Bio &&
                                            reelReportist[0]?.reelId?.createdBy?.Bio?.length > 25 ? (
                                            <PageIndex.LightTooltip
                                                placement="right"
                                                title={reelReportist[0]?.reelId?.createdBy?.Bio}
                                            >
                                                {reelReportist[0]?.reelId?.createdBy?.Bio.slice(0, 25) + "..."}
                                            </PageIndex.LightTooltip>
                                        ) : (
                                            reelReportist[0]?.reelId?.createdBy?.Bio || "-"
                                        )}
                                    </p>
                                    <label>Suspend</label>
                                    <p>
                                        {reelReportist[0]?.reelId?.createdBy?.isSuspended === true
                                            ? "Yes"
                                            : "No"}
                                    </p>
                                    <label>Links</label>
                                    {/* <p>{reelReportist[0]?.reelId?.createdBy?.links ? reelReportist[0]?.reelId?.createdBy?.links : "-"}</p> */}
                                    <p>
                                        {reelReportist[0]?.reelId?.createdBy?.links &&
                                            reelReportist[0]?.reelId?.createdBy?.links?.length > 25 ? (
                                            <PageIndex.LightTooltip
                                                placement="right"
                                                title={reelReportist[0]?.reelId?.createdBy?.links?.split(',').map((item) => <p>{item}</p>)}
                                            >
                                                {reelReportist[0]?.reelId?.createdBy?.links.slice(0, 25) + "..."}
                                            </PageIndex.LightTooltip>
                                        ) : (
                                            reelReportist[0]?.reelId?.createdBy?.links?.Bio || "-"
                                        )}

                                    </p>
                                    <label>Following Count</label>
                                    <p>
                                        {/* {reelReportist[0]?.followingCount ? reelReportist[0]?.followingCount : 0} */}
                                        {reelReportist[0]?.followingCount
                                            ? Number.isInteger(reelReportist[0]?.followingCount)
                                                ? numeral(reelReportist[0]?.followingCount).format('0')
                                                : numeral(reelReportist[0]?.followingCount).format('0.000a')
                                            : 0}

                                    </p>
                                    <label>Followers Count</label>
                                    <p>
                                        {/* {reelReportist[0]?.followersCount ? reelReportist[0]?.followersCount : 0} */}
                                        {reelReportist[0]?.followersCount
                                            ? Number.isInteger(reelReportist[0]?.followersCount)
                                                ? numeral(reelReportist[0]?.followersCount).format('0')
                                                : numeral(reelReportist[0]?.followersCount).format('0.000a')
                                            : 0}
                                    </p>
                                </Index.Box>

                                {(rolePermission && rolePermission?.isAdmin === true) ? (
                                    <PageIndex.LightTooltip title={reelReportist[0]?.reelId?.createdBy?.isSuspended != true ? "Suspend" : "Unsuspend"}>
                                        <Index.IconButton
                                            color="primary"
                                            aria-label="upload picture"
                                            component="label"
                                            // disabled={userdata?.isSuspended === true}
                                            onClick={() => {
                                                handleOpenSuspend();
                                            }}
                                        >

                                            <img
                                                src={reelReportist[0]?.reelId?.createdBy?.isSuspended != true ? Index.Png.blockUser : Index.Png.unBlockUser}
                                                className="dash-icons"
                                                alt="dashboard icon"
                                            />
                                            {/* <Index.DeleteIcon className="usermailwarning-img" /> */}
                                        </Index.IconButton>
                                    </PageIndex.LightTooltip>) : ""}
                            </Index.Box>
                        </Index.Box>
                        <Index.Box
                            gridColumn={{
                                xs: "span 12",
                                sm: "span 12",
                                md: "span 12",
                                lg: "span 9",
                            }}
                            className="grid-column"
                        >
                            <Index.Box className="right-wrape">
                                <Index.Box className="owner-detail-wrap">
                                    <Index.Box className="mini-card">
                                        <Index.Box className="owner-image-wrape cus-image-wrape">
                                            <Index.Box className="user-post-post-img-box">
                                                <Index.Box className='cus-view-user-slider'>

                                                    <Index.Box >
                                                        <Index.ReactPlayer
                                                            component="video"
                                                            controls
                                                            width={200}
                                                            height={150}

                                                            url={`${imageUrl}${reelReportist[0]?.reelId?.media}`}
                                                        />
                                                    </Index.Box>

                                                </Index.Box>
                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Box>

                                    <Index.Box className="start-section owener-detail">
                                        <Index.Box className="detail profile-detail">
                                            <label>Caption</label>

                                            <p className="post-description">
                                                {reelReportist[0]?.reelId?.caption && reelReportist[0]?.reelId?.caption?.length > 40 ? (
                                                    <PageIndex.LightTooltip
                                                        title={
                                                            <Index.Box
                                                                ref={divRef}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: reelReportist[0]?.reelId?.caption ? reelReportist[0]?.reelId?.caption : "-",
                                                                }}
                                                            ></Index.Box>
                                                        }
                                                        placement="right"
                                                    >
                                                        <Index.Box
                                                            ref={divRef}
                                                            dangerouslySetInnerHTML={{
                                                                __html: reelReportist[0]?.reelId?.caption
                                                                    ? reelReportist[0]?.reelId?.caption?.slice(0, 45) + "..."
                                                                    : "-",
                                                            }}
                                                        ></Index.Box>
                                                    </PageIndex.LightTooltip>
                                                ) : (
                                                    <Index.Box
                                                        ref={divRef}
                                                        dangerouslySetInnerHTML={{
                                                            __html: reelReportist[0]?.reelId?.caption ? reelReportist[0]?.reelId?.caption : "-",
                                                        }}
                                                    ></Index.Box>
                                                )}
                                            </p>
                                        </Index.Box>

                                        <Index.Box className="detail profile-detail">
                                            <label>Location</label>
                                            <p className="post-description">
                                                {reelReportist[0]?.reelId?.location && reelReportist[0]?.reelId?.location?.length > 30 ? (
                                                    <PageIndex.LightTooltip
                                                        placement="top"
                                                        title={reelReportist[0]?.reelId?.location}
                                                    >
                                                        {reelReportist[0]?.reelId?.location.slice(0, 30) + "..."}
                                                    </PageIndex.LightTooltip>
                                                ) : (
                                                    reelReportist[0]?.reelId?.location || "-"
                                                )}
                                            </p>
                                        </Index.Box>
                                        <Index.Box className="detail profile-detail">
                                            <label>Created at</label>
                                            <p className="post-description">
                                                {reelReportist[0]?.reelId?.createdAt ? moment(reelReportist[0]?.reelId?.createdAt).format(
                                                    "DD/MM/YYYY hh:mm A"
                                                ) : "-"}
                                            </p>
                                        </Index.Box>
                                        <Index.Box className="detail profile-detail">
                                            <label>Allow Comments</label>
                                            <p className="post-description">
                                                {reelReportist[0]?.reelId?.allowComments == true ? "Yes" : "No"}
                                            </p>
                                        </Index.Box>
                                        <Index.Box className="detail profile-detail">
                                            <label>Visible to Everyone</label>
                                            <p className="post-description">
                                                {reelReportist[0]?.reelId?.allowComments == true ? "Yes" : "No"}
                                            </p>
                                        </Index.Box>

                                        {(rolePermission &&
                                            rolePermission?.roleType?.rolePermission?.includes(
                                                "UserReport_view"
                                            )) ||
                                            (rolePermission &&
                                                rolePermission?.roleType?.rolePermission?.includes(
                                                    "UserReport_edit"
                                                )) ||
                                            rolePermission?.roleType?.rolePermission?.includes(
                                                "UserReport_delete"
                                            ) ||
                                            (rolePermission && rolePermission?.isAdmin === true) ? (
                                            <>
                                                <Index.Box className="userdata-btn-flex">
                                                    {(rolePermission &&
                                                        rolePermission?.roleType?.rolePermission?.includes(
                                                            "UserReport_edit"
                                                        )) ||
                                                        (rolePermission &&
                                                            rolePermission?.isAdmin === true) ? (
                                                        <>
                                                            <PageIndex.LightTooltip title="Warning mail">
                                                                <Index.IconButton
                                                                    color="primary"
                                                                    aria-label="upload picture"
                                                                    component="label"
                                                                    disabled={isMail}
                                                                    onClick={() => {
                                                                        handleSendMail();
                                                                    }}
                                                                >
                                                                    <img
                                                                        alt="img"
                                                                        className="usermailwarning-img"
                                                                        src={Index.Png.userMailWarning}
                                                                    />
                                                                </Index.IconButton>
                                                            </PageIndex.LightTooltip>
                                                        </>
                                                    ) : (
                                                        ""
                                                    )}

                                                    {(rolePermission &&
                                                        rolePermission?.roleType?.rolePermission?.includes(
                                                            "UserReport_delete"
                                                        )) ||
                                                        (rolePermission &&
                                                            rolePermission?.isAdmin === true) ? (
                                                        <>
                                                            <PageIndex.LightTooltip position='right' title="Delete post">
                                                                <Index.IconButton
                                                                    color="primary"
                                                                    aria-label="upload picture"
                                                                    component="label"
                                                                    onClick={() => {
                                                                        handleOpenDelete();
                                                                    }}
                                                                >
                                                                    <Index.DeleteIcon className="usermailwarning-img" />
                                                                </Index.IconButton>
                                                            </PageIndex.LightTooltip>
                                                        </>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Index.Box>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </Index.Box>
                                </Index.Box>

                                <Index.Box className="table-wrape">
                                    <Index.Box className="external-tab-box">
                                        <Index.Box className="barge-common-box">
                                            <Index.Box className="common-box">
                                                <Index.Box className="user-list-flex">
                                                    <Index.Box className="admin-page-title-main">
                                                        <Index.Typography
                                                            className="admin-page-title tittle-like-costam"
                                                            component="h2"
                                                            variant="h2"
                                                        >
                                                            Post Report
                                                        </Index.Typography>
                                                    </Index.Box>
                                                    <Index.Box className="userlist-btn-flex">
                                                        <Index.Box className="user-search-main">
                                                            <Index.Box className="user-search-box">
                                                                <Index.Box className="form-group">
                                                                    <Index.TextField
                                                                        fullWidth
                                                                        id="fullWidth"
                                                                        className="form-control"
                                                                        value={search}
                                                                        placeholder="Search user"
                                                                        onChange={(e) => {
                                                                            const newValue = e.target.value
                                                                                .replace(/^\s+/, "")
                                                                                .replace(/\s\s+/g, " ");

                                                                            setSearch(newValue);
                                                                        }}
                                                                    />
                                                                    <span className="search-icon-box">
                                                                        <img
                                                                            src={Index.Svg.search}
                                                                            className="search-icon"
                                                                        />
                                                                    </span>
                                                                </Index.Box>
                                                            </Index.Box>
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Index.Box>
                                                <Index.Box className="admin-dash-box">
                                                    <Index.Box className="page-table-main like-table-main">
                                                        <Index.TableContainer
                                                            component={Index.Paper}
                                                            className="table-container"
                                                        >
                                                            <Index.Table
                                                                stickyHeader
                                                                aria-label="simple table"
                                                                className="table"
                                                            >
                                                                <Index.TableHead className="table-head cus-table-head">
                                                                    <Index.TableRow className="table-row">
                                                                        <Index.TableCell
                                                                            component="th"
                                                                            variant="th"
                                                                            className="table-th"
                                                                            align="center"
                                                                        >
                                                                            S.No.
                                                                        </Index.TableCell>
                                                                        <Index.TableCell
                                                                            component="th"
                                                                            variant="th"
                                                                            className="table-th"
                                                                            align="center"
                                                                        >
                                                                            Report
                                                                        </Index.TableCell>
                                                                        <Index.TableCell
                                                                            component="th"
                                                                            variant="th"
                                                                            className="table-th"
                                                                            align="center"
                                                                        >
                                                                            Report By
                                                                        </Index.TableCell>
                                                                        <Index.TableCell
                                                                            component="th"
                                                                            variant="th"
                                                                            className="table-th"
                                                                            align="center"
                                                                        >
                                                                            Created Date
                                                                        </Index.TableCell>
                                                                        <Index.TableCell
                                                                            component="th"
                                                                            variant="th"
                                                                            className="table-th"
                                                                            align="center"
                                                                        >
                                                                            Created Time
                                                                        </Index.TableCell>
                                                                    </Index.TableRow>
                                                                </Index.TableHead>
                                                                <Index.TableBody className="table-body">
                                                                    {!loader ? (
                                                                        pageData?.length > 0 ? (
                                                                            pageData?.map((row, index) => (
                                                                                <Index.TableRow key={row?._id}>
                                                                                    {console.log("row", row)}
                                                                                    <Index.TableCell
                                                                                        component="td"
                                                                                        variant="td"
                                                                                        scope="row"
                                                                                        className="table-td"
                                                                                        align="center"
                                                                                    >
                                                                                        {index + 1}
                                                                                    </Index.TableCell>
                                                                                    <Index.TableCell
                                                                                        component="td"
                                                                                        variant="td"
                                                                                        scope="row"
                                                                                        className="table-td"
                                                                                        align="center"
                                                                                    >
                                                                                        {row?.report &&
                                                                                            row?.report?.length > 30 ? (
                                                                                            <PageIndex.LightTooltip
                                                                                                title={row?.report}
                                                                                                arrowPlacement="top"
                                                                                            >
                                                                                                {row?.report.slice(0, 30)}...
                                                                                            </PageIndex.LightTooltip>
                                                                                        ) : (
                                                                                            row?.report || "-"
                                                                                        )}
                                                                                    </Index.TableCell>
                                                                                    <Index.TableCell
                                                                                        component="td"
                                                                                        variant="td"
                                                                                        scope="row"
                                                                                        className="table-td"
                                                                                        align="center"
                                                                                    >
                                                                                        <span className="cus-user-name-navigate"
                                                                                            onClick={() => {
                                                                                                navigate('/dashboard/user-view',
                                                                                                    {
                                                                                                        state: {
                                                                                                            data: row?.userId?._id,
                                                                                                            postId: postId,
                                                                                                            pathName: params.pathname
                                                                                                        },
                                                                                                    }
                                                                                                )
                                                                                            }}
                                                                                        >{row?.userId?.userName ? row?.userId?.userName : "-"}</span>
                                                                                    </Index.TableCell>
                                                                                    <Index.TableCell
                                                                                        component="td"
                                                                                        variant="td"
                                                                                        scope="row"
                                                                                        className="table-td"
                                                                                        align="center"
                                                                                    >
                                                                                        {moment(row?.createdAt).format("DD/MM/YYYY ")}
                                                                                    </Index.TableCell>
                                                                                    <Index.TableCell
                                                                                        component="td"
                                                                                        variant="td"
                                                                                        scope="row"
                                                                                        className="table-td"
                                                                                        align="center"
                                                                                    >
                                                                                        {moment(row?.createdAt).format("hh:mm A")}
                                                                                    </Index.TableCell>
                                                                                </Index.TableRow>
                                                                            ))
                                                                        ) : (
                                                                            <PageIndex.RecordNotFound colSpan={5} />
                                                                        )
                                                                    ) : (
                                                                        <PageIndex.TableLoader colSpan={5} />
                                                                    )}
                                                                </Index.TableBody>
                                                            </Index.Table>
                                                        </Index.TableContainer>
                                                    </Index.Box>
                                                </Index.Box>
                                                <Index.Box className="pagination-costom">
                                                    <PageIndex.Pagination
                                                        fetchData={filterData}
                                                        setPageData={setPageData}
                                                        pageData={pageData}
                                                        search={search}
                                                    />
                                                </Index.Box>
                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Box>
            <PageIndex.DeleteModal
                openDelete={openDelete}
                handleCloseDelete={handleCloseDelete}
                handleDeleteRecord={deletePost}
                isDeleteButtonDisabled={isDeleteButtonDisabled}
            />
            <PageIndex.SuspendModal
                openSuspend={openSuspend}
                handleCloseSuspend={handleCloseSuspend}
                handleSuspendRecord={suspendUserByAdmin}
                isDeleteButtonDisabled={isDeleteButtonDisabled}
            />
            <PageIndex.ImageViewModel
                handleClose={handleImageClose}
                open={openImage}
                image={imagePath}
            />
        </>
    );
};

export default ViewReelReport;
