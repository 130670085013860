import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { getTodayReportAction } from "../../../../redux/slices/adminService";
import numeral from "numeral";

export const TodayReport = () => {
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(true);
  const today = "today"
  const navigate = PageIndex.useNavigate();

  const getTodayReport = () => {
    setLoader(true)
    getTodayReportAction().then((res) => {
      if (res?.status == 200) {
        setData(res?.data);
        setLoader(false)
      }
      else {
        setTimeout(() => {
          setLoader(false);
        }, 3000);
      }

    });
  };
  useEffect(() => {
    getTodayReport();
  }, []);

  return (
    <>
      {!loader ? (
        <Index.Box className="common-box">
          <Index.Box className="user-list-flex">
            <Index.Box className="admin-page-title-main cus-dashboard-heading">
              <Index.Typography
                className="admin-page-title"
                component="h2"
                variant="h2"
              >
                Today Report Dashboard
              </Index.Typography>
            </Index.Box>
          </Index.Box>

          <Index.Box className="admin-dashboad-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row-dashboard"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box
                      className="admin-dashboard-inner-box"
                      onClick={() => window.open(`/dashboard/transactions-list?state=${encodeURIComponent(JSON.stringify({ today }))}`, "_blank")}
                    // onClick={() => navigate("/dashboard/transactions-list",{state:today})}
                    >
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Png.transactionHistory}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>

                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total no. of transactions
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {data?.transactionCount
                            ? data.transactionCount < 1000
                              ? numeral(data.transactionCount).format('0,0')
                              : numeral(data.transactionCount).format('0.00a')
                            : 0}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box
                      className="admin-dashboard-inner-box"
                      // onClick={() => navigate("/dashboard/user-list",{state:{type:today}})}

                      onClick={() => window.open(`/dashboard/user-list?state=${encodeURIComponent(JSON.stringify({ type: today }))}`, "_blank")}

                    >
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Png.registerUser}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>

                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total registered accounts
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >

                          {data?.userCount
                            ? data.userCount < 1000
                              ? numeral(data.userCount).format('0,0')
                              : numeral(data.userCount).format('0.00a')
                            : 0}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box
                      className="admin-dashboard-inner-box"
                      // onClick={() => navigate("/dashboard/user-report",{state:{today,key:2}})}
                      onClick={() => window.open(`/dashboard/user-report?state=${encodeURIComponent(JSON.stringify({ today, key: 2 }))}`, "_blank")}

                    >
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Png.reportedUser}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>

                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total reports
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {data?.reportCount
                            ? data.reportCount < 1000
                              ? numeral(data.reportCount).format('0,0')
                              : numeral(data.reportCount).format('0.00a')
                            : 0}

                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box
                      className="admin-dashboard-inner-box"
                      // onClick={() => navigate("/dashboard/contest-list",{state:today})}
                      onClick={() => window.open(`/dashboard/contest-list?state=${encodeURIComponent(JSON.stringify({ today }))}`, "_blank")}


                    >
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Png.trophy}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>

                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total created contest
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {data?.contestCount
                            ? data.contestCount < 1000
                              ? numeral(data.contestCount).format('0,0')
                              : numeral(data.contestCount).format('0.00a')
                            : 0}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box
                      className="admin-dashboard-inner-box"
                      onClick={() => window.open(`/dashboard/withdrawal-conversion-request-list?state=${encodeURIComponent(JSON.stringify({ today }))}`, "_blank")}
                    // onClick={() => navigate("/dashboard/withdrawal-conversion-request-list",{state:today})}
                    >
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Png.withdraw}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>

                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total withdrawal requests
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {data?.withdrawalRequest
                            ? data.withdrawalRequest < 1000
                              ? numeral(data.withdrawalRequest).format('0,0')
                              : numeral(data.withdrawalRequest).format('0.00a')
                            : 0}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box
                      className="admin-dashboard-inner-box"
                      onClick={() => window.open(`/dashboard/user-list?state=${encodeURIComponent(JSON.stringify({ type: today, status: "suspended" }))}`, "_blank")}>
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Png.blockUser}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>

                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total suspended users
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >

                          {data?.suspendedUsers
                            ? data.suspendedUsers < 1000
                              ? numeral(data.suspendedUsers).format('0,0')
                              : numeral(data.suspendedUsers).format('0.00a')
                            : 0}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box
                      className="admin-dashboard-inner-box"
                      onClick={() => window.open(`/dashboard/user-post?state=${encodeURIComponent(JSON.stringify({ type: today }))}`, "_blank")}
                    >
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Png.userPost}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>

                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Post of the day
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >

                          {data?.postOfDayCount
                            ? data.postOfDayCount < 1000
                              ? numeral(data.postOfDayCount).format('0,0')
                              : numeral(data.postOfDayCount).format('0.00a')
                            : 0}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box
                      className="admin-dashboard-inner-box"
                      onClick={() => window.open(`/dashboard/user-reels?state=${encodeURIComponent(JSON.stringify({ type: today }))}`, "_blank")}
                    >
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Png.reels}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>

                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Reel of the day
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >

                          {data?.reelOfDayCount
                            ? data.reelOfDayCount < 1000
                              ? numeral(data.reelOfDayCount).format('0,0')
                              : numeral(data.reelOfDayCount).format('0.00a')
                            : 0}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      ) : (
        <PageIndex.Loading />
      )}

    </>

  );
};
