import React from 'react'
import Index from '../../container/Index'
import PageIndex from '../../container/PageIndex'

const ViewTransactionDetailsModel = ({ open, handleClose, singleTransactionData }) => {
  return (
    <Index.Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-delete modal"
    >
      <Index.Box
        sx={Index.style}
        className="withdrawal-modal-inner-main modal-inner"
      >
        <Index.Box className="modal-circle-main cus-modal-cicle-main">
          <Index.IconButton onClick={handleClose}>
            <img src={Index.Png.close} className="user-circle-img" />
          </Index.IconButton>
        </Index.Box>
        <Index.Typography
          className="withdrawal-modal-heading"
          component="h2"
          variant="h2"
        >
          View Details
        </Index.Typography>
        <Index.Divider />

        <Index.Box className="withdrawal-model-main">
          <Index.Box className="withdrawal-model">
            <Index.Typography className="withdrawal-title">
              Username
            </Index.Typography>
            <Index.Typography className="withdrawal-content">
              {singleTransactionData?.userId?.userName
                ? singleTransactionData?.userId?.userName
                : ""}
            </Index.Typography>
          </Index.Box>
          <Index.Box className="withdrawal-model">
            <Index.Typography className="withdrawal-title">
              Name
            </Index.Typography>
            <Index.Typography className="withdrawal-content">
              {singleTransactionData?.userId?.name
                ? singleTransactionData?.userId?.name
                : ""}
            </Index.Typography>
          </Index.Box>

          <Index.Box className="withdrawal-model">
            <Index.Typography className="withdrawal-title">
              Email
            </Index.Typography>
            <Index.Typography className="withdrawal-content">
              {singleTransactionData?.userId?.email
                ? singleTransactionData?.userId?.email
                : "-"}
            </Index.Typography>
          </Index.Box>
          <Index.Box className="withdrawal-model">
            <Index.Typography className="withdrawal-title">
              Amount
            </Index.Typography>
            <Index.Typography className="withdrawal-content">
              {singleTransactionData?.amount
                ? singleTransactionData?.amount
                : "-"}
            </Index.Typography>
          </Index.Box>
          <Index.Box className="withdrawal-model">
            <Index.Typography className="withdrawal-title">
              Type
            </Index.Typography>
            <Index.Typography className="withdrawal-content">
              {singleTransactionData?.type
                ? singleTransactionData?.type
                : "-"}
            </Index.Typography>
          </Index.Box>
          {
            !(singleTransactionData?.type === "Deposit" || singleTransactionData?.type === "Diamond Tick"
              || singleTransactionData?.type === "Contest Fee"
              || singleTransactionData?.type === "Contest Winning") ? (
              <>
                <Index.Box className="withdrawal-model">
                  <Index.Typography className="withdrawal-title">
                    Platform Fee
                  </Index.Typography>
                  <Index.Typography className="withdrawal-content">
                    {singleTransactionData?.platformFee ? singleTransactionData.platformFee : "-"}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="withdrawal-model">
                  <Index.Typography className="withdrawal-title">
                    Platform Fee(%)
                  </Index.Typography>
                  <Index.Typography className="withdrawal-content">
                    {singleTransactionData?.platformFeePercent ? singleTransactionData.platformFeePercent : "-"}
                  </Index.Typography>
                </Index.Box>
              </>
            ) : null
          }


          {
            singleTransactionData?.type == "Deposit" || singleTransactionData?.type == "Diamond Tick" && singleTransactionData?.paymentMethod ?

              (<Index.Box className="withdrawal-model">
                <Index.Typography className="withdrawal-title">
                  Payment Method
                </Index.Typography>
                <Index.Typography className="withdrawal-content">
                  {singleTransactionData?.paymentMethod?.paymentType
                    ? singleTransactionData?.paymentMethod?.paymentType
                    : "-"}
                </Index.Typography>
              </Index.Box>) : ""}

          <Index.Box className="withdrawal-model">
            <Index.Typography className="withdrawal-title">
              Currency
            </Index.Typography>
            <Index.Typography className="withdrawal-content">
              {singleTransactionData?.currency?.coins
                ? singleTransactionData?.currency?.coins
                : "-"}
            </Index.Typography>
          </Index.Box>
          <Index.Box className="withdrawal-model">
            <Index.Typography className="withdrawal-title">
              Currency Type
            </Index.Typography>
            <Index.Typography className="withdrawal-content">
              {singleTransactionData?.currencyType
                ? singleTransactionData?.currencyType
                : "-"}
            </Index.Typography>
          </Index.Box>

          {
            singleTransactionData?.type == "Send" || singleTransactionData?.type == "Receive" ? (
              <>
                <Index.Box className="withdrawal-model">
                  <Index.Typography className="withdrawal-title">
                    Sender Name
                  </Index.Typography>
                  <Index.Typography className="withdrawal-content">
                    {singleTransactionData?.type == "Send"
                      ? singleTransactionData?.userId?.userName
                      : singleTransactionData?.receiverName}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="withdrawal-model">
                  <Index.Typography className="withdrawal-title">
                    Receiver Name
                  </Index.Typography>
                  <Index.Typography className="withdrawal-content">
                    {singleTransactionData?.type == "Receive"
                      ? singleTransactionData?.userId?.userName
                      : singleTransactionData?.receiverName}
                  </Index.Typography>
                </Index.Box>


              </>
            ) : ""}

          <Index.Box className="withdrawal-model">
            <Index.Typography className="withdrawal-title">
              Status
            </Index.Typography>
            <Index.Typography className="withdrawal-content">
              {singleTransactionData?.paymentStatus
                ? singleTransactionData?.paymentStatus
                : "-"}
            </Index.Typography>

          </Index.Box>
          <Index.Box className="withdrawal-model">
            <Index.Typography className="withdrawal-title">
              Transaction Id
            </Index.Typography>
            <Index.Typography className="withdrawal-content">
              {singleTransactionData?.transactionId
                ? <PageIndex.LightTooltip
                  title={singleTransactionData?.transactionId}
                  arrow
                >
                  <span>
                    {singleTransactionData?.transactionId.substring(0, 20)}...
                  </span>
                </PageIndex.LightTooltip>
                : "-"}

            </Index.Typography>

          </Index.Box>
          {
            singleTransactionData?.type == 'Withdrawal' &&
            <>
              <Index.Box className="withdrawal-model">
                <Index.Typography className="withdrawal-title">
                  Bank Name
                </Index.Typography>
                <Index.Typography className="withdrawal-content">
                  {singleTransactionData?.bankDetails?.bankName
                    ? singleTransactionData?.bankDetails?.bankName
                    : "-"}
                </Index.Typography>

              </Index.Box>
              <Index.Box className="withdrawal-model">
                <Index.Typography className="withdrawal-title">
                  IFSC Code
                </Index.Typography>
                <Index.Typography className="withdrawal-content">
                  {singleTransactionData?.bankDetails?.IFSCCode
                    ? singleTransactionData?.bankDetails?.IFSCCode
                    : "-"}
                </Index.Typography>

              </Index.Box>
              <Index.Box className="withdrawal-model">
                <Index.Typography className="withdrawal-title">
                  Account No.
                </Index.Typography>
                <Index.Typography className="withdrawal-content">
                  {singleTransactionData?.bankDetails?.bankAccountNo
                    ? singleTransactionData?.bankDetails?.bankAccountNo
                    : "-"}
                </Index.Typography>

              </Index.Box>
            </>
          }
        </Index.Box>
      </Index.Box>
    </Index.Modal>
  )
}

export default ViewTransactionDetailsModel