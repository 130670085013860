import React, { useEffect, useState } from 'react'
import Index from '../../../Index';
import PageIndex from '../../../PageIndex';
import { Form } from 'formik';import { getHelpCenter, updateHelpCenter } from '../../../../redux/slices/adminService';

const HelpCenter = () => {
  const [addEditData, setAddEditData] = useState({});
  const [loading, setLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);

  const initialValues = {
    email: addEditData ? addEditData?.email : "",
    phoneNumber: addEditData ? addEditData?.phoneNumber : "",
  }

  const handleSubmit = async (values) => {
    setIsDisabled(true);
    updateHelpCenter(values).then((res) => {
      if (res?.status == 200) {
        setTimeout(() => {
          setIsDisabled(false);
        }, 3000);

      } else {
        setTimeout(() => {
          setIsDisabled(false);
        }, 3000);
      }
    });
  };

  // Get Terms and Condition
  const fetchCmsDetails = () => {
    getHelpCenter().then((data) => {
      if (data?.status == 200) {
        setAddEditData(data?.data[0]);
        setLoading(false);
      }
      else {
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    fetchCmsDetails();
  }, []);


  return (
    <>
      {!loading &&
        <Index.Box className="dashboard-content edit-profile-containt">
          <Index.Box className="tabpanel-main">
            <Index.Box className="change-pass-main">
              <Index.Box className="admin-page-title-main cus-change-password-box">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  Help Center
                </Index.Typography>
              </Index.Box>
              <PageIndex.Formik
                validationSchema={PageIndex.helpCenterSchema}
                initialValues={initialValues}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  touched,
                  isValid,
                  dirty,
                  setFieldValue
                }) => (
                  <PageIndex.Form>
                    <Index.Box className="input-box add-user-input">
                      <Index.FormHelperText className="form-lable">
                        Email
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          disabled={isDisabled ? true : false}
                          size="small"
                          name="email"
                          className="form-control input-with-radius password-form-control email-input "

                          // label="Password"
                          variant="outlined"
                          placeholder="Email"
                          autoComplete="off"
                          inputProps={{
                            className: "input_props",
                          }}
                          InputLabelProps={{
                            className: "add-formlabel",
                          }}
                          FormHelperTextProps={{
                            className: "input_label_props",
                          }}
                          value={values.email}
                          onChange={(e) => {
                            const newValue = e.target.value.replace(
                              /\s/g,
                              ""
                            );
                            setFieldValue("email", newValue);
                          }}
                          onBlur={handleBlur}
                          error={Boolean(
                            errors?.email
                              ? touched?.email
                              : undefined
                          )}
                          helperText={
                            touched?.email
                              ? errors?.email
                              : undefined
                          }
                          sx={{ mb: 3 }}

                        />
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="input-box add-user-input">
                      <Index.FormHelperText className="form-lable">
                      Phone Number
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          disabled={isDisabled ? true : false}
                          size="small"
                          name="phoneNumber"
                          className="form-control input-with-radius password-form-control"
                          type='number'
                          // label="Password"
                          variant="outlined"
                          placeholder="Phone Number"
                          autoComplete="off"
                          inputProps={{
                            className: "input_props",
                          }}
                          InputLabelProps={{
                            className: "add-formlabel",
                          }}
                          FormHelperTextProps={{
                            className: "input_label_props",
                          }}
                          onBlur={handleBlur}
                          value={values.phoneNumber}
                          onChange={(e) => {
                            const newValue = e.target.value.replace(
                              /\s/g,
                              ""
                            );
                            setFieldValue("phoneNumber", newValue);
                          }}
                          error={Boolean(
                            errors?.phoneNumber
                              ? touched?.phoneNumber
                              : undefined
                          )}
                          helperText={
                            touched?.phoneNumber
                              ? errors?.phoneNumber
                              : undefined
                          }
                          sx={{ mb: 3 }}

                        />
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="user-btn-flex change-pass-flex">
                      <Index.Box className="save-btn-main border-btn-main">
                        <Index.Button
                          className={`save-user-btn border-btn ${isDisabled ? "btn-disabled" : ""}`}
                          type="submit"
                          disabled={isDisabled}
                        >
                          {isDisabled ? (
                            <PageIndex.ButtonLoader color="white" size={14} loading={isDisabled} />
                          ) : (
                            <>
                              <img
                                src={Index.Svg.save}
                                className="user-save-icon"
                                alt="Save Icon"
                              />
                              Update
                            </>
                          )}
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </PageIndex.Form>
                )}
              </PageIndex.Formik>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      }
    </>
  )
}

export default HelpCenter