import React from "react";
import { Modal, Box, IconButton } from "@mui/material";
import { imageUrl } from "../../config/dataService";
import Index from "../../container/Index";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import PageIndex from "../../container/PageIndex";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
 
};

const ImageViewModel = ({ open, handleClose, image, swiper }) => {
    const navigate = PageIndex.useNavigate();

    const isArray = Array.isArray(image);

    return (
        <Index.Modal
            open={open}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal-add modal"

        >
            <Index.Box sx={style}>
                <Index.IconButton onClick={handleClose} className="image-model-close-icon">
                    <img src={Index.Png.close} className="user-circle-img" alt="Close" />
                </Index.IconButton>

                {isArray && image.length > 0 ? (
                    <Swiper
                        pagination={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper"
                    >
                        {image.map((item, index) => (
                            <SwiperSlide key={index}>
                                {item.endsWith(".mp4") || item.endsWith(".mov") ? (
                                    <video
                                        controls
                                        src={`${imageUrl}${item}`}
                                        className="image-view-img"

                                    />
                                ) : (
                                    <img
                                        src={`${imageUrl}${item}`}
                                        alt={`Slide ${index}`}
                                        className="user-post-post-img image-view-img"

                                    />
                                )}
                            </SwiperSlide>
                        ))}
                    </Swiper>
                ) : (
                    <Index.Box className='view-image-box'>


                        <img
                            src={image ? (image instanceof Blob ? URL.createObjectURL(image) : `${imageUrl}${image}`) : ""}
                            alt="Preview"
                            className="image-view-img"

                        />
                    </Index.Box>
                )}
            </Index.Box>
        </Index.Modal>
    );
};

export default ImageViewModel;
