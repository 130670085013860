import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";
import PageIndex from "../../../PageIndex";
import {
  addRoleMaster,
  editRoleMaster,
} from "../../../../redux/slices/adminService";

const RoleMasterEdit = () => {
  const [loader, setLoader] = useState(false);

  const dispatch = PageIndex.useDispatch();
  const navigate = PageIndex.useNavigate();
  const params = PageIndex.useLocation();
  const editId = params?.state;

  const initialValues = {
    roleName: editId ? editId?.roleName : "",
    rolePermission: editId ? editId?.rolePermission : [],
  };
  const handleSubmitRoleMaster = async (values) => {
    setLoader(true);
    if (!editId) {
      addRoleMaster(values).then((response) => {
        if (response?.status == 201) {
          navigate("/dashboard/role-master");
          setLoader(false);
        } else {
          setTimeout(() => {
            setLoader(false);
          }, 6000);
        }
      });
    } else {
      values.roleId = editId._id;
      editRoleMaster(values).then((response) => {
        if (response?.status == 200) {
          navigate("/dashboard/role-master");
          setLoader(false);
        } else {
          setTimeout(() => {
            setLoader(false);
          }, 6000);
        }
      });
    }
  };

  const modules = [
    {
      tag: "UserMaster",
      title: "User Master",
      isAll: "false",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "true",
    },
    {
      tag: "UserPost",
      title: "User Post",
      isAll: "false",
      isView: "true",
      isAdd: "false",
      isEdit: "false",
      isDelete: "false",
    },
    {
      tag: "UserReport",
      title: "User Report",
      isAll: "false",
      isView: "true",
      isAdd: "false",
      isEdit: "true",
      isDelete: "true",
    },
    {
      tag: "ReelsList",
      title: "Reels List",
      isAll: "false",
      isView: "true",
      isAdd: "false",
      isEdit: "false",
      isDelete: "false",
    },
    {
      tag: "RoleMaster",
      title: "Role Master",
      isAll: "false",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "true",
    },
    {
      tag: "AdminMaster",
      title: "Admin Master",
      isAll: "false",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "true",
    },
    {
      tag: "UserFeedbackList",
      title: "User Feedback List",
      isAll: "false",
      isView: "true",
      isAdd: "false",
      isEdit: "false",
      isDelete: "false",
    },
    {
      tag: "ContestList",
      title: "Contest List ",
      isAll: "false",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "true",
    },
    {
      tag: "ContestWinnerList",
      title: "Contest Winner Management",
      isAll: "false",
      isView: "true",
      isAdd: "false",
      isEdit: "true",
      isDelete: "false",
    },
    {
      tag: "StreakManagement",
      title: "Streak Management",
      isAll: "false",
      isView: "true",
      isAdd: "false",
      isEdit: "true",
      isDelete: "false",
    },
    {
      tag: "AddDiamondPrice",
      title: "Add Diamond Price",
      isAll: "false",
      isView: "true",
      isAdd: "false",
      isEdit: "false",
      isDelete: "false",
    },
    {
      tag: "DiamondTickUsersList",
      title: "Diamond Tick Users List",
      isAll: "false",
      isView: "true",
      isAdd: "false",
      isEdit: "false",
      isDelete: "false",
    },
    {
      tag: "CryptoList",
      title: "Crypto List ",
      isAll: "false",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "true",
    },
    {
      tag: "WithdrawalList",
      title: "Withdrawal List ",
      isAll: "false",
      isView: "true",
      isAdd: "false",
      isEdit: "true",
      isDelete: "false",
    },
    {
      tag: "ConversionList",
      title: "Conversion List ",
      isAll: "false",
      isView: "true",
      isAdd: "false",
      isEdit: "true",
      isDelete: "false",
    },
    {
      tag: "FiatWalletManagement",
      title: "Fiat Wallet Management ",
      isAll: "false",
      isView: "true",
      isAdd: "false",
      isEdit: "true",
      isDelete: "false",
    },
    {
      tag: "CryptoWalletManagement",
      title: "Crypto Wallet Management ",
      isAll: "false",
      isView: "true",
      isAdd: "false",
      isEdit: "true",
      isDelete: "false",
    },
    {
      tag: "PlatformFee",
      title: "Platform Fee",
      isAll: "false",
      isView: "true",
      isAdd: "false",
      isEdit: "true",
      isDelete: "false",
    },
    {
      tag: "TransactionsList",
      title: "Transactions List",
      isAll: "false",
      isView: "true",
      isAdd: "false",
      isEdit: "false",
      isDelete: "false",
    },
    {
      tag: "NotificationList",
      title: "Notification List",
      isAll: "false",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "false",
    },
    {
      tag: "ContactUsList",
      title: "Contact Us List ",
      isAll: "false",
      isView: "true",
      isAdd: "false",
      isEdit: "false",
      isDelete: "false",
    },
    {
      tag: "StickersList",
      title: "Stickers List ",
      isAll: "false",
      isView: "true",
      isAdd: "false",
      isEdit: "false",
      isDelete: "false",
    },
    {
      tag: "AdminSupport",
      title: "Admin Support ",
      isAll: "false",
      isView: "true",
      isAdd: "false",
      isEdit: "false",
      isDelete: "false",
    },
    {
      tag: "trendingList",
      title: "Trending List",
      isAll: "false",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "true",
    },
  ];

  return (
    <>
      <Index.Box className="dashboard-content">
        <PageIndex.BackButton
          onClick={() => navigate("/dashboard/role-master")}
        />
        <Index.Box className="barge-common-box">
          <Index.Box className="common-box">
            <Index.Box className="user-list-flex">
              <Index.Box className="admin-page-title-main">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  {editId ? "Update Role" : "Add Role"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <PageIndex.Formik
              onSubmit={handleSubmitRoleMaster}
              initialValues={initialValues}
              validationSchema={PageIndex.roleMasterSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  {console.log("values", values, errors)}
                  <Index.Box className="userlist-btn-flex cus-role-text-box">
                    <Index.Box className="input-box">
                      <Index.FormHelperText className="form-lable">
                        Role Name <span className="mandatory-asterisk">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          onBlur={handleBlur}
                          value={values.roleName}
                          // onChange={handleChange}
                          onChange={(e) => {
                            const inputValue = e.target.value
                              .replace(/^(?=.*[A-Z])/, "")
                              .replace(/^\s+/, "")
                              .replace(/\s\s+/g, " ");
                            setFieldValue("roleName", inputValue.slice(0, 30));
                          }}
                          name="roleName"
                          id="fullWidth"
                          className="form-control"
                          type="text"
                          autocomplete="off"
                          placeholder="Role Name"
                          error={Boolean(
                            errors?.roleName ? touched?.roleName : undefined
                          )}
                          helperText={
                            touched?.roleName ? errors?.roleName : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-dashboard-list-row">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        className="display-row-userlist"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-dash-box">
                            <Index.Box className="page-table-main role-master-add-table-main">
                              <Index.TableContainer
                                component={Index.Paper}
                                className="table-container"
                              >
                                <Index.Table
                                  sx={{ minWidth: 650 }}
                                  aria-label="simple table"
                                  className="table"
                                >
                                  <Index.TableHead className="table-head cus-table-head">
                                    <Index.TableRow className="table-row">
                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        align="center"
                                      >
                                        Module
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        align="center"
                                      >
                                        All
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        align="center"
                                      >
                                        View
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        align="center"
                                      >
                                        Add
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        align="center"
                                      >
                                        Edit
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        align="center"
                                      >
                                        Delete
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  </Index.TableHead>
                                  <Index.TableBody className="table-body">
                                    {modules.map((row) => (
                                      <Index.TableRow
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                        className="refferal-datarow-table"
                                        key={row?.tag}
                                      >
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="left"
                                        >
                                          {row?.title}
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="left"
                                        >
                                          <Index.Box className="email-refferal">
                                            <Index.Checkbox
                                              name="rolePermission"
                                              // disabled={
                                              //   row?.isView === "false" 
                                              // }
                                              checked={

                                                values?.rolePermission?.includes(row?.tag + "_view") &&
                                                (values?.rolePermission?.includes(row?.tag + "_edit") || row?.isEdit == "false") &&
                                                (values?.rolePermission?.includes(row?.tag + "_delete") || row?.isDelete == "false") &&
                                                (values?.rolePermission?.includes(row?.tag + "_add") || row?.isAdd == "false")

                                              }
                                              onChange={(e) => {
                                                const isChecked = e.target.checked;
                                                let newData = [...values?.rolePermission || []];

                                                if (isChecked) {

                                                  if (!newData.includes(row?.tag + "_view") && row?.isView !== "false") {
                                                    newData.push(row?.tag + "_view");
                                                  }


                                                  if (!newData.includes(row?.tag + "_edit") && row?.isEdit !== "false") {
                                                    newData.push(row?.tag + "_edit");
                                                  }
                                                  if (!newData.includes(row?.tag + "_delete") && row?.isDelete !== "false") {
                                                    newData.push(row?.tag + "_delete");
                                                  }
                                                  if (!newData.includes(row?.tag + "_add") && row?.isAdd !== "false") {
                                                    newData.push(row?.tag + "_add");
                                                  }
                                                } else {

                                                  newData = newData.filter(item => item !== row?.tag + "_view");


                                                  newData = newData.filter(item => item !== row?.tag + "_edit");
                                                  newData = newData.filter(item => item !== row?.tag + "_delete");
                                                  newData = newData.filter(item => item !== row?.tag + "_add");
                                                }

                                                setFieldValue("rolePermission", newData);
                                              }}
                                            />
                                          </Index.Box>

                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="left"
                                        >
                                          <Index.Box className="email-refferal">
                                            <Index.Checkbox
                                              name="rolePermission"
                                              // onChange={handleChange}
                                              // disabled={row?.isAdd === "false"}
                                              disabled={
                                                row?.isView === "false" ||
                                                values?.rolePermission?.includes(
                                                  row?.tag + "_edit"
                                                ) ||
                                                values?.rolePermission?.includes(
                                                  row?.tag + "_add"
                                                ) ||
                                                values?.rolePermission?.includes(
                                                  row?.tag + "_delete"
                                                )
                                              }
                                              checked={
                                                values?.rolePermission?.includes(
                                                  row?.tag + "_view"
                                                )
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) => {
                                                if (
                                                  values?.rolePermission?.includes(
                                                    row?.tag + "_view"
                                                  )
                                                ) {
                                                  const newData =
                                                    values?.rolePermission?.filter(
                                                      function (item) {
                                                        return (
                                                          item !==
                                                          row?.tag + "_view"
                                                        );
                                                      }
                                                    );
                                                  setFieldValue(
                                                    "rolePermission",
                                                    newData
                                                  );
                                                } else {
                                                  const newData = [
                                                    ...values?.rolePermission,
                                                  ];
                                                  newData.push(
                                                    row?.tag + "_view"
                                                  );
                                                  setFieldValue(
                                                    "rolePermission",
                                                    newData
                                                  );
                                                }
                                              }}
                                            />
                                          </Index.Box>
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                        >
                                          <Index.Box className="email-refferal">
                                            <Index.Checkbox
                                              name="rolePermission"
                                              disabled={row?.isAdd === "false"}
                                              checked={values?.rolePermission?.includes(
                                                row?.tag + "_add"
                                              )}
                                              onChange={(e) => {
                                                const checked =
                                                  e.target.checked;
                                                let newPermissions = [
                                                  ...values.rolePermission,
                                                ];
                                                if (checked) {
                                                  newPermissions.push(
                                                    row?.tag + "_add"
                                                  );
                                                  if (
                                                    !newPermissions.includes(
                                                      row?.tag + "_view"
                                                    )
                                                  ) {
                                                    newPermissions.push(
                                                      row?.tag + "_view"
                                                    );
                                                  }
                                                } else {
                                                  newPermissions =
                                                    newPermissions.filter(
                                                      (permission) =>
                                                        permission !==
                                                        row?.tag + "_add"
                                                    );
                                                  // Remove view permission only if delete permission is also not selected
                                                  if (
                                                    !newPermissions.includes(
                                                      row?.tag + "_add"
                                                    ) &&
                                                    !newPermissions.includes(
                                                      row?.tag + "_edit"
                                                    ) &&
                                                    !newPermissions.includes(
                                                      row?.tag + "_delete"
                                                    )
                                                  ) {
                                                    newPermissions =
                                                      newPermissions.filter(
                                                        (permission) =>
                                                          permission !==
                                                          row?.tag + "_view"
                                                      );

                                                  }
                                                }
                                                setFieldValue(
                                                  "rolePermission",
                                                  newPermissions
                                                );
                                              }}
                                            />
                                          </Index.Box>
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                        >
                                          <Index.Box className="email-refferal">
                                            <Index.Checkbox
                                              name="rolePermission"
                                              disabled={row?.isEdit === "false"}
                                              checked={values?.rolePermission?.includes(
                                                row?.tag + "_edit"
                                              )}
                                              onChange={(e) => {
                                                const checked =
                                                  e.target.checked;
                                                let newPermissions = [
                                                  ...values.rolePermission,
                                                ];
                                                if (checked) {
                                                  newPermissions.push(
                                                    row?.tag + "_edit"
                                                  );
                                                  if (
                                                    !newPermissions.includes(
                                                      row?.tag + "_view"
                                                    )
                                                  ) {
                                                    newPermissions.push(
                                                      row?.tag + "_view"
                                                    );
                                                  }
                                                } else {
                                                  newPermissions =
                                                    newPermissions.filter(
                                                      (permission) =>
                                                        permission !==
                                                        row?.tag + "_edit"
                                                    );
                                                  // Remove view permission only if delete permission is also not selected
                                                  if (
                                                    !newPermissions.includes(
                                                      row?.tag + "_add"
                                                    ) &&
                                                    !newPermissions.includes(
                                                      row?.tag + "_edit"
                                                    ) &&
                                                    !newPermissions.includes(
                                                      row?.tag + "_delete"
                                                    )
                                                  ) {
                                                    newPermissions =
                                                      newPermissions.filter(
                                                        (permission) =>
                                                          permission !==
                                                          row?.tag + "_view"
                                                      );
                                                  }
                                                }
                                                setFieldValue(
                                                  "rolePermission",
                                                  newPermissions
                                                );
                                              }}
                                            />
                                          </Index.Box>
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                        >
                                          <Index.Box className="email-refferal">
                                            <Index.Checkbox
                                              name="rolePermission"
                                              disabled={
                                                row?.isDelete === "false"
                                              }
                                              checked={values?.rolePermission?.includes(
                                                row?.tag + "_delete"
                                              )}
                                              onChange={(e) => {
                                                const checked =
                                                  e.target.checked;
                                                let newPermissions = [
                                                  ...values.rolePermission,
                                                ];
                                                if (checked) {
                                                  newPermissions.push(
                                                    row?.tag + "_delete"
                                                  );
                                                  if (
                                                    !newPermissions.includes(
                                                      row?.tag + "_view"
                                                    )
                                                  ) {
                                                    newPermissions.push(
                                                      row?.tag + "_view"
                                                    );
                                                  }
                                                } else {
                                                  newPermissions =
                                                    newPermissions.filter(
                                                      (permission) =>
                                                        permission !==
                                                        row?.tag + "_delete"
                                                    );
                                                  // Remove view permission only if edit permission is also not selected
                                                  if (
                                                    !newPermissions.includes(
                                                      row?.tag + "_add"
                                                    ) &&
                                                    !newPermissions.includes(
                                                      row?.tag + "_edit"
                                                    ) &&
                                                    !newPermissions.includes(
                                                      row?.tag + "_delete"
                                                    )
                                                  ) {
                                                    newPermissions =
                                                      newPermissions.filter(
                                                        (permission) =>
                                                          permission !==
                                                          row?.tag + "_view"
                                                      );
                                                  }
                                                }
                                                setFieldValue(
                                                  "rolePermission",
                                                  newPermissions
                                                );
                                              }}
                                            />
                                          </Index.Box>
                                        </Index.TableCell>
                                      </Index.TableRow>
                                    ))}

                                  </Index.TableBody>
                                </Index.Table>
                              </Index.TableContainer>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                     
                      </Index.Box>

                  
                    </Index.Box>
                    <Index.Box className='role-permission-error-box'>

                    {errors.rolePermission && touched.rolePermission && (
                    <p className="error-text">
                      {errors.rolePermission}
                    </p>
                  )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="adduser-btn-main btn-main-primary cus-button-box">
                    
                    <Index.Button
                      className="adduser-btn btn-primary"
                      type="submit"
                      disabled={loader}
                    >
                      {loader ? (
                        <PageIndex.ButtonLoader
                          color="white"
                          size={14}
                          loading={loader}
                        />
                      ) : (
                        <>
                          <img
                            src={Index.Svg.plus}
                            className="plus-icon"
                            alt="plus icon"
                          />
                          {editId ? "Update Role" : "Add Role"}
                        </>
                      )}
                    </Index.Button>
                  </Index.Box>
                </form>
              )}
            </PageIndex.Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default RoleMasterEdit;
