import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import {
  contestAnalysisAction,
  getAllContest,
} from "../../../../redux/slices/adminService";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { Pie } from "react-chartjs-2";
import moment from "moment";
function createData(name, joinedDate, engagementRatio, successRate) {
  return { name, joinedDate, engagementRatio, successRate };
}

const ContestAnalyticsView = () => {
  const navigate = PageIndex.useNavigate();
  const [contestParticipantList, setContestParticipantList] = useState();
  const [loader, setLoader] = useState(true);

  const [chartData, setChartData] = useState();

  const [pageData, setPageData] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState([]);

  const navigateData = PageIndex.useLocation();
  const editData = navigateData?.state?.editData;

  const getContestAnalysis = () => {
    contestAnalysisAction({ id: editData?._id }).then((res) => {
      if (res?.status === 200) {
        setLoader(false);
        setContestParticipantList(res?.data[0]);
        setChartData({
          labels: ["Joined Participants", "Remaining Slots"],
          datasets: [
            {
              data: [
                res?.data[0]?.spotLength,
                res?.data[0]?.spotNumber - res?.data[0]?.spotLength,
              ],
              backgroundColor: [
                "rgba(255, 99, 132, 0.6)",
                "rgba(54, 162, 235, 0.6)",
              ],
              borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
              borderWidth: 1,
            },
          ],
        });
      } else {
        setContestParticipantList([]);
      }
    });
  };

  function convertTo12HourFormat(time24) {
    // Split the time string into hours, minutes, and seconds
    const [hour, minute, second] = time24.split(":");

    // Convert hour to an integer and determine AM or PM
    let hour12 = parseInt(hour, 10);
    const ampm = hour12 >= 12 ? "PM" : "AM";

    // Adjust hours for 12-hour format
    hour12 = hour12 % 12 || 12; // Converts '0' or '12' to '12'

    // Return formatted string in 12-hour format
    return `${hour12}:${minute}:${second} ${ampm}`;
  }

  useEffect(() => {
    getContestAnalysis();
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    const filterData = contestParticipantList?.sortedCountsByUser?.filter(
      (item) => {
        return search
          ? item?.userName?.toLowerCase().includes(search.toLowerCase()) ||
              new Date(item?.joinedDate)
                ?.toLocaleDateString("en-GB")
                ?.toString()
                ?.includes(search?.toLowerCase())
          : item;
      }
    );
    setFilterData(filterData);
  }, [search, contestParticipantList]);
  return (
    <>
      <Index.Box className="dashboard-content">
        <PageIndex.BackButton
          onClick={() =>
            navigate("/dashboard/sub-contest-list", {
              state: navigateData?.state?.contestId?.state,
            })
          }
        />
        <Index.Box className="barge-common-box">
          <Index.Box className="common-box">
            <Index.Box className="user-list-flex">
              <Index.Box className="admin-page-title-main">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  Analysis of{" "}
                  {editData?.contestName ? editData?.contestName : "_____"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box className="pie-chart">
              <>
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="display-row-add-user"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 4",
                        md: "span 4",
                        lg: "span 4",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="pie-chart">
                        <p>
                          Maximum Participants:{" "}
                          {contestParticipantList?.spotNumber}
                        </p>
                        {chartData && <Pie data={chartData} />}
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 8",
                        md: "span 8",
                        lg: "span 8",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="content-search-list">
                        <Index.Box className="userlist-btn-flex">
                          <Index.Box className="user-search-main">
                            <Index.Box className="user-search-box">
                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control"
                                  placeholder="Search user"
                                  onChange={handleSearch}
                                />
                                <span className="search-icon-box">
                                  <img
                                    src={Index.Svg.search}
                                    className="search-icon"
                                  />
                                </span>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box>
                        <Index.Box className="admin-dashboard-list-row">
                          <Index.Box sx={{ width: 1 }} className="grid-main">
                            <Index.Box
                              display="grid"
                              className="display-row-userlist"
                              gridTemplateColumns="repeat(12, 1fr)"
                              gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                            >
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 12",
                                  md: "span 12",
                                  lg: "span 12",
                                }}
                                className="grid-column"
                              >
                                <Index.Box className="admin-dash-box">
                                  <Index.Box className="contest-analysis-table-main page-table-main">
                                    <Index.TableContainer
                                      component={Index.Paper}
                                      className="table-container"
                                    >
                                      <Index.Table
                                        sx={{ minWidth: 650 }}
                                        aria-label="simple table"
                                        className="table"
                                      >
                                        <Index.TableHead className="table-head cus-table-head">
                                          <Index.TableRow className="table-row">
                                            <Index.TableCell
                                              component="th"
                                              variant="th"
                                              className="table-th"
                                              align="center"
                                            >
                                              S.No
                                            </Index.TableCell>
                                            <Index.TableCell
                                              component="th"
                                              variant="th"
                                              className="table-th"
                                              align="center"
                                            >
                                              Username
                                            </Index.TableCell>
                                            <Index.TableCell
                                              component="th"
                                              variant="th"
                                              className="table-th"
                                              align="center"
                                            >
                                              Joined Date{" "}
                                            </Index.TableCell>
                                            <Index.TableCell
                                              component="th"
                                              variant="th"
                                              className="table-th"
                                              align="center"
                                            >
                                              Joined Time{" "}
                                            </Index.TableCell>
                                            <Index.TableCell
                                              component="th"
                                              variant="th"
                                              className="table-th"
                                              align="center"
                                            >
                                              Engagement Ratio
                                            </Index.TableCell>
                                          </Index.TableRow>
                                        </Index.TableHead>
                                        <Index.TableBody className="table-body">
                                          {!loader ? (
                                            pageData?.length > 0 ? (
                                              pageData?.map((row, index) => (
                                                <>
                                                  <Index.TableRow
                                                    sx={{
                                                      "&:last-child td, &:last-child th":
                                                        {
                                                          border: 0,
                                                        },
                                                    }}
                                                    key={row._id}
                                                  >
                                                    <Index.TableCell
                                                      component="td"
                                                      variant="td"
                                                      scope="row"
                                                      className="table-td"
                                                      align="center"
                                                    >
                                                      {row?.sNo}
                                                    </Index.TableCell>
                                                    <Index.TableCell
                                                      component="td"
                                                      variant="td"
                                                      scope="row"
                                                      className="table-td"
                                                      align="center"
                                                    >
                                                      {row?.userName
                                                        ? row?.userName
                                                        : "-"}
                                                    </Index.TableCell>
                                                    <Index.TableCell
                                                      component="td"
                                                      variant="td"
                                                      scope="row"
                                                      className="table-td"
                                                      align="center"
                                                    >
                                                      {row?.joinedDate
                                                        ? Index.moment(
                                                            row?.joinedDate
                                                          ).format("DD/MM/YYYY")
                                                        : "-"}
                                                    </Index.TableCell>
                                                    <Index.TableCell
                                                      component="td"
                                                      variant="td"
                                                      scope="row"
                                                      className="table-td"
                                                      align="center"
                                                    >
                                                      {convertTo12HourFormat(
                                                        row?.joinedTime
                                                          ? row?.joinedTime
                                                          : ""
                                                      )}
                                                    </Index.TableCell>
                                                    <Index.TableCell
                                                      component="td"
                                                      variant="td"
                                                      scope="row"
                                                      className="table-td"
                                                      align="center"
                                                    >
                                                      {row?.engagementRatio
                                                        ? row?.engagementRatio
                                                        : "-"}
                                                    </Index.TableCell>
                                                  </Index.TableRow>
                                                </>
                                              ))
                                            ) : (
                                              <PageIndex.RecordNotFound
                                                colSpan={7}
                                              />
                                            )
                                          ) : (
                                            <PageIndex.TableLoader
                                              colSpan={7}
                                            />
                                          )}
                                        </Index.TableBody>
                                      </Index.Table>
                                    </Index.TableContainer>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="pagination-main">
                          <PageIndex.Pagination
                            fetchData={filterData}
                            setPageData={setPageData}
                            pageData={pageData}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default ContestAnalyticsView;
