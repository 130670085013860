import React, { useState } from "react";
import PageIndex from "../../../PageIndex";
import Index from "../../../Index";
import dayjs from "dayjs";
import { addEditStreakNotification } from "../../../../redux/slices/adminService";
import { MobileTimePicker } from "@mui/x-date-pickers";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export const NotificationModal = ({
  handleClose,
  addEditData,
  getNotification,
}) => {

  const [loader, setLoader] = useState(false);
  const [switchDisable, setSwitchDisable] = useState(false)
  
  function convertTimeToDateString(timeString) {
    const currentDate = new Date();
    const [hours, minutes, seconds] = timeString?.split(":");
    currentDate?.setHours(hours);
    currentDate?.setMinutes(minutes);
    currentDate?.setSeconds(seconds);
    const dateString =currentDate?.toDateString() + " " + currentDate?.toTimeString();
    return dateString;
  }

  function extractTimeFromDate(dateString) {
    const dateObject = new Date(dateString);
    const time = dateObject.toLocaleTimeString("en-IN", { hour12: false });
    return time;
  }

  const initialValues = {
    hours: addEditData
      ? convertTimeToDateString(addEditData?.hours || "00:00")
      : "",
    message: addEditData ? addEditData.message : "",
  };
  const handleSubmit = (values) => {
    setLoader(true);
    var urlencoded = new URLSearchParams();
    urlencoded.append("hours", extractTimeFromDate(values.hours));
    urlencoded.append("message", values.message);

    addEditStreakNotification(urlencoded)
      .then((res) => {
        getNotification();
        setLoader(false);
        handleClose();
      })
      .catch((err) => {
        setTimeout(() => {
          setLoader(false);
        }, 3000);
      });
  };

  const disableStreak = (values, data) => {
    setSwitchDisable(true);
    var urlencoded = new URLSearchParams();
    urlencoded.append("hours", extractTimeFromDate(values.hours));
    urlencoded.append("message", values.message);
    urlencoded.append("isSendNotification", data);
    addEditStreakNotification(urlencoded)
      .then((res) => {
        getNotification();
        setSwitchDisable(false);
        handleClose();
      })
      .catch((err) => {
        setTimeout(() => {
          setSwitchDisable(false);
        }, 3000);
      });
  };

  return (
    <>
      <Index.Box sx={style} className="delete-modal-inner-main modal-inner ">
        <Index.Box className="modal-circle-main cus-modal-margin">
          <Index.IconButton onClick={handleClose}>
            <img src={Index.Png.close} className="user-circle-img" />
          </Index.IconButton>
        </Index.Box>
        <PageIndex.Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={PageIndex.streakNotificationSchema}
        >
          {({
            values,
            handleBlur,
            errors,
            touched,
            setFieldValue,
          }) => (
            <PageIndex.Form>
              <Index.Typography
                className="delete-modal-title setting-modal"
                component="h2"
                variant="h2"
              >
                Streak Notification
              </Index.Typography>
              <Index.Box className="table-view-toggle-btn streak-toggle">
                <Index.FormControlLabel
                  control={
                    <Index.IOSSwitch
                      disabled={switchDisable}
                      checked={addEditData?.isSendNotification || false}
                      sx={{ m: 1 }}
                      className="MuiSwitch-track-active"
                      onClick={(event) => {
                        disableStreak(values, event.target.checked);
                      }}
                    />
                  }
                  className="switch-lable"
                />
              </Index.Box>
              <Index.Box className="input-box add-user-input">
                <Index.FormHelperText className="form-lable">
                  Message<span className="mandatory-asterisk">*</span>
                </Index.FormHelperText>

                <Index.Box className="form-group textarea-container">
                  <Index.TextareaAutosizeed
                    fullWidth
                    id="fullWidth"
                    className="form-control text-autosize"
                    placeholder="Message"
                    value={values.message}
                    name="message"
                    onChange={(e) => {
                      const newValue = e.target.value
                        .replace(/^\s+/, "")
                        .replace(/\s\s+/g, " ");
                      if (newValue.length <= 100) {
                        setFieldValue("message", newValue);
                      }

                    }}
                    onBlur={handleBlur}
                  />
                  {errors?.message && touched?.message && (
                    <p className="custom-error-tag">{errors?.message}</p>
                  )}
                </Index.Box>
              </Index.Box>
              <Index.Box className="input-box add-user-input">
                <Index.FormHelperText className="form-lable">
                  Time<span className="mandatory-asterisk">*</span>
                </Index.FormHelperText>
                <Index.Box className="form-group custom-datepicker">
                  <Index.LocalizationProvider
                    dateAdapter={Index.AdapterDayjs}
                    className="date-adapter-main"
                  >
                    <Index.DemoContainer
                      components={["DatePicker", "DatePicker"]}
                      className="date-adapter-container"
                    >
                      <MobileTimePicker
                        className="date-adapter-picker"
                        name="hours"
                        value={dayjs(values?.hours)}
                        onChange={(selectedTime) => {
                          const newStartTime = selectedTime.$d;
                          setFieldValue("hours", newStartTime);
                        }}
                        // disablePast
                        ampm={false}
                        format="HH:mm"
                        onBlur={handleBlur}
                        disablePast
                      />
                      {values.hours != "" ? (
                        <Index.IconButton
                          className="streak-dateclear-icon"
                          onClick={() => setFieldValue("hours", "")}
                        >
                          <Index.ClearIcon className="icon-clear" />
                        </Index.IconButton>
                      ) : (
                        ""
                      )}
                    </Index.DemoContainer>
                  </Index.LocalizationProvider>
                  {errors.hours && touched.hours && (
                    <p className="error-text">{errors.hours}</p>
                  )}
                </Index.Box>
              </Index.Box>
              <Index.Box className="delete-modal-btn-flex">
                <Index.Button
                  className="modal-cancel-btn modal-btn"
                  onClick={handleClose}
                >
                  Cancel
                </Index.Button>

                <Index.Button
                  type="submit"
                  className="modal-delete-btn modal-btn"
                  disabled={loader}
                >
                  Submit
                </Index.Button>
              </Index.Box>

            </PageIndex.Form>
          )}
        </PageIndex.Formik>
      </Index.Box>
    </>
  );
};
