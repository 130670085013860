
import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {getPurchasedDiamondByUserList,} from "../../../../redux/slices/adminService";
import PageIndex from "../../../PageIndex";
import moment from "moment/moment";
import { debounce } from "lodash";
import numeral from "numeral";

export default function DiamondTickUserList() {
  const location=PageIndex.useLocation()
  const navigate=PageIndex.useNavigate()
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [loader, setLoader] = useState(true);
  const [diamondTickUsersList, setDiamondTickUsersList] = useState([]);





  const fetchDiamondTickusersList = () => {
    setLoader(true);
    getPurchasedDiamondByUserList({ currentPage, search }).then((data) => {
      if (data?.status == 200) {
        setDiamondTickUsersList(data?.data);
        if (data?.currentPage) {
          setCurrentPage(data?.currentPage);
        }
        setPageCount(data?.totalPages);
        if (data?.currentPage) {
          setCurrentPage(data?.currentPage);
        }
        setPageCount(data?.totalPages);
        setLoader(false);
      } else {
        setDiamondTickUsersList([]);
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      }
    });
  };

  useEffect(() => {
    const debouncedFetch = debounce(fetchDiamondTickusersList, 500);

    if (search || currentPage) {
      debouncedFetch();
    } else {
      fetchDiamondTickusersList();
    }

    return () => {
      debouncedFetch.cancel();
    };
  }, [search, currentPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="barge-common-box">
          <Index.Box className="user-list-flex">
            <Index.Box className="admin-page-title-main">
              <Index.Typography
                className="admin-page-title"
                component="h2"
                variant="h2"
              >
                Diamond Tick Users List
              </Index.Typography>
            </Index.Box>
            <Index.Box className="userlist-btn-flex">
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box">
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth_123"
                      type="text"
                      value={search}
                      autoComplete="search"
                      className="form-control"
                      placeholder="Search"
                      onChange={(e) => {
                        const newValue = e.target.value
                          .replace(/^\s+/, "")
                          .replace(/\s\s+/g, " ");
                        setSearch(newValue);
                        setCurrentPage(1);
                        // getCryptoList(newValue);
                      }}
                    />
                    <span className="search-icon-box">
                      <img src={Index.Svg.search} className="search-icon" />
                    </span>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row-userlist"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dash-box">
                    <Index.Box className="cryptolist-table-main action-column page-table-main">
                      {/* <Paper sx={{ width: '100%', overflow: 'hidden' }}> */}

                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                        // sx={{ maxHeight:400 }}
                      >
                        <Index.Table
                          stickyHeader
                          aria-label="sticky table"
                          sx={{ minWidth: 650 }}
                          className="table"
                        >
                          <Index.TableHead className="table-head cus-table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                S.No
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Username
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Subscripton Plan
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                              Currency Type
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Subscription Price
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Purchased Date & Time
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Validity Date 
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {!loader ? (
                              diamondTickUsersList?.length > 0 ? (
                                diamondTickUsersList?.map((row, index) => (
                                  <Index.TableRow
                                    key={row._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {(currentPage - 1) * 10 + index + 1}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                     
                                  >
                                      <span className=" cus-user-name-navigate"
                                       onClick={() => {
                                        navigate('/dashboard/user-view',
                                            {
                                                state: {
                                                    data: row?.userId?._id,
                                                    pathName: location.pathname
                                                },
                                            }
                                        )
                                    }}
                                      
                                      >{row?.userId?.userName
                                        ? row?.userId?.userName
                                        : "-"}</span>
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.planType ? row?.planType : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.userCurrencyType ? row?.userCurrencyType : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {/* {row?.planAmount ? row?.planAmount : "-"} */}
                                      {row?.planAmount
                                          ? Number.isInteger(row?.planAmount)
                                            ? numeral(row?.planAmount).format('0')
                                            : numeral(row?.planAmount).format('0.000a')
                                          : 0}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.createdAt ? Index.moment(row?.createdAt).format("DD/MM/YYYY HH:MM ") : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.validityDate ?  row?.validityDate :"-"}
                                    </Index.TableCell>

                                  </Index.TableRow>
                                ))
                              ) : (
                                <PageIndex.RecordNotFound colSpan={7} />
                              )
                            ) : (
                              <PageIndex.TableLoader colSpan={7} />
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                      {/* </Paper> */}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {diamondTickUsersList.length ? (
            <Index.Box className="pagination-main">
              <Index.Pagination
                count={pageCount}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
                className="pagination"
              />
            </Index.Box>
          ) : (
            ""
          )}
        </Index.Box>
      </Index.Box>

      {/* Delete Modal */}
    </>
  );
}
