import React from "react";
import Index from "../../../../Index";
import Chart from "react-apexcharts";

export default function FollowersGrowth({
  contestData,
  contestName,
  setContestName,
  contestList,
}) {


  const likesCount = Math.max(contestData.likesCount, 0);
  const viewsCount = Math.max(contestData.viewsCount, 0);
  const commentsCount = Math.max(contestData.commentsCount, 0);
  const sharedCount = Math.max(contestData.sharedCount, 0);

  const maxCount = Math.max(likesCount, viewsCount, commentsCount, sharedCount);
  const maxYAxis = maxCount === 0 ? 1 : maxCount + 1;
  const chartData = {
    options: {
      chart: {
        id: "basic-bar",
      },
      chart: {
        stacked: true
      },
      xaxis: {
        categories: ["Likes", "Views", "Comments", "Shares"],
      },
      yaxis: {
        min: 0,
        max: maxYAxis,
        tickAmount: Math.ceil(maxYAxis),
        labels: {
          formatter: function (val) {
            return Math.floor(val);
          }
        }
      },
      tooltip: {
        intersect: false,
      },
    },
    series: [
      {
        name: "Counts",
        data: [likesCount, viewsCount, commentsCount, sharedCount],
      },
    ],
  };


  return (
    <>
      {contestList?.length > 0 ? (
        <Index.Box className="admin-dashboard-list-row follower-graph">
          <Index.Box className="contest-select-flex">
            <Index.Box className="input-box add-user-input">
              <Index.FormHelperText className="form-lable">
                Joined contest
              </Index.FormHelperText>
              <Index.Box className="form-group">
                <Index.FormControl className="radio-main">
                  <Index.Select
                    className="form-control select-drop-list"
                    name="category"
                    value={contestName ? contestName : contestList?.[0]?._id}
                    onChange={(e) => {
                      setContestName(e.target.value);
                    }}
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                  >

                    {contestList &&
                      contestList?.map((val) => (
                        <Index.MenuItem
                          value={val?._id}
                          key={val?.id}
                          className="menuitem"
                        >
                          {val?.contestName}
                        </Index.MenuItem>
                      ))}
                  </Index.Select>
                </Index.FormControl>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row-userlist"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Chart
                  options={chartData.options}
                  series={chartData.series}
                  type="bar"
                  height={350}
                  className='contest-chart'
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      ) : (
        <>
          <Chart
            options={{}}
            series={[]}
            type="bar"
            height={350}
            className='contest-chart'
          />
          <Index.Box className="user-activity-norecord-main">
            <Index.Typography className="user-activity-norecord">
              {" "}
              No Record Found
            </Index.Typography>
          </Index.Box>
        </>
      )}
    </>
  );
}
