import { Form, Formik } from "formik";
import LoadingOverlay from "react-loading-overlay";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../component/common/Pagination";
import { ToastContainer, toast } from "react-toastify";
import Svg from "../assets/Svg";
import Png from "../assets/Png";
import DeleteModal from "../component/common/DeleteModal";
import Jpg from "../assets/Jpg"
import { termsConditionSchema,privacyPolicySchema,cmsContactUsSchema,cmsAboutUsSchema,
  adminForgotPasswordSchema,adminSendOtpSchema,resetPasswordSchema,addUserSchema,editUserMasterSchema
  ,addSubAdminSchema,diamondPriceSchema,adminLoginSchema,
  addNotificationSchema,
  addPlatformFeeSchema,
  streakNotificationSchema,
  addCryptoSchema,
  ChangePasswordSchemaProfile,
  helpCenterSchema,
  removeDiamondTickSchema,
  roleMasterSchema,
  EditProfileSchema,
  addEditStickersSchema,
  cmsAdminSupport,
  diamondTick,} from "../validation/Validation";
import ButtonLoader from "../component/common/ButtonLoader";
import BackButton from "../component/common/BackButton";
import RecordNotFound from "../component/common/RecordNotFound";
import Loading from "../component/common/Loading";
import TableLoader from "../component/common/TableLoader";
import CancelModel from "../component/common/CancelModel";
import { LightTooltip } from "../component/common/Tooltip";
import LinearProgresBar from "../component/common/LinearProgres";
import AddModel from "../component/common/AddModel";
import DeclineModel from "../component/common/DeclineModel";
import CommonTable from "../component/common/CommonTable";
import SuspendModal from "../component/common/SuspendUser";
import { DiamondTickModel } from "../component/common/DiamondTick";
import AddStickerModel from "../component/common/AddStickerModel";
import ImageViewModel from "../component/common/ImageViewModel";
import ViewTransactionDetailsModel from "../component/common/ViewTransactionDetailsModel";


const PageIndex = {
  Formik,
  LoadingOverlay,
  useDispatch,
  useSelector,
  useLocation,
  useNavigate,
  Pagination,
  DeleteModal,
  toast,
  ToastContainer,
  Svg,
  Png,
  Jpg,
  Form,




  // common component
  ButtonLoader,
  RecordNotFound,
  Loading,
  TableLoader,
  CancelModel,
  LightTooltip,
  LinearProgresBar,
  AddModel,
  DeclineModel,
  SuspendModal,
  DiamondTickModel,
  BackButton,
  // validation schema
  termsConditionSchema,
  privacyPolicySchema,
  cmsContactUsSchema,
  cmsAboutUsSchema,
  adminForgotPasswordSchema,
 adminSendOtpSchema,
 resetPasswordSchema,
 addUserSchema,
 editUserMasterSchema,
 addSubAdminSchema,
 roleMasterSchema,
addNotificationSchema,
addEditStickersSchema,
diamondPriceSchema,
adminLoginSchema,
addPlatformFeeSchema,
addCryptoSchema,
streakNotificationSchema,
helpCenterSchema,
diamondTick,
removeDiamondTickSchema,
ChangePasswordSchemaProfile,
EditProfileSchema,
CommonTable,
AddStickerModel,
ImageViewModel,
ViewTransactionDetailsModel,
cmsAdminSupport
};
export default PageIndex;
