import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { loginAdmin, updateProfile } from "./adminService";

export const AdminSlice = createSlice({
  name: "counter",
  initialState: {
    token: "",
    loading: false,
    isAuthenticated: false,
    adminProfile: {},
    adminData: {},
    isOTP: false,
    isForgot: false,
    isReset: false,
  },
  reducers: {
    isResetAll: (state, action) => {
      console.log(action.payload,7 );
      state.isForgot = action.payload;
      state.isReset = action.payload;
      state.isOTP = action.payload;
    },

    isResetPassword: (state, action) => {
      state.isReset = action.payload;
    },
    isVerifyOTP: (state, action) => {
      state.isOTP = action.payload;
    },
    isForgot: (state, action) => {
      state.isForgot = action.payload;
      
    },
    updateAdmin: (state, action) => {
      console.log(action, 22);
      state.adminData = action.payload;
      state.adminProfile = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.adminData = {};
      state.adminProfile = {};
      state.token = "";
    },
    updateRolePermission:(state, action)=>{
      console.log(action,'action');
      
      state.rolePermission = action?.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loginAdmin.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      state.adminData = action?.payload?.data;
      state.adminProfile = action?.payload?.data;
      state.token = action?.payload?.data?.token;
      state.rolePermission = action?.payload?.data;
    });

    // builder.addCase(updateProfile.fulfilled, (state, action) => {
    //   state.adminData = action.payload?.data;
    //   state.adminProfile = action.payload?.data;
    // });
  },
});
export const {
  logout,
  updateAdmin,
  isResetPassword,
  isVerifyOTP,
  isForgot,
  isResetAll,
  updateRolePermission
} = AdminSlice.actions;
export default AdminSlice.reducer;
