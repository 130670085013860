const USER = "user";
const ADMIN = "admin";
const COMMON="common"
const Api = {
  Auth: {
    login: `${ADMIN}/admin-login`,
    forgotPassword: `${ADMIN}/forget-password`,
    otpVerify: `${ADMIN}/verify-Otp`,
    resetPassword: `${ADMIN}/reset-password`,
    changePassword: `${ADMIN}/change-password`,
    updateProfile: `${ADMIN}/update-profile`,
    resendOtp:`${ADMIN}/resent-otp-for-admin`,
  },
  User: {
    addUser: `${USER}/admin-create-new-user`,
    editUser: `${ADMIN}/edit-user-by-admin`,
    deleteUser: `${USER}/delete-user`,
    getAllUser: `${USER}/get-all-users`,
    userActivity:`${ADMIN}/get-userActivity`,
    get_device_history:`${ADMIN}/get-userDeviceDetails`,
  },
  CMS: {
    get_CMS_Details: `${ADMIN}/get-CMS-detail`,
    create_Privacy_Policy: `${ADMIN}/add-edit-privacy-policy`,
    create_Terms_Condition: `${ADMIN}/add-edit-terms-and-condition`,
    createContactUs: `${ADMIN}/add-edit-contactUs`,
    Edit_About_Us: `${ADMIN}/add-edit-about-us`,
    Get_Help_center:`${COMMON}/get-helpCenter`,
    Add_edit_Help_center:`${ADMIN}/add-edit-helpCenter`,
    adminSupport:`${ADMIN}/add-update-support`,
    getAdminSupport:`${ADMIN}/get-support-data`,
  },
Pages:{
  Remove_winner_popularity:`${ADMIN}/delete-checkbox`,
  Announce_winner_popularity:`${ADMIN}/declare-winnerInPopulare`,
  Popularity_winner_declare:`${ADMIN}/save-checkbox`,
  Popularity_winner_List_declare:`${ADMIN}/get-allCheckboxForSingleContest`,
  Get_roleMasterList: `${ADMIN}/get-all-role`,
  Get_allposts_listbyadmin: `${ADMIN}/get-allposts-listbyadmin`,
  Get_singlepost_listbyadmin: `${ADMIN}/get-singlepost-byadmin`,
  Get_singlereel_byadmin: `${ADMIN}/getsingle-reel-byadmin`,
  RoleMaster_delete:  `${ADMIN}/delete-role`,
  RoleMaster_add:`${ADMIN}/add-role`,
  RoleMaster_edit: `${ADMIN}/edit-role`,
  Get_all_sub_admin : `${ADMIN}/get-all-sub-admin`,
  Sub_admin_delete : `${ADMIN}/admin-delete-sub-admin`,
  Edit_sub_admin : `${ADMIN}/admin-update-sub-admin`,
  Add_sub_admin : `${ADMIN}/admin-add-sub-admin`,
  Get_allreels_listbyadmin:`${ADMIN}/getall-reellist-byadmin`,
  Contact_Us_List : `${ADMIN}/get-all-contactus-list-byadmin`,
  Get_single_user_by_admin : `${ADMIN}/get-single-user-by-admin`,
 Get_users_feedback_list:`${ADMIN}/get-all-feedback-by-admin`,
 Get_user_feedback:`${ADMIN}/get-user-feedback-by-admin`,
  get_today_report:`${ADMIN}/get-today-dashboard-data`,
get_overView_report:`${ADMIN}/get-dashboard-data`,
Add_Edit_Notification:`${ADMIN}/add-edit-notification`,
Get_All_Notification:`${ADMIN}/get-all-admin-notification`,
Send_Notification:`${ADMIN}/send-notification-to-user-by-admin`,
Get_All_Reports_Admin: `${ADMIN}/get-all-reports-byadmin`,
Get_Single_Reports_Admin: `${ADMIN}/get-reports`,
Delete_Profile_report: `${USER}/delete-user`,
Delete_Post_report:`${ADMIN}/deletePost`,
Delete_reel_report:`${ADMIN}/delete-reel-byadmin`,
Delete_Comment_report: `${ADMIN}/deleteComment`,
suspend_user_byadmin: `${ADMIN}/suspend-user-byadmin`,

user_joied_contest:`${ADMIN}/userContest`,
Send_Post_Email:`${ADMIN}/send-warningMail`,
// Send_Reel_Email:`${ADMIN}/send-warningMail`,
Send_Comment_Email:`${ADMIN}/send-warningMail`,
Send_Profile_Email:`${ADMIN}/send-warningMail`,
contest_Participant_list:`${ADMIN}/get-allContest`,
search_contest_Participant:`${ADMIN}/search-Contest`,

// diamond
add_diamond_price:`${ADMIN}/update-diamond-tick-price`,
get_diamond_price:`${ADMIN}/get-diamond-price-data`,
get_purchased_diamond_by_user:`${ADMIN}/get-purchased-diamond-by-user`,
provide_diamond_tick_byadmin:`${ADMIN}/provide-diamond-tick-byadmin`,
remove_diamond_tick:`${ADMIN}/delete-diamond-tick-by-admin`,
Contest_winnerList:`${ADMIN}/get-allCheckbox`,

Get_All_Contest:`${ADMIN}/get-allContest`,
search_sub_contest:`${ADMIN}/search-SubContest`,
contest_disable:`${ADMIN}/stop-contest`,
Get_Sub_Contest:`${ADMIN}/get-allSubContestOfMainContest`,
Add_Edit_Contest: `${ADMIN}/add-editContest`,
delete_contest:`${ADMIN}/delete-contest`,
cancel_contest:`${ADMIN}/cancel-contest`,
complete_contest:`${ADMIN}/complete-contest`,
contest_analysis:`${ADMIN}/get-contest-analysis`,
suffle_contest_participant:`${ADMIN}/suffle-contest`,
get_single_contest:`${ADMIN}/get-singleContest`,
get_user_activity_for_graph:`${ADMIN}/get-user-activity-for-graph`,
get_user_contest_activity_log:`${ADMIN}/get-user-contest-activity-log`,
get_all_contest_for_graph:`${ADMIN}/get-all-contest-for-graph`,
random_winner_declare:`${ADMIN}/declare-winner`,

random_add_winner:`${ADMIN}/declare-winnerInRandom`,
get_popular_contest_participant:`${ADMIN}/get-singlePopulareContest`,

// crypto list
get_crypto_name_list:"common/get-crypto-name-list",
delete_crypto_name:`${ADMIN}/delete-crypto-name`,
add_edit_crypto_name:`${ADMIN}/add-edit-crypto-name`,
get_live_cryptoList:'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd',

get_platform_fee:`${COMMON}/get-platform-fee`,
add_update_platform_fee:`${ADMIN}/add-update-platform-fee`,
admin_get_sub_admin:`${ADMIN}/admin-get-sub-admin`,
active_deactive_crypto:`${ADMIN}/active-deactive-crypto`,
withdraw_crypto_request_list:`${ADMIN}/withdraw-crypto-request-list`,
update_withdraw_request:`${ADMIN}/update-withdraw-request`,
update_fiat_request:`${ADMIN}/update-fiat-request`,
update_crypto_request:`${ADMIN}/update-crypto-request`,


get_fiat_wallet_data_for_admin:`${ADMIN}/get-fiat-wallet-data-for-admin`,
active_deactive_user_wallet:`${ADMIN}/active-deactive-user-wallet`,
active_deactive_user_crypto_wallet:`${ADMIN}/active-deactive-user-crypto-wallet`,
get_user_wallet_data_for_admin:`${ADMIN}/get-user-wallet-data-for-admin`,
crytpo_wallet_data_for_admin:`${ADMIN}/crytpo-wallet-data-for-admin`,
single_crytpo_wallet_data_for_admin:`${ADMIN}/single-crytpo-wallet-data-for-admin`,
get_user_transaction_list_for_admin:`${ADMIN}/get-user-transaction-list-for-admin`,
get_alluser_transaction_list_for_admin:`${ADMIN}/get-alluser-transaction-list-for-admin`,
get_assests_list_for_admin:`${ADMIN}/get-assests-list-for-admin`,
get_qr_code:`${USER}/get-qr-code`,

// generate_qr_for_crypto:`${USER}/generate-qr-for-crypto`,
generate_qr_for_crypto:`${USER}/generate-qr-for-admin`,
add_edit_streak_notification:`${ADMIN}/add-editStreakSetting`,
get_streak_notification:`${ADMIN}/get-allstreak-list `,
get_streak_list:`${ADMIN}/get-allStreaks`,
get_single_user_streak:`${ADMIN}/get-allStreaksOfSingleUser`,
get_streak_history:`${ADMIN}/get-SingleUserStreakDetails`,
get_currency_list:`${ADMIN}/get-currency-name-for-admin`,
delete_reel:`${ADMIN}/delete-reel-byadmin `,


// stickers
add_edit_stickers:`${ADMIN}/add-edit-stickers`,
delete_stickers:`${ADMIN}/delete-stickers`,
get_all_stickers:`${ADMIN}/get-all-stickers-byadmin`,
auto_logout:`${ADMIN}/check-authorize`,

// trending
get_trending_post:`${ADMIN}/get-trandingPost`,
get_trending_reel:`${ADMIN}/get-trandingReels`,
get_trending_hashtag:`${ADMIN}/get-trendingHastag`,
get_graph_data:`${ADMIN}/get-grapForSinglePost`,
get_streak_chat:`${ADMIN}/get-usersStreakDetails`

},
  
};
export { Api };
