import React from 'react'
import Index from '../Index'


const RecordNotFound = (props) => {
    return (
        <Index.TableRow className='loading-row'>
            <Index.TableCell className={`loading-cell ${props.className}`} colSpan={props.colSpan}  >
                <Index.Typography className="table-data-not-found">Record not found</Index.Typography>
            </Index.TableCell>
        </Index.TableRow>
    )
}

export default RecordNotFound