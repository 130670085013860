import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import {
  addRandomWinnerList,
  getPopularContestParticipant,
  getSingleContest,
  randomWinnerDeclare,
  suffleContestParticipantAction,
} from "../../../../redux/slices/adminService";
import { Rowing } from "@mui/icons-material";
import { imageUrl } from "../../../../config/dataService";
import { Card, CardMedia } from "@mui/material";
import ReactPlayer from "react-player";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const ParticipantList = ({ row, winnerPage }) => {
  const ordinalNumbers = [
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "Sixth",
    "Seventh",
    "Eighth",
    "Ninth",
    "Tenth",
  ];
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [contestParticipantList, setContestParticipantList] = useState();
  const [loader, setLoader] = useState(true);
  const [pageData, setPageData] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [winnerData, setWinnerData] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [contestDetails, setContestDetails] = useState();
  const handleCloseModal = () => {
    setOpenModal(!openModal);
    setWinnerData();
  };

  const getContest = () => {
    setLoader(true);
    if (row?.category == "Random") {
      getSingleContest(row?._id).then((res) => {
        console.log(res?.data);
        setLoader(false);
        setContestDetails(res?.data[0]?.contest);
        setContestParticipantList(res?.data[0]?.countsByUser);
        setFilterData(res?.data[0]?.countsByUser);
      });
    } else {
      getPopularContestParticipant(row?._id).then((res) => {
        console.log(res?.data);
        setLoader(false);
        setContestDetails(res?.data[0]?.contest);
        setContestParticipantList(res?.data[0]?.countsByUser);
        setFilterData(res?.data[0]?.countsByUser);
      });
    }
  };

  useEffect(() => {
    getContest();
  }, [row]);

  useEffect(() => {
    const filterData = contestParticipantList?.filter((item) => {
      return search
        ? item.userName?.toLowerCase().includes(search.toLowerCase())
        : item;
    });
    setFilterData(filterData);
  }, [search, contestParticipantList]);

  const handleSuffle = () => {
    setButtonDisabled(true);
    setLoader(true);
    suffleContestParticipantAction(row?._id).then((res) => {
      setContestDetails(res?.data[0]?.contest);
      setContestParticipantList(res?.data[0]?.countsByUser);
      setLoader(false);
      setButtonDisabled(false);
    });
  };

  const handleChangeCheckBox = (data) => {
    setLoader(true);
    addRandomWinnerList({
      contestId: row._id,
      userId: data?.userId,
    }).then((res) => {
      // setButtonDisabled(false)
      // setOpenModal(false)
      getContest();
    });
  };

  const winnerAnnounce = () => {
    setButtonDisabled(true);
    randomWinnerDeclare({ contestId: row?._id })
      .then((res) => {
        setButtonDisabled(false);
        setOpenModal(false);
        getContest();
      })
      .catch((err) => {
        setTimeout(() => {
          setButtonDisabled(false);
        }, 3000);
      });
  };




  const handleVideoClick = (row) => {
    if (row === currentVideoId) {
      setCurrentVideoId(null);
    } else {
      if (currentVideoId !== null) {
        setCurrentVideoId(null);
      }

      setCurrentVideoId(row);
    }
  };



  return (
    <Index.Box className="table-wrape">
      <Index.Box className="external-tab-box">
        <Index.Box className="barge-common-box">
          <Index.Box className="common-box">
            <Index.Box className="user-list-flex">
              <Index.Box className="admin-page-title-main">
                <Index.Typography
                  className="admin-page-title tittle-like-costam"
                  component="h2"
                  variant="h2"
                >
                  Participant List
                </Index.Typography>
              </Index.Box>
              <Index.Box className="userlist-btn-flex">
                <Index.Box className="user-search-main">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Search"
                        onChange={(e) => {
                          const newValue = e.target.value
                            .replace(/^\s+/, "")
                            .replace(/\s\s+/g, " ");
                          setCurrentPage(1);
                          setSearch(newValue);
                        }}
                      />
                      <span className="search-icon-box">
                        <img src={Index.Svg.search} className="search-icon" />
                      </span>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                {contestParticipantList?.length && !loader ? (
                  !contestDetails?.isAnnounceWinner &&
                    winnerPage == "winnerPage" &&
                    contestDetails?.category == "Random" ? (
                    <Index.Box className="adduser-btn-main btn-main-primary">
                      <Index.Button
                        className="adduser-btn btn-primary"
                        onClick={handleSuffle}
                        disabled={buttonDisabled}
                      >
                        {buttonDisabled ? (
                          <PageIndex.ButtonLoader
                            color="white"
                            size={14}
                            loading={buttonDisabled}
                          />
                        ) : (
                          "Suffle"
                        )}
                      </Index.Button>
                    </Index.Box>
                  ) : (
                    !contestDetails?.isAnnounceWinner &&
                    winnerPage == "winnerPage" &&
                    !contestDetails?.isAnnouceCheckBox &&
                    contestDetails?.category == "Popular" && (
                      <Index.Box className="adduser-btn-main btn-main-primary">
                        <Index.Button
                          className="adduser-btn btn-primary"
                          onClick={winnerAnnounce}
                          disabled={buttonDisabled}
                        >
                          {buttonDisabled ? (
                            <PageIndex.ButtonLoader
                              color="white"
                              size={14}
                              loading={buttonDisabled}
                            />
                          ) : (
                            " Winner"
                          )}
                        </Index.Button>
                      </Index.Box>
                    )
                  )
                ) : (
                  ""
                )}
              </Index.Box>
            </Index.Box>
            <Index.Box className="admin-dash-box">
              <Index.Box className="page-table-main contest-winner-view-table">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  <Index.Table
                    stickyHeader
                    aria-label="simple table"
                    className="table"
                  >
                    <Index.TableHead className="table-head cus-table-head">
                      <Index.TableRow className="table-row">
                        {winnerPage == "winnerPage" && (
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            align="center"
                          ></Index.TableCell>
                        )}
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        >
                          S.No.
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        >
                          Media File
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        >
                          Username
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        >
                          No. Of Likes
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        >
                          No. Of Comments
                        </Index.TableCell>
                        {/* <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        >
                          No. of Shears
                        </Index.TableCell> */}
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        >
                          No. Of Views
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        >
                          Engagment ratio
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className="table-body">
                      {!loader ? (
                        pageData?.length ? (
                          pageData.map((row, index) => (
                            <Index.TableRow key={row?.userId}>
                              {winnerPage == "winnerPage" && (
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                  align="center"
                                >
                                  {console.log(row.isWinner,'row.isWinner')}
                                  <Index.Checkbox
                                    onChange={() => handleChangeCheckBox(row)}
                                    // disabled={
                                    //   contestDetails?.winningPrice?.length ==
                                    //     contestDetails?.priceDistribution
                                    //       ?.length ||
                                    //   contestDetails?.priceDistribution?.some(
                                    //     (data) => data?.userId == row?.userId
                                    //   )
                                    // }
                                    // checked={contestDetails?.priceDistribution?.some(
                                    //   (data) => data?.userId == row?.userId
                                    // )}

                                    checked={
                                      row.isWinner
                                    }
                                    disabled={
                                      contestDetails?.isAnnounceWinner ||
                                      row.isWinner ||
                                      (contestDetails.category == "Popular" &&
                                        !contestDetails?.isAnnouceCheckBox)
                                    }
                                  />
                                </Index.TableCell>
                              )}
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                align="center"
                              >
                                {row.sNo}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td participant_table_vedio"
                                align="center"
                                // onClick={() => handleVideoClick(row?.media)}
                              >

                                {/* <ReactPlayer
                                  component="video"
                                  controls
                                  width={130}
                                  height={70}
                            
                                  url={`${imageUrl}${row?.media}`}
                                  playing={row.userId === currentVideoId?true:false}
                                  onPlay={() => handleVideoClick(row)}
                                /> */}
                                {console.log(row,'niru')}
                                <Index.Box className='video-swiper' >
                                  <ReactPlayer
                                    component="video"
                                    controls
                                    width={150}
                                    height={65}
                                    className="react-player"
                                    url={`${imageUrl}${row?.media}`}
                                    playing={row?.userId == currentVideoId ? true : false}
                                    onPlay={() => handleVideoClick(row?.userId)}
                                  />
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                align="center"
                              >
                                {row?.userName}
                              </Index.TableCell>{" "}
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                align="center"
                              >
                                {row?.likeCount}
                              </Index.TableCell>{" "}
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                align="center"
                              >
                                {row?.commentCount}
                              </Index.TableCell>
                              {/* <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                align="center"
                              >
                                {row?.shareCount}
                              </Index.TableCell> */}
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                align="center"
                              >
                                {row?.viewCount}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                align="center"
                              >
                                {row?.engagementRatio} 
                              </Index.TableCell>
                              {/* Render other cells as needed */}
                            </Index.TableRow>
                          ))
                        ) : (
                          <PageIndex.RecordNotFound colSpan={9} />
                        )
                      ) : (
                        <PageIndex.TableLoader colSpan={9} />
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
            </Index.Box>
            <PageIndex.Pagination
              fetchData={filterData}
              setPageData={setPageData}
              pageData={pageData}
            />
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {/* <Index.Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="modal-delete modal"
            >
                <Index.Box sx={style} className="delete-modal-inner-main modal-inner">
                  
                    <Index.Typography
                        className="delete-modal-title"
                        component="h2"
                        variant="h2"
                    >
                        Are you sure?
                    </Index.Typography>
                    <Index.Typography
                        className="delete-modal-para common-para"
                        component="p"
                        variant="p"
                    >
                        Do you really want to delete these records? This process cannot be
                        undone.
                    </Index.Typography>
                    <Index.Box className="delete-modal-btn-flex">
                        <Index.Button
                            className="modal-cancel-btn modal-btn"
                            onClick={handleCloseModal}
                        >
                            Cancel
                        </Index.Button>


                        <Index.Button
                            className="modal-delete-btn modal-btn"
                            onClick={winnerAnnounce}
                            disabled={buttonDisabled}
                        >

                            Confirm
                        </Index.Button>
                    </Index.Box>
                </Index.Box>
            </Index.Modal> */}
    </Index.Box>
  );
};

export default ParticipantList;
