import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { imageUrl } from "../../../../config/dataService";
import {
  deleteCommentReport,
  getSinglecommentReportsList,
  sendMailCommentReports,
  suspendUser,
} from "../../../../redux/slices/adminService";
import moment from "moment/moment";


const ViewCommentReport = () => {
  ////////////////////////////////////////////
  const divRef = useRef(null);
  const { state } = PageIndex.useLocation();
  const { rolePermission } = PageIndex.useSelector((state) => state.admin);
  let navigate = PageIndex.useNavigate();
  const [loader, setLoader] = useState(true);
  const [filterData, setFilterData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [commentReportist, setCommentReportist] = useState([]);
  const [search, setSearch] = useState("");
  const [deleteId, setDeleteId] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [isMail, setIsMail] = useState(false);
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false);
  const [suspendUserId, setSuspendUserId] = useState();
  const [openSuspend, setOpenSuspend] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(null);
  //Fetch Post Report List
  const postId = state?.data?.postId?._id;

  const commentId = state?.data?.commentId?._id;
  const sendMailToUser = state?.data?.commentId?.createdBy?._id;
  console.log("state", state?.data);

  const fetchSinglePostReport = () => {
    setLoader(true);
    getSinglecommentReportsList(commentId).then((data) => {
      console.log("data++", data?.data);
      if (data?.status == 200) {
        setLoader(false);
        setCommentReportist(data?.data);
        setFilterData(data?.data);
      } else {
        setCommentReportist([]);
        setLoader(false);
      }
    });
  };
  useEffect(() => {
    fetchSinglePostReport();
  }, []);

  setTimeout(() => {
    setLoader(false);
  }, 4000);

  useEffect(() => {
    console.log("commentReportist[0]?.taskId?.reportersId", commentReportist);
    const filterData = commentReportist?.filter((item) => {
      return search
        ? item?.userId?.userName?.toLowerCase().includes(search?.toLowerCase()) ||
        item?.report?.toLowerCase().includes(search?.toLowerCase()): 
        item;
    });
    setFilterData(filterData);
  }, [search, commentReportist]);

  const handleOpenDelete = () => {
    setOpenDelete(true);
    setDeleteId(commentId);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  const deleteUser = (id) => {
    setIsDeleteButtonDisabled(true);
    deleteCommentReport({ commentId: deleteId }).then((res) => {
      handleCloseDelete();
      setIsDeleteButtonDisabled(false);
      fetchSinglePostReport();
      if (res?.status === 200) {
        navigate("/dashboard/user-report", {
          state: {
            postKey: 1,
          },
        });
      } else {
        setTimeout(() => {
          setIsDeleteButtonDisabled(false);
        }, 3000);
      }
    });
  };

  const handleSendMail = () => {
    setIsMail(true);
    sendMailCommentReports(sendMailToUser).then((data) => {
      if (data?.status == 200) {
        setIsMail(false);
      } else {
        setTimeout(() => {
          setIsMail(false);
        }, 3000);
      }
    });
  };
  const handleOpenSuspend = (type) => {
    setOpenSuspend(true);
    setSuspendUserId(postId);
  };
  const suspendUserByAdmin = () => {
    setIsDeleteButtonDisabled(true);
    suspendUser(sendMailToUser).then((res) => {
      setIsDeleteButtonDisabled(false);
      fetchSinglePostReport();
      if (res?.status === 200) {
        handleCloseSuspend();
      } else {
        setTimeout(() => {
          setIsDeleteButtonDisabled(false);
        }, 3000);
      }
    });
  };
  const handleCloseSuspend = () => setOpenSuspend(false);

  const handleVideoClick = (row) => {
    if (row === currentVideoId) {
      setCurrentVideoId(null);
    } else {
      if (currentVideoId !== null) {
        setCurrentVideoId(null);
      }

      setCurrentVideoId(row);
    }
  };
  ////////////////////////////////////////////////

  return (
    <>
      <Index.Box className='user-details-back-btn'>
        <PageIndex.BackButton onClick={() => navigate("/dashboard/user-report", {
          state: {
            postKey: 1,
          },
        })} />
      </Index.Box>
      <Index.Box className="user-detail-section">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            className="display-row-add-user"
            gridTemplateColumns="repeat(12, 1fr)"
          // gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 3",
              }}
              className="grid-column"
            >
              <Index.Box className="left-wrape">
                <Index.Box className="profile-wrape">
                  <Index.Box className="image-wrape">
                    <img
                      src={
                        commentReportist[0]?.commentId?.createdBy?.profile &&
                          commentReportist[0]?.commentId?.createdBy?.profile.length > 0
                          ? commentReportist[0]?.commentId?.createdBy
                            ?.profile instanceof Blob
                            ? URL.createObjectURL(
                              commentReportist[0]?.commentId?.createdBy?.profile
                            )
                            : `${imageUrl}${commentReportist[0]?.commentId?.createdBy?.profile}`
                          : PageIndex?.Jpg?.dummyImage
                      }
                      className="user-post-post-img"
                    />
                  </Index.Box>
                </Index.Box>

                <Index.Box className="detail">

                  <label>Full Name</label>
                  <p> {commentReportist[0]?.commentId?.createdBy?.name ? commentReportist[0]?.commentId?.createdBy?.name : "-"}</p>
                  <label> Email</label>
                  <p> {commentReportist[0]?.commentId?.createdBy?.email ? commentReportist[0]?.commentId?.createdBy?.email : "-"}</p>
                  <label>Username</label>
                  <p>
                    {commentReportist[0]?.commentId?.createdBy?.userName ? commentReportist[0]?.commentId?.createdBy?.userName : '-'}
                  </p>
                  <label>Phone Number</label>
                  <p>
                    {commentReportist[0]?.commentId?.createdBy?.phoneNumber ? `${commentReportist[0]?.commentId?.createdBy?.countryCode} ${commentReportist[0]?.commentId?.createdBy?.phoneNumber}` : '-'}
                  </p>
                  <label>Engagement Ratio</label>
                  <p> {commentReportist[0]?.allLikeComment ? `${commentReportist[0]?.allLikeComment?.toFixed(2)}%`: '-'}</p>
                  <label>Country</label>
                  <p>  {commentReportist[0]?.commentId?.createdBy?.country ? commentReportist[0]?.commentId?.createdBy?.country : "-"}</p>

                  <label>Bio</label>
                  <p>
                    {commentReportist[0]?.commentId?.createdBy?.Bio &&
                      commentReportist[0]?.commentId?.createdBy?.Bio?.length > 25 ? (
                      <PageIndex.LightTooltip
                        placement="right"
                        title={commentReportist[0]?.commentId?.createdBy?.Bio}
                      >
                        {commentReportist[0]?.commentId?.createdBy?.Bio.slice(0, 25) + "..."}
                      </PageIndex.LightTooltip>
                    ) : (
                      commentReportist[0]?.commentId?.createdBy?.Bio || "-"
                    )}
                  </p>
                  <label>Suspend</label>
                  <p>
                    {commentReportist[0]?.commentId?.createdBy?.isSuspended === true
                      ? "Yes"
                      : "No"}
                  </p>
                  <label>Links</label>
                  <p>{commentReportist[0]?.commentId?.createdBy?.links ? commentReportist[0]?.commentId?.createdBy?.links : "-"}</p>
                  <label>Following Count</label>
                  <p>{commentReportist[0]?.followingCount ? commentReportist[0]?.followingCount : 0}</p>
                  <label>Followers Count</label>
                  <p>{commentReportist[0]?.followersCount ? commentReportist[0]?.followersCount : 0}</p>
                </Index.Box>
                <PageIndex.LightTooltip title="Suspend">
                  <Index.IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    disabled={
                      commentReportist[0]?.commentId?.createdBy?.isSuspended === true
                    }
                    onClick={() => {
                      handleOpenSuspend();
                    }}
                  >
                    <img
                      src={Index.Png.blockUser}
                      className="dash-icons"
                      alt="dashboard icon"
                    />
                  </Index.IconButton>
                </PageIndex.LightTooltip>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 9",
              }}
              className="grid-column"
            >
              <Index.Box className="right-wrape">
                <Index.Box className="owner-detail-wrap">
                  <Index.Box className="mini-card">
                    <Index.Box className="owner-image-wrape">
                      <Index.Box className="user-post-post-img-box">

                        <Index.Box className='cus-view-user-slider'>
                          <Index.Swiper
                            navigation={true}
                            modules={[Index.SwiperPagination, Index.Navigation]}
                            className="mySwiper"
                            onSlideNextTransitionStart={(swiper) => handleVideoClick(swiper.activeIndex)}
                            onSlidePrevTransitionStart={(swiper) => handleVideoClick(swiper.activeIndex)}
                          >
                            {state?.data &&
                              commentReportist[0]?.commentId?.postId?.profile?.map((profile, index) => {
                                return (
                                  <Index.SwiperSlide key={profile} >
                                    {profile?.endsWith(".mp4")||profile?.endsWith(".mov") ? (

                                      <Index.Box >
                                        <Index.ReactPlayer
                                          component="video"
                                          controls
                                          width={200}
                                          height={150}
                                          // className="user-post-post-img"
                                          url={`${imageUrl}${profile}`}
                                          playing={index === currentVideoId ? true : false}
                                          onPlay={() => {
                                            handleVideoClick(index)

                                          }}
                                        />
                                      </Index.Box>
                                    ) : (
                                      <Index.Box className='image-view-user-swiper'>
                                        <img
                                          src={`${imageUrl}${profile}`}
                                          className="user-post-post-img"
                                        />
                                      </Index.Box>
                                    )}
                                  </Index.SwiperSlide>
                                );
                              })}
                          </Index.Swiper>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="start-section owener-detail">
                    <Index.Box className="detail profile-detail">
                      <label>Comment</label>
                      <p className="post-description">
                        <Index.Box
                              ref={divRef} 
                          dangerouslySetInnerHTML={{
                            __html: commentReportist[0]?.commentId?.comment,
                          }}
                        ></Index.Box>
                      </p>
                    </Index.Box>

                    <Index.Box className="detail profile-detail">
                      <label>Caption</label>

                      <p className="post-description">
                        {commentReportist[0]?.commentId?.postId?.caption && commentReportist[0]?.commentId?.postId?.caption?.length > 40 ? (
                          <PageIndex.LightTooltip
                            title={
                              <Index.Box
                              ref={divRef} 
                                dangerouslySetInnerHTML={{
                                  __html: commentReportist[0]?.commentId?.postId?.caption ? commentReportist[0]?.commentId?.postId?.caption : "-",
                                }}
                              ></Index.Box>
                            }
                            placement="right"
                          >
                            <Index.Box
                                  ref={divRef} 
                              dangerouslySetInnerHTML={{
                                __html: commentReportist[0]?.commentId?.postId?.caption
                                  ? commentReportist[0]?.commentId?.postId?.caption?.slice(0, 45) + "..."
                                  : "-",
                              }}
                            ></Index.Box>
                          </PageIndex.LightTooltip>
                        ) : (
                          <Index.Box
                          ref={divRef} 
                            dangerouslySetInnerHTML={{
                              __html: commentReportist[0]?.commentId?.postId?.caption ? commentReportist[0]?.commentId?.postId?.caption : "-",
                            }}
                          ></Index.Box>
                        )}
                      </p>
                    </Index.Box>

                    <Index.Box className="detail profile-detail">
                      <label>Location</label>
                      <p className="post-description">
                        {commentReportist[0]?.commentId?.postId?.location && commentReportist[0]?.commentId?.postId?.location?.length > 30 ? (
                          <PageIndex.LightTooltip
                            placement="top"
                            title={commentReportist[0]?.commentId?.postId?.location}
                          >
                            {commentReportist[0]?.commentId?.postId?.location.slice(0, 30) + "..."}
                          </PageIndex.LightTooltip>
                        ) : (
                          commentReportist[0]?.commentId?.postId?.location || "-"
                        )}
                      </p>
                    </Index.Box>
                    <Index.Box className="detail profile-detail">
                      <label>Created at</label>
                      <p className="post-description">
                        {commentReportist[0]?.commentId?.createdAt ? moment(commentReportist[0]?.commentId?.createdAt).format(
                          "DD/MM/YYYY hh:mm A"
                        ) : "-"}
                      </p>
                    </Index.Box>
                    <Index.Box className="detail profile-detail">
                      <label>Allow Comments</label>
                      <p className="post-description">
                        {commentReportist[0]?.commentId?.allowComments == true ? "Yes" : "No"}
                      </p>
                    </Index.Box>
                    <Index.Box className="detail profile-detail">
                      <label>Visible to Everyone</label>
                      <p className="post-description">
                        {commentReportist[0]?.commentId?.allowComments == true ? "Yes" : "No"}
                      </p>
                    </Index.Box>
                    {(rolePermission &&
                      rolePermission?.roleType?.rolePermission?.includes(
                        "UserReport_view"
                      )) ||
                      (rolePermission &&
                        rolePermission?.roleType?.rolePermission?.includes(
                          "UserReport_edit"
                        )) ||
                      rolePermission?.roleType?.rolePermission?.includes(
                        "UserReport_delete"
                      ) ||
                      (rolePermission && rolePermission?.isAdmin === true) ? (
                      <>
                        <Index.Box className="userdata-btn-flex">
                          {(rolePermission &&
                            rolePermission?.roleType?.rolePermission?.includes(
                              "UserReport_edit"
                            )) ||
                            (rolePermission &&
                              rolePermission?.isAdmin === true) ? (
                            <>
                              <Index.IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                                disabled={isMail}
                                onClick={() => {
                                  handleSendMail();
                                }}
                              >
                                <img
                                  alt="img"
                                  className="usermailwarning-img"
                                  src={Index.Png.userMailWarning}
                                />
                              </Index.IconButton>
                            </>
                          ) : (
                            ""
                          )}

                          {rolePermission?.roleType?.rolePermission?.includes(
                            "UserReport_delete"
                          ) ||
                            (rolePermission &&
                              rolePermission?.isAdmin === true) ? (
                            <>
                              <Index.IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                                onClick={() => {
                                  handleOpenDelete();
                                }}
                              >
                                <Index.DeleteIcon className="usermailwarning-img" />
                              </Index.IconButton>
                            </>
                          ) : (
                            ""
                          )}
                        </Index.Box>
                      </>
                    ) : (
                      ""
                    )}
                  </Index.Box>
                </Index.Box>

                <Index.Box className="table-wrape">
                  <Index.Box className="external-tab-box">
                    <Index.Box className="barge-common-box">
                      <Index.Box className="common-box">
                        <Index.Box className="user-list-flex">
                          <Index.Box className="admin-page-title-main">
                            <Index.Typography
                              className="admin-page-title tittle-like-costam"
                              component="h2"
                              variant="h2"
                            >
                              Comment Report
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="userlist-btn-flex">
                            <Index.Box className="user-search-main">
                              <Index.Box className="user-search-box">
                                <Index.Box className="form-group">
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    value={search}
                                    className="form-control"
                                    placeholder="Search user"
                                    onChange={(e) => {
                                      const newValue = e.target.value
                                        .replace(/^\s+/, "")
                                        .replace(/\s\s+/g, " ");

                                      setSearch(newValue);
                                    }}
                                  />
                                  <span className="search-icon-box">
                                    <img
                                      src={Index.Svg.search}
                                      className="search-icon"
                                    />
                                  </span>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="admin-dash-box">
                          <Index.Box className="page-table-main like-table-main">
                            <Index.TableContainer
                              component={Index.Paper}
                              className="table-container"
                            >
                              <Index.Table
                                stickyHeader
                                aria-label="simple table"
                                className="table"
                              >
                                <Index.TableHead className="table-head cus-table-head">
                                  <Index.TableRow className="table-row">
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      S.No.
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Report
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Report By
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Created Date
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Created Time
                                    </Index.TableCell>
                                  </Index.TableRow>
                                </Index.TableHead>
                                <Index.TableBody className="table-body">
                                  {!loader ? (
                                    pageData?.length > 0 ? (
                                      pageData?.map((row, index) => (
                                        <Index.TableRow key={row?._id}>
                                          {console.log("row", row)}
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                          >
                                            {index + 1}
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                          >
                                            {row?.report &&
                                              row?.report?.length > 30 ? (
                                              <PageIndex.LightTooltip
                                                title={row?.report}
                                                arrowPlacement="top"
                                              >
                                                {row?.report.slice(0, 30)}...
                                              </PageIndex.LightTooltip>
                                            ) : (
                                              row?.report || "-"
                                            )}
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                          >
                                            {row?.userId?.userName ? row?.userId?.userName : "-"}
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                          >
                                            {moment(row?.createdAt).format("DD/MM/YYYY ")}
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                          >
                                            {moment(row?.createdAt).format("hh:mm A")}
                                          </Index.TableCell>
                                        </Index.TableRow>
                                      ))
                                    ) : (
                                      <PageIndex.RecordNotFound colSpan={6} />
                                    )
                                  ) : (
                                    <PageIndex.TableLoader colSpan={6} />
                                  )}
                                </Index.TableBody>
                              </Index.Table>
                            </Index.TableContainer>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="pagination-costom">
                          <PageIndex.Pagination
                            fetchData={filterData}
                            setPageData={setPageData}
                            pageData={pageData}
                            search={search}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <PageIndex.DeleteModal
        openDelete={openDelete}
        handleCloseDelete={handleCloseDelete}
        handleDeleteRecord={deleteUser}
        isDeleteButtonDisabled={isDeleteButtonDisabled}
      />
      <PageIndex.SuspendModal
        openSuspend={openSuspend}
        handleCloseSuspend={handleCloseSuspend}
        handleSuspendRecord={suspendUserByAdmin}
        isDeleteButtonDisabled={isDeleteButtonDisabled}
      />
    </>
  );
};

export default ViewCommentReport;
