import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { imageUrl } from "../../../../config/dataService";
import { getAllPostReportsList, getTrendingPostAction, getTrendingReelAction } from "../../../../redux/slices/adminService";
import { debounce } from "lodash";
import moment from "moment";

const TrendingPostList = ({setBtnValue}) => {
  const { rolePermission } = PageIndex.useSelector((state) => state.admin);
  const location = PageIndex.useLocation();
  const queryParams = new URLSearchParams(window.location.search);
  const stateParam = queryParams.get('state');
  const state = JSON.parse(decodeURIComponent(stateParam));

  let navigate = PageIndex.useNavigate();
  const [loader, setLoader] = useState(true);
  const [trendingList, setTrendingList] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(null);
  const [currentVideoId, setCurrentVideoId] = useState(null)
  //Fetch Post Report List



  const fetchTrendingList = () => {
    const page = currentPage
    getTrendingReelAction(page, search).then((data) => {
      if (data?.status == 200) {
        setLoader(false);
        setTrendingList(data?.data);
        if (data?.currentPage) {
          setCurrentPage(data?.currentPage);
        }

        setPageCount(data?.totalPages);
      } else {
        setTrendingList([]);
        setLoader(false);
      }
    });
  };
  useEffect(() => {
    const debouncedFetch = debounce(fetchTrendingList, 300);
    if (search || currentPage) {
      debouncedFetch();
    } else {
      fetchTrendingList();
    }

    return () => {
      debouncedFetch.cancel();
    };
  }, [search, currentPage]);

  const handleVideoClick = (index, id) => {
    if (index === currentVideoIndex && id === currentVideoId) {
      setCurrentVideoIndex(null);
      setCurrentVideoId(null);
    } else {
      setCurrentVideoIndex(index);
      setCurrentVideoId(id);
    }
  }

  useEffect(() => {
    if (location?.state?.tabNo) {
      setBtnValue(location?.state?.tabNo);
    }
  }, [location?.state?.tabNo]);

  return (
    <Index.Box className="barge-common-box">
      <Index.Box className="common-box">
        <Index.Box className="user-list-flex">
          <Index.Box className="admin-page-title-main">
            <Index.Typography
              className="admin-page-title tittle-like-costam"
              component="h2"
              variant="h2"
            >
              Trending Reel 
            </Index.Typography>
          </Index.Box>
          <Index.Box className="userlist-btn-flex">
            {/* <Index.Box className="input-box add-user-input request-input-box">
              <Index.Box className="form-group">
                <Index.FormControl className="request-dropdown">
                  <Index.Select
                    className="form-control select-drop-list request-drop-list"
                    name="type"
                    value={type}

                    onChange={handleType}
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                  >
                    {typeList &&
                      typeList?.map((val) => (
                        <Index.MenuItem
                          value={val?.value}
                          key={val?.id}
                          className="menuitem"
                        >
                          {val?.key}
                        </Index.MenuItem>
                      ))}
                  </Index.Select>
                </Index.FormControl>
              </Index.Box>
            </Index.Box> */}
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search"
                    onChange={(e) => {
                      const newValue = e.target.value
                        .replace(/^\s+/, "")
                        .replace(/\s\s+/g, " ");
                      setCurrentPage(1)
                      setSearch(newValue);
                    }}
                  />
                  <span className="search-icon-box">
                    <img src={Index.Svg.search} className="search-icon" />
                  </span>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row-userlist"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box">
                      <Index.Box className="page-table-main postList-table-main ">
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                        >
                          <Index.Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                            className="table"
                          >
                            <Index.TableHead className="table-head cus-table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  S.No
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Name
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Username
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Location
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Created Date
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Created Time
                                </Index.TableCell>
                                {(rolePermission &&
                                  rolePermission?.roleType?.rolePermission?.includes(
                                    "UserPost_view"
                                  )) ||
                                  (rolePermission &&
                                    rolePermission?.isAdmin === true) ? (
                                  <>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Action
                                    </Index.TableCell>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {!loader ? (
                                trendingList?.length > 0 ? (
                                  trendingList?.map((row, index) => (
                                    <Index.TableRow
                                      key={row._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                      >
                                        {(currentPage - 1) * 10 + index + 1}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                     
                                      >
                                        <span  
                                         className="cus-user-name-navigate"
                                        onClick={() => {
                                          navigate('/dashboard/user-view',
                                            {
                                              state: {
                                                data: row?.createdBy?._id,
                                                pathName: location.pathname
                                              },
                                            }
                                          )
                                        }}>{row?.createdBy?.name}</span>
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                        align="center"
                                      >
                                        {row?.createdBy?.userName}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                        align="center"
                                      >
                                        {row?.location && row?.location?.length > 30 ?
                                          <PageIndex.LightTooltip
                                            title={row?.location}
                                            arrow
                                          >
                                            <span>
                                              {row?.location.substring(0, 25)}...
                                            </span>
                                          </PageIndex.LightTooltip> : row?.location || "-"}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                        align="center"
                                      >
                                        {moment(row.createdAt).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                        align="center"
                                      >
                                        {moment(row.createdAt).format(
                                          "hh:mm A"
                                        )}
                                      </Index.TableCell>
                                      {(rolePermission &&
                                        rolePermission?.roleType?.rolePermission?.includes(
                                          "UserPost_view"
                                        )) ||
                                        (rolePermission &&
                                          rolePermission?.isAdmin === true) ? (
                                        <>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            className="table-td"
                                            align="center"
                                          >
                                            <Index.Box className="userdata-btn-flex">
                                              <PageIndex.LightTooltip title="View">
                                                <Index.IconButton
                                                  color="primary"
                                                  aria-label="upload picture"
                                                  component="label"
                                                  onClick={() => {
                                                    navigate(
                                                      "/dashboard/trending-post-details",
                                                      {
                                                        state: { postId: row?._id,
                                                          userId:row?.createdBy?._id
                                                         },
                                                      }
                                                    );
                                                  }}
                                                >
                                                  <Index.RemoveRedEyeIcon />
                                                </Index.IconButton>
                                              </PageIndex.LightTooltip>
                                            </Index.Box>
                                          </Index.TableCell>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </Index.TableRow>
                                  ))
                                ) : (
                                  <PageIndex.RecordNotFound colSpan={7} />
                                )
                              ) : (
                                <PageIndex.TableLoader colSpan={7} />
                              )}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
        {trendingList?.length ? (
          <Index.Box className="pagination-main">
            <Index.Pagination
              count={pageCount}
              page={currentPage}
              onChange={(event, value) => {
                setCurrentPage(value);
              }}
              variant="outlined"
              shape="rounded"
              className="pagination"
            />
          </Index.Box>
        ) : (
          ""
        )}
      </Index.Box>
    </Index.Box>
  );
};
export default TrendingPostList;
