import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import DataService, { dataService } from "../config/dataService";

const OTPPrivateRoutes = () => {
  const { isOTP } = useSelector((state) => state.admin);
  console.log(71,isOTP);
  const isAuthenticate = (token) => {
    if (!token) return true;
    return false;
  };
  console.log(71,isOTP,isAuthenticate(isOTP) );

  return isAuthenticate(isOTP) ? <Outlet /> : <Navigate to="/" replace />;
};

export default OTPPrivateRoutes;
