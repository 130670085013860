import "./App.css";
import Routers from "./routes/Routes";
import style from "./assets/style/admin.css";
import responsive from "./assets/style/responsive.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./container/pages/admin/userpost/userpost.css";
// import "./container/pages/admin/UserReels/UserReelsView.css"
import "./container/pages/admin/userpost/viewuserpost.css";
import "./container/pages/admin/userpost/userpost.css";
import "../src/assets/fonts/Poppins/Poppins-Regular.ttf";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
function App() {
 
  
  return (
    <div className="App">
      <Routers />
      <ToastContainer />
    </div>
  );
}

export default App;
