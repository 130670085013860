import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  createTermsConditionCms,
  getCmsDetails,
} from "../../../../redux/slices/adminService";
import PageIndex from "../../../PageIndex";

const TermsCondition = () => {
  const [addEditData, setAddEditData] = useState({});
  const [loading, setLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);

  const initialValues = {
    title: addEditData ? addEditData?.title : "",
    description: addEditData ? addEditData?.description : "",
  };

  const handleUpdateTerms = async (values) => {
    setIsDisabled(true);
    createTermsConditionCms(values).then((res) => {
      console.log(res, 21);
      if (res?.status == 200) {
        setTimeout(() => {
          setIsDisabled(false);
        }, 3000);
      } else {
        setTimeout(() => {
          setIsDisabled(false);
        }, 3000);
      }
    });
  };

  // Get Terms and Condition
  const fetchTermsAndCondition = () => {
    getCmsDetails().then((data) => {
      if (data?.status == 200) {
        setAddEditData(data?.data?.termsAndCondition);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    fetchTermsAndCondition();
  }, []);
  return (
    <>
      {!loading && (
        <Index.Box className="dashboard-content">
          <Index.Box className="barge-common-box">
            <Index.Box className="dashboard-content add-user-containt cms-page-containt">
              <Index.Typography
                className="admin-page-title cms-page-title"
                component="h2"
                variant="h4"
              >
                Terms & Conditions
              </Index.Typography>
            </Index.Box>
            <PageIndex.Formik
              initialValues={initialValues}
              validationSchema={PageIndex.termsConditionSchema}
              onSubmit={handleUpdateTerms}
            >
              {({ values, errors, setFieldValue, handleBlur, touched }) => (
                <PageIndex.Form>
                  <Index.Box className="admin-dash-box cms-page-containt">
                    <Index.Box className="grid-row cms-page-row">
                      <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                        >
                          {/* <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 5",
                              md: "span 4",
                              lg: "span 3",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box">
                              <Index.FormHelperText className="form-lable">
                                Title
                              </Index.FormHelperText>
                              <Index.Box className="form-group cms-input-box">
                                <Index.TextField
                                  fullWidth
                                  disabled={isDisabled ? true : false}
                                  id="title"
                                  className="form-control"
                                  placeholder="Title"
                                  name="title"
                                  onChange={(e) => {
                                    const newValue = e.target.value
                                      .replace(/^\s+/, "")
                                      .replace(/\s\s+/g, " ");
                                    setFieldValue("title", newValue);
                                  }}
                                  value={values.title}
                                  onBlur={handleBlur}
                                  error={Boolean(
                                    errors?.title ? touched?.title : undefined
                                  )}
                                  helperText={
                                    touched?.title ? errors?.title : undefined
                                  }
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Box> */}
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 12",
                              lg: "span 12",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box">
                              <Index.FormHelperText className="form-lable">
                                Description{" "}
                                <span className="mandatory-asterisk">*</span>
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Index.TextareaAutosizeed
                                  fullWidth
                                  disabled={isDisabled ? true : false}
                                  id="fullWidth"
                                  className="form-control text-autosize"
                                  placeholder="Description"
                                  value={values.description}
                                  // disabled={type === "view"}
                                  name="description"
                                  onChange={(e) => {
                                    const newValue = e.target.value
                                      .replace(/^\s+/, "")
                                      .replace(/\s\s+/g, " ");
                                    setFieldValue("description", newValue);
                                  }}
                                  onBlur={handleBlur}
                                />
                                {errors?.description &&
                                  touched?.description && (
                                    <p className="custom-error-tag">
                                      {errors?.description}
                                    </p>
                                  )}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="save-btn-main border-btn-main cms-btn-main">
                      <Index.Button
                        className={`save-user-btn border-btn ${
                          isDisabled ? "btn-disabled" : ""
                        }`}
                        type="submit"
                        disabled={isDisabled}
                      >
                        {isDisabled ? (
                          <PageIndex.ButtonLoader
                            color="white"
                            size={14}
                            loading={isDisabled}
                          />
                        ) : (
                          <>
                            <img
                              src={Index.Svg.save}
                              className="user-save-icon"
                              alt="Save Icon"
                            />
                            Update
                          </>
                        )}
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </PageIndex.Form>
              )}
            </PageIndex.Formik>
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
};

export default TermsCondition;
