import React, { useState } from "react";
import PageIndex from "../../../../PageIndex";
import Index from "../../../../Index";
import { useEffect } from "react";
import moment from "moment";
import { imageUrl } from "../../../../../config/dataService";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import ReactPlayer from "react-player";

const UserHighlightData = ({ hightLightData, loader,setImagePath,setImageOpen }) => {
  const [pageData, setPageData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [search, setSearch] = useState("");
  const [currentVideoIndex, setCurrentVideoIndex] = useState(null);
  const [currentVideoId, setCurrentVideoId] = useState(null)
 
  console.log(hightLightData, 21);
  useEffect(() => {
    const filterData = hightLightData?.filter((item) => {
      return search
        ? item.highlightName.toLowerCase().includes(search.toLowerCase())
        : item;
    });
    setFilterData(filterData);
  }, [search, hightLightData]);

  const handleOpenImage = (image) => {
    if(image){
      setImageOpen(true)
      setImagePath(image)
    }
  }

  const handleVideoClick = (index, id) => {
    if (index === currentVideoIndex && id === currentVideoId) {
      setCurrentVideoIndex(null);
      setCurrentVideoId(null);
    } else {
      setCurrentVideoIndex(index);
      setCurrentVideoId(id);
    }
  }
  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="user-list-flex">
          <Index.Box className="admin-page-title-main">
            <Index.Typography
              className="admin-page-title"
              component="h2"
              variant="h2"
            >
              Highlights
            </Index.Typography>
          </Index.Box>
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search"
                    name="search"
                    value={search}
                    onChange={(e) => {
                      const newValue = e.target.value
                        .replace(/^\s+/, "")
                        .replace(/\s\s+/g, " ");
                      setSearch(newValue);
                    }}
                  />
                  <span className="search-icon-box">
                    <img src={Index.Svg.search} className="search-icon" />
                  </span>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row-userlist"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="highlightlist-table-main page-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head cus-table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              S.No.
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              Highlights Name
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              Cover Image
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              Highlights
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              Created Date
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {!loader ? pageData?.length > 0 ? (
                            pageData?.map((row, index) => (
                              <Index.TableRow
                                key={row._id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                {console.log(row, 'niru1')}
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                  align="center"
                                >
                                  {row?.sNo}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                  align="center"
                                >
                                  {row?.highlightName ? row?.highlightName : "-"}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                  align="center"
                                >
                                  <img
                                    src={
                                      row?.coverImage
                                        ? row?.coverImage instanceof Blob
                                          ? URL.createObjectURL(row?.coverImage)
                                          : `${imageUrl}${row?.coverImage}`
                                        : Index.Png.herobg
                                    }
                                    className='user-post-user-img'
                                    onClick={()=>handleOpenImage(row?.coverImage)}
                                  />
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td user-view-post-data"
                                  align="center"
                                >
                                  <Index.Box className="cus-slider table-swiper">
                                    <Swiper
                                      navigation={true}
                                      modules={[Pagination, Navigation]}
                                      className="mySwiper"
                                      onSlideChange={(swiper) => handleVideoClick(swiper.activeIndex, row._id)}
                                    >

                                      {row?.stories &&
                                        row?.stories?.map((data, profileIndex) => (
                                          <SwiperSlide >

                                            {data?.profile[0]?.endsWith(".mp4")||data?.profile[0]?.endsWith(".mov") ? (
                                              <Index.Box className='video-swiper' >
                                                <ReactPlayer
                                                  component="video"
                                                  controls
                                                  width={150}
                                                  height={65}
                                                  className="react-player"
                                                  url={`${imageUrl}${data?.profile[0]}`}
                                                  playing={profileIndex === currentVideoIndex && row._id === currentVideoId}
                                                  onPlay={() => handleVideoClick(profileIndex, row._id)}


                                                />
                                              </Index.Box>
                                            ) : (
                                              <Index.Box className='image-swiper'  onClick={()=>handleOpenImage(data?.profile[0])}>
                                                <img src={`${imageUrl}${data?.profile[0]}`} />
                                              </Index.Box>
                                            )}
                                          </SwiperSlide>
                                        ))}
                                    </Swiper>
                                  </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                  align="center"
                                >
                                  {moment(row?.createdAt).format("DD-MM-YYYY")}
                                </Index.TableCell>
                              </Index.TableRow>
                            ))
                          ) : (
                            <PageIndex.RecordNotFound colSpan={6} />
                          ) : <PageIndex.TableLoader colSpan={6} />}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {/* Pagination */}
      <PageIndex.Pagination
        fetchData={filterData}
        setPageData={setPageData}
        pageData={pageData}
      />
    </>
  );
};

export default UserHighlightData;
